import {
  BBBVideo,
  CBTVideoMeta,
  CBTVideoType,
  getReference,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import _order from "../data/bookCodesOrder.json";
import { getCBTTitle } from "./videoList";

const bookOrder = _order as { [key: string]: string };

export const bbbFilename = (
  code: string,
  bookShortcode: string,
  metadata: BBBVideo
): string => {
  const orderNumber = bookOrder[bookShortcode.toUpperCase()];

  const reference = getReference(metadata)
    .replaceAll(" ", "-")
    .replaceAll(":", "_")
    .replaceAll(";-", "__")
    .replaceAll(";", "__");

  const filename = `${code}_${orderNumber}_${reference}`
    .toLowerCase()
    .slice(0, 240);

  return filename;
};

export const cbtFilename = (
  code: string,
  metadata: CBTVideoMeta,
  type: CBTVideoType
): string => {
  const displayOrder = metadata.display_order.toString().padStart(3, "0");

  const title = getCBTTitle(metadata, false)
    .replaceAll(" ", "-")
    .replaceAll(",", "")
    .replaceAll("'", "")
    .replaceAll(".", "");

  const filename = `${code}_${displayOrder}-${type}_${title}`
    .toLowerCase()
    .slice(0, 240);

  return filename;
};
