import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";
import {
  isMobileWidthVideoPanel,
  videoHeight,
} from "../../../store/screen/panelSelectors";
import useURLManipulator from "../../../hooks/useURLManipulator";
import { RootState } from "../../../store";

import styles from "./styles.module.css";
import {
  nextChapter,
  prevChapter,
  nextCBTVideo,
  prevCBTVideo,
} from "../../../store/translation/selectors";
import { isMobile } from "../../../store/screen/selectors";

interface Props {
  direction: "prev" | "next";
}

const Arrow = ({ direction }: Props): JSX.Element => {
  const history = useHistory();
  const { code } = useSelector((state: RootState) => state.params);
  const { selectCBTVideo } = useURLManipulator();
  const nextBBB = useSelector(nextChapter);
  const prevBBB = useSelector(prevChapter);
  const height = useSelector(videoHeight);
  const nextCVideo = useSelector(nextCBTVideo);
  const prevCVideo = useSelector(prevCBTVideo);
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const isMobileDevice = useSelector(isMobileWidthVideoPanel);
  const isRealMobileDevice = useSelector(isMobile);

  const translationType = useSelector(
    (state: RootState) => state.translation.rawData?.translation_type
  );

  const isBBB = translationType === "BBB";
  const isCBT = translationType === "CBT";
  const isNext = direction === "next";
  const bookChapter = isNext ? nextBBB : prevBBB;
  const cbtVideo = isNext ? nextCVideo : prevCVideo;
  const isDisabled = bookChapter === undefined;

  const icon = isNext ? faChevronRight : faChevronLeft;

  const onClick = () => {
    if (isBBB && bookChapter !== undefined) {
      const newUrl = `/${code}/${bookChapter.book}.${bookChapter.chapter}.${bookChapter.verse}${window.location.search}`;

      history.push(newUrl);
    } else if (isCBT && cbtVideo !== undefined) {
      selectCBTVideo(cbtVideo);
    }
  };

  const containerClass = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isRealMobileDevice && styles.containerMobile} ${
    isRealMobileDevice && darkMode && styles.containerMobileDarkMode
  }`;

  const margin = (isMobileDevice && -15) || -70;
  const size = isMobileDevice ? 40 : 50;

  const containerStyle: CSSProperties = {
    transform: `translateX(${0}px)`,
    top: `${height / 2 - 25}px`,
    opacity: isDisabled ? 0 : 1,
    pointerEvents: !isDisabled ? "auto" : "none",
    left: isNext ? undefined : margin,
    right: isNext ? margin : undefined,
    width: size,
    height: size,
    borderRadius: isRealMobileDevice ? 0 : size / 2,
  };

  return (
    <div
      aria-label={`Go to ${
        direction === "next" ? direction : "previous"
      } video`}
      style={containerStyle}
      className={containerClass}
      role="button"
      onKeyDown={undefined}
      onClick={onClick}
      tabIndex={0}
    >
      <FontAwesomeIcon icon={icon} className={styles.icon} />
    </div>
  );
};

export default Arrow;
