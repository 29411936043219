import { useSelector } from "react-redux";
import { CSSProperties, useMemo } from "react";
import { GlobalTextSize } from "../utils/localStorage";
import { RootState } from "../store";
import { getFillColor } from "../utils/colors";

type FontSizes = {
  [key in GlobalTextSize]: string;
};

export const useTextStyle = (
  sizes?: FontSizes,
  includeColor?: boolean
): CSSProperties => {
  const { textSize, textColor } = useSelector(
    (state: RootState) => state.globalTextSettings
  );

  const fontSize =
    sizes !== undefined
      ? (textSize === "large" && sizes.large) ||
        (textSize === "normal" && sizes.normal) ||
        sizes.small
      : undefined;

  const color = includeColor === true ? getFillColor(textColor) : undefined;

  const style = useMemo(
    () => ({
      fontSize,
      color,
    }),
    [color, fontSize]
  );

  return style;
};
