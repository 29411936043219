import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Heading.module.css";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  closeSelf: () => void;
  title: string;
  ariaLabel: string;
  darkMode: boolean;
}

const Heading = ({
  darkMode,
  closeSelf,
  title,
  ariaLabel,
}: Props): JSX.Element => {
  const textStyle = useTextStyle(
    { large: "2.3em", normal: "2em", small: "1.5em" },
    true
  );

  return (
    <div className={styles.header}>
      <h1 style={textStyle} className={styles.heading}>
        {title}
      </h1>
      <button
        tabIndex={0}
        className={`${styles.closeButton} ${
          darkMode ? styles.darkModeButton : styles.lightModeButton
        }`}
        onClick={closeSelf}
        type="button"
        aria-label={ariaLabel}
      >
        <FontAwesomeIcon className={styles.xMarkIcon} icon={faTimes} />
      </button>
    </div>
  );
};

export default Heading;
