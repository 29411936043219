import { useDispatch, useSelector } from "react-redux";
import { faComments, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../store";
import {
  setShowSidebar,
  setShowLanguageModal,
  setShowFeedbackModal,
} from "../../store/interface";
import { headerHeight, isMobile } from "../../store/screen/selectors";

import styles from "./styles.module.css";
import Overlay from "../Overlay";

import dbsLogo from "../../assets/images/dbs-light.svg";
import dcwLogo from "../../assets/images/dcw.svg";
import useInternationalization from "../../hooks/useInternationalization";
import FeatureLink from "./FeatureLink";
import ExternalLink from "./ExternalLink";
import { setDarkMode } from "../../store/settings";
import ThemeSwitch from "../Switch/Theme";
import { sidebarWidth } from "../../store/screen/navigationSidebarSelectors";

const Sidebar = (): JSX.Element => {
  const dispatch = useDispatch();

  const { getIntlText } = useInternationalization();
  const darkMode = useSelector((state: RootState) => state.settings.darkMode);
  const isMobileDevice = useSelector(isMobile);

  const toggleThemeSwitch = () => {
    dispatch(setDarkMode(!darkMode));
  };

  const show = useSelector((state: RootState) => state.interface.showSidebar);
  const width = useSelector(sidebarWidth);
  const height = useSelector(headerHeight);
  const windowHeight = useSelector(
    (state: RootState) => state.screen.dimensions.height
  );

  const hideSidebar = (): void => {
    dispatch(setShowSidebar(false));
  };

  const openDBS = (): void => {
    hideSidebar();
    window.open("https://www.deafbiblesociety.com");
  };

  const openDCW = (): void => {
    hideSidebar();
    window.open("https://app.deafchurchwhere.com");
  };

  const openLanguageModal = (): void => {
    hideSidebar();
    dispatch(setShowLanguageModal(true));
  };

  const openFeedbackModal = (): void => {
    hideSidebar();
    dispatch(setShowFeedbackModal(true));
  };

  const innerContainerStyle = {
    // transform: `translateX(-${!show ? width : 0}px)`,
    minHeight: windowHeight - height,
  };

  const containerStyle = {
    width,
    height: windowHeight,
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const innerContainer = `${styles.innerContainer} ${
    darkMode && styles.innerContainerDarkMode
  }`;

  // const internalLink = `${styles.internalLink} ${
  //   darkMode && styles.internalLinkDarkMode
  // }`;

  return (
    <Overlay show={show} closeSelf={hideSidebar}>
      {(ref) => (
        <div className={container} style={containerStyle} ref={ref}>
          <div className={innerContainer} style={innerContainerStyle}>
            <div className={styles.featureLinksContainer}>
              <FeatureLink
                text={getIntlText("change_language")}
                icon={faLanguage}
                onClick={openLanguageModal}
              />
              <FeatureLink
                text={getIntlText("feedback")}
                icon={faComments}
                onClick={openFeedbackModal}
              />
              {isMobileDevice && (
                <FeatureLink
                  onClick={toggleThemeSwitch}
                  text={getIntlText("dark_mode")}
                  iconChild={
                    <div className={styles.themeSwitchContainer}>
                      <ThemeSwitch />
                    </div>
                  }
                />
              )}
            </div>
            <div className={styles.externalLinksContainer}>
              <ExternalLink
                imgIcon={dbsLogo}
                title="Deaf Bible Society"
                description="Funds and distributes Bible Translations"
                onClick={openDBS}
              />
              <ExternalLink
                imgIcon={dcwLogo}
                title="Deaf Church Where"
                description="Find a Deaf church near you"
                onClick={openDCW}
              />
            </div>
            {/* <div className={styles.internalLinksContainer}> */}
            {/*  <div className={internalLink}>About</div> */}
            {/*  <div className={internalLink}>Privacy Policy</div> */}
            {/*  <div className={internalLink}>Terms and Conditions</div> */}
            {/*  <div className={internalLink}>Affiliates</div> */}
            {/* </div> */}
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default Sidebar;
