import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../../assets/icons/arrow-thick.svg";
import styles from "./styles.module.css";
import { RootState } from "../../../store";
import LineHeightBox from "../SettingBox/LineHeight";
import PanelPosition from "../SettingBox/PanelPosition";
import { textPanelWidth } from "../../../store/screen/panelSelectors";
import { setShowTextPanelSettingsModal } from "../../../store/interface";
import useOverlayModal from "../../../hooks/useOverlayModal";

const SettingsModal = (): JSX.Element => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const show = useSelector(
    (state: RootState) => state.interface.showTextPanelSettingsModal
  );

  const close = () => {
    dispatch(setShowTextPanelSettingsModal(false));
  };

  const ref = useOverlayModal(show, close);

  const width = useSelector(textPanelWidth);

  const shouldRoundCorners = width > 750;

  const containerClass = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${show && styles.show} ${shouldRoundCorners && styles.containerRounded}`;

  const arrowClass = `${styles.arrow} ${show && styles.arrowOpen}`;

  return (
    <div ref={ref} className={containerClass}>
      <div className={styles.innerContainer}>
        <LineHeightBox />
        <PanelPosition />
      </div>
      <div className={styles.bottom}>
        <Arrow className={arrowClass} onClick={close} />
      </div>
    </div>
  );
};

export default SettingsModal;
