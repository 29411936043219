import { Route, Switch } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";

// Disabling until this can be done in the right way due to multiple styling and UX issues
// import SmartBanner from "react-smartbanner";
// import "react-smartbanner/dist/main.css";

import { RootState } from "../../store";

/**
 * Hooks
 */
import useBodyBackground from "../../hooks/useBodyBackground";
import useInitFFmpeg from "../../hooks/useInitFFmpeg";
import useInitInternationalization from "../../hooks/useInitInternationalization";
import useLoadTextVersion from "../../hooks/useLoadTextVersion";
import useLoadTextVersionsMetadata from "../../hooks/useLoadTextVersionsMetadata";
import useLoadTimestamps from "../../hooks/useLoadTimestamps";
import useLoadTranslations from "../../hooks/useLoadTranslations";
import useLoadTranslation from "../../hooks/useLoadTranslation";
import useNetworkEvents from "../../hooks/useNetworkEvents";
import useScreenKey from "../../hooks/useScreenKey";
import useSeparatorDragHandler from "../../hooks/useSeparatorDragHandler";
import useUpdateDimensions from "../../hooks/useUpdateDimensions";
import useURLFixer from "../../hooks/useURLFixer";
import useURLInit from "../../hooks/useURLInit";

/**
 * Components
 */
import CountriesNavigator from "../NavigationSidebar/Countries";
import DownloadProgressModal from "../DownloadProgressModal";
import Header from "../Header";
import NavigatorTab from "../SideTab/NavigatorTab";
// import SEO from "./SEO";
import ShareModal from "../ShareModal";
import LanguageModal from "../LanguageModal";
import FeedbackModal from "../FeedbackModal";
import Sidebar from "../Sidebar";
import SplashScreen from "../SplashScreen";
import Toast from "../Toast";
import TranslationPanels from "../TranslationPanels";
import TranslationsNavigator from "../NavigationSidebar/Translations";
import TranslationNavigator from "../NavigationSidebar/Translation";

/**
 * Styles
 */
import fade from "../../styles/transitions/fade.module.css";
import styles from "./styles.module.css";
import MainVideoPlayer from "../MainVideoPlayer";
import VersionPickerModal from "../TextPanel/VersionPickerModal";
import useScreenOrientation from "../../hooks/useScreenOrientation";
import QRModal from "../ShareModal/QRModal";
import useLoadSearchResults from "../../hooks/useLoadSearchResults";
import SearchResultDisplay from "../SearchResultDisplay";
import GlobalSettingsModal from "../GlobalSettingsModal";

const App = (): JSX.Element => {
  /**
   * Sets URl to last viewed video
   */
  useURLInit();

  /**
   * Sets text language based on local storage and home country.
   */
  useInitInternationalization();

  /**
   * Loads metadata for all translations and save to Redux store.
   * Only runs once for the entire app lifespan.
   */
  useLoadTranslations();

  /**
   * Loads metadata for videos from the currently selected translation
   * and save to Redux store. Reruns whenever a different translation
   * is selected.
   */
  useLoadTranslation();

  /**
   * Loads timestamps for the currently selected book
   */
  useLoadTimestamps();

  /**
   * Loads metadata for available text versions (NIV, KJV, etc)
   */
  useLoadTextVersionsMetadata();

  /**
   * Loads the text content of the selected version, book, and chapter
   */
  useLoadTextVersion();

  /**
   * Loads the results of the search query for text version
   */
  useLoadSearchResults();

  /**
   * Analyzes current URL to determine current
   * app state, as well as redirecting if parts
   * of it are missing or invalid.
   */
  useURLFixer();

  /**
   * Updates the background color of body class according to
   * setting of darkMode
   */
  useBodyBackground();

  /**
   * On app load and whenever the window/screen is resized,
   * retrieve current dimensions and save to Redux store.
   */
  useUpdateDimensions();

  /**
   * Sets fullscreen mode based on screen orientation
   */
  useScreenOrientation();

  /**
   * Load the FFmpeg wasm library for the video clipping feature
   */
  useInitFFmpeg();

  /**
   * Handles disconnect/connect network events
   */
  useNetworkEvents();

  /**
   * Handles dragging of the separator between the video and text panels
   */
  useSeparatorDragHandler();

  /**
   * For page-switching animation purposes. Causes a
   * fade out/in when key is changed.
   */
  const key = useScreenKey();

  const { isEmbedded } = useSelector((state: RootState) => state.params);

  const showTranslationNavigator = useSelector(
    (state: RootState) => state.interface.showSelectorList
  );

  return (
    <div className={styles.container}>
      {isEmbedded ? (
        <MainVideoPlayer />
      ) : (
        <>
          <Header />
          <Sidebar />

          {/* Dropdown Menus */}
          <TranslationNavigator />
          <TranslationsNavigator />
          <CountriesNavigator />

          {/* Modals and Toast - independent of URL state */}
          <GlobalSettingsModal />
          <ShareModal />
          <QRModal />
          <LanguageModal />
          <FeedbackModal />
          <DownloadProgressModal />
          <VersionPickerModal />
          <SearchResultDisplay />

          <Toast />
          <SwitchTransition mode="out-in">
            <CSSTransition key={key} classNames={fade} timeout={300}>
              <Switch>
                <Route exact path="/">
                  <SplashScreen />
                </Route>
                <Route
                  path={["/:code/:ref/:cbtVideoType", "/:code/:ref", "/:code"]}
                >
                  <TranslationPanels />

                  {/* Tab */}
                  <NavigatorTab
                    isPlaceholder
                    isOpen={showTranslationNavigator}
                  />
                </Route>
              </Switch>
            </CSSTransition>
          </SwitchTransition>
        </>
      )}
      {/* <SmartBanner */}
      {/*  daysHidden={0} */}
      {/*  position="bottom" */}
      {/*  ignoreIosVersion */}
      {/*  title="DeafBible" */}
      {/*  appMeta={{ */}
      {/*    ios: "apple-itunes-app", */}
      {/*    android: "google-play-app", */}
      {/*    windows: "msApplication-ID", */}
      {/*    kindle: "kindle-fire-app", */}
      {/*  }} */}
      {/* /> */}
    </div>
  );
};

export default App;
