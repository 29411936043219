import {
  BBBVideo,
  LookupTable,
  getBookName,
  getFirstChapterVerse,
  getReference,
  getTitle,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";
import { Result, SearchListItem } from "../types/searchResult";

export const convertTopicToItems = (
  searchResult: Result[],
  code: string,
  lut: LookupTable
): SearchListItem[] => {
  return searchResult
    .reduce((acc, item) => {
      const verseNumber = item.verses[0].number || "";

      const videoMetadata = lut.lookupByReference(
        item.book,
        item.chapter,
        verseNumber
      );

      if (videoMetadata === undefined) {
        return acc;
      }

      const thumbnailUrl = videoMetadata.thumbnail_url;
      const videoSrcUrl = videoMetadata.video_url.hls;

      const id = `${item.book}.${item.chapter}.${verseNumber}`;
      const baseUrl = `/${code}/${id}`;
      const text = item.verses[0].sections[0];
      const reference = `${getBookName(videoMetadata)} ${
        item.chapter
      }:${verseNumber}`;

      const newItem = {
        id,
        bookId: item.book,
        bbbId: videoMetadata.bbb_id,
        chapter: item.chapter,
        verse: verseNumber,
        baseUrl,
        thumbnailUrl,
        videoSrcUrl,
        text,
        reference,
      };

      acc.push(newItem);
      return acc;
    }, [] as SearchListItem[])
    .filter(
      (item, index, self) => index === self.findIndex((t) => item.id === t.id)
    );
};

export const convertPassageToItems = (
  searchResult: Result[],
  code: string,
  lut: LookupTable
): SearchListItem[] => {
  return searchResult
    .reduce((acc, item) => {
      const videos = new Map<string, BBBVideo>();

      for (let i = 0; i < item.verses.length; i += 1) {
        const verseNumber = item.verses[i].number || "";

        const bbbVideo = lut.lookupByReference(
          item.book,
          item.chapter,
          verseNumber
        );

        if (bbbVideo !== undefined) {
          videos.set(bbbVideo.bbb_id, bbbVideo);
        }
      }

      videos.forEach((videoMetadata) => {
        const thumbnailUrl = videoMetadata.thumbnail_url;
        const videoSrcUrl = videoMetadata.video_url.hls;
        const [_, verseNumber] = getFirstChapterVerse(videoMetadata);

        const id = `${item.book}.${item.chapter}.${verseNumber}`;
        const reference = getReference(videoMetadata);
        const text = getTitle(videoMetadata);

        const baseUrl = `/${code}/${id}`;

        const newItem = {
          id,
          bookId: item.book,
          bbbId: videoMetadata.bbb_id,
          baseUrl,
          thumbnailUrl,
          videoSrcUrl,
          text,
          reference,
        };

        acc.push(newItem);
      });
      return acc;
    }, [] as SearchListItem[])
    .filter(
      (item, index, self) => index === self.findIndex((t) => item.id === t.id)
    );
};
