import React, { CSSProperties, ReactPortal } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import colors from "../../styles/colors.module.css";
import styles from "./styles.module.css";
import { headerHeight, isMobile } from "../../store/screen/selectors";
import useOverlayModal from "../../hooks/useOverlayModal";
import { RootState } from "../../store";
import {
  separatorWidth,
  textPanelWidth,
} from "../../store/screen/panelSelectors";

interface Props {
  children: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode;
  show: boolean;
  closeSelf: () => void;
  showHeader?: boolean;
  showTextPanel?: boolean;
  keepOpen?: boolean;
  closeOnEsc?: boolean;
  closeOnExternalClick?: boolean;
  alwaysHideHeaderIfMobile?: boolean;
  darkerBackground?: boolean;
}

const Overlay = ({
  show,
  closeSelf,
  children,
  showHeader = true,
  showTextPanel = false,
  keepOpen = false,
  closeOnEsc = true,
  closeOnExternalClick = true,
  alwaysHideHeaderIfMobile = false,
  darkerBackground = false,
}: Props): ReactPortal | null => {
  const isMobileDevice = useSelector(isMobile);
  const top = useSelector(headerHeight);

  const offset = useSelector(textPanelWidth);
  const sepWidth = useSelector(separatorWidth);
  const textPanelPosition = useSelector(
    (state: RootState) => state.textPanelSettings.panelPosition
  );

  const ref = useOverlayModal(show && !keepOpen, closeSelf, {
    closeOnEsc,
    closeOnExternalClick,
  });

  const overlayElement = document.getElementById("overlay");

  const overlayStyle = {
    pointerEvents: show && !keepOpen ? "auto" : "none",
    opacity: show || keepOpen ? 1 : 0,
    background:
      (keepOpen && "none") ||
      (darkerBackground && colors.overlayBackgroundDarker) ||
      colors.overlayBackground,
    left: showTextPanel && textPanelPosition === "left" ? offset + sepWidth : 0,
    right:
      showTextPanel && textPanelPosition === "right" ? offset + sepWidth : 0,
    top: !alwaysHideHeaderIfMobile && (showHeader || isMobileDevice) ? top : 0,
    zIndex: keepOpen ? undefined : 10000,
  } as CSSProperties;

  const element = (
    <div style={overlayStyle} className={styles.overlay}>
      {children(ref)}
    </div>
  );

  return (keepOpen && element) || overlayElement !== null
    ? createPortal(element, overlayElement as HTMLElement)
    : null;
};

export default Overlay;
