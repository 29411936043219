import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../Modal";
import SearchBox from "../SearchBox";

import styles from "./styles.module.css";
import { RootState } from "../../store";
import { setShowLanguageModal } from "../../store/interface";
import useInternationalization from "../../hooks/useInternationalization";
import LanguageButtonsGroup from "./LanguageButtonsGroup";

const LanguageModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { getIntlText, getLanguages } = useInternationalization();

  const languages = getLanguages();

  const { showLanguageModal } = useSelector(
    (state: RootState) => state.interface
  );
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const selectedLanguage = useSelector(
    (state: RootState) => state.interface.textLanguage
  );

  const [searchText, setSearchText] = useState<string>("");

  const closeSelf = () => {
    dispatch(setShowLanguageModal(false));
  };

  return (
    <Modal
      show={showLanguageModal}
      closeSelf={closeSelf}
      title={getIntlText("change_language")}
      ariaLabel="Close the Change Language modal"
    >
      <div className={styles.searchBoxContainer}>
        <SearchBox
          darkMode={darkMode}
          value={searchText}
          setValue={setSearchText}
          placeholder={getIntlText("search_for_language")}
        />
      </div>
      <LanguageButtonsGroup
        searchText={searchText}
        darkMode={darkMode}
        selectedLanguage={selectedLanguage}
        closeSelf={closeSelf}
        noLanguageText={getIntlText("no_languages")}
        languages={languages}
      />
    </Modal>
  );
};

export default LanguageModal;
