import React from "react";
import { BaseTranslation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import styles from "./List.module.css";
import Translation from "./Translation";
import ApprovalKey from "./ApprovalKey";

interface Props {
  translations: BaseTranslation[];
  selectTranslation: (translationCode: string) => void;
  selectedTranslationCode: string | undefined;
  darkMode: boolean;
}

const TranslationList = React.memo(
  ({
    translations,
    selectTranslation,
    selectedTranslationCode,
    darkMode,
  }: Props) => {
    const showApprovalKey = translations.some(
      (translation) => translation.quality_checks.length > 0
    );

    return (
      <div className={styles.listContainer}>
        {translations.length > 0 ? (
          translations.map(
            ({
              title,
              subtitle,
              quality_checks: qualityChecks,
              translation_shortcode: shortCode,
              display_translation_type_icon_url: icon,
            }) => {
              const isSelected = selectedTranslationCode === shortCode;

              const handler = () => {
                if (!isSelected) {
                  selectTranslation(shortCode);
                }
              };

              return (
                <div key={shortCode} className={styles.translationContainer}>
                  <Translation
                    handler={handler}
                    darkMode={darkMode}
                    isSelected={isSelected}
                    qualityChecks={qualityChecks}
                    translationIcon={icon}
                    translationCode={shortCode}
                    translationName={title}
                    translationLanguage={subtitle}
                  />
                </div>
              );
            }
          )
        ) : (
          <div />
        )}
        {showApprovalKey && <ApprovalKey darkMode={darkMode} />}
      </div>
    );
  }
);

export default TranslationList;
