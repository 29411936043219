import { RefObject, useEffect, useState } from "react";

const TOTAL_MARGIN_TOP_SPACING = 36;

const useMaxHeight = (
  ref: RefObject<HTMLElement>,
  initialHeight: number
): number => {
  const [maxHeight, setMaxHeight] = useState(initialHeight);

  useEffect(() => {
    const element = ref.current;

    if (element !== null) {
      const observer = new ResizeObserver((entries) => {
        const currentHeight = entries[0].contentRect.height;
        if (currentHeight !== maxHeight) {
          setMaxHeight(element.scrollHeight + TOTAL_MARGIN_TOP_SPACING);
        }
      });

      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [maxHeight, ref]);

  return maxHeight;
};

export default useMaxHeight;
