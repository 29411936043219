import React from "react";

import Button from "./index";

interface Props {
  darkMode: boolean;
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const Square = ({
  darkMode,
  onClick,
  isSelected,
  children,
}: Props): JSX.Element => {
  return (
    <Button
      width={50}
      height={50}
      darkMode={darkMode}
      onClick={onClick}
      selected={isSelected}
    >
      {children}
    </Button>
  );
};

export default Square;
