import { RefObject, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  nextBBBVideo,
  nextCBTVideo,
  translationType,
} from "../../store/translation/selectors";
import useURLManipulator from "../useURLManipulator";

const useAutoAdvance = (
  on: boolean,
  videoRef: RefObject<HTMLVideoElement>
): void => {
  const { selectBBBVideoReset, selectCBTVideo } = useURLManipulator();
  const { autoadvance } = useSelector((state: RootState) => state.settings);

  const type = useSelector(translationType);

  const nextBBB = useSelector(nextBBBVideo);
  const nextCBT = useSelector(nextCBTVideo);

  const endedHandler = useCallback(() => {
    if (type === "BBB" && nextBBB !== undefined) {
      selectBBBVideoReset(nextBBB);
    } else if (nextCBT !== undefined) {
      selectCBTVideo(nextCBT);
    }
  }, [nextBBB, nextCBT, selectBBBVideoReset, selectCBTVideo, type]);

  useEffect(() => {
    if (videoRef.current !== null && autoadvance && on) {
      const element = videoRef.current;
      element.addEventListener("ended", endedHandler);

      return () => {
        element.removeEventListener("ended", endedHandler);
      };
    }

    return undefined;
  }, [endedHandler, videoRef, autoadvance, on]);
};

export default useAutoAdvance;
