import styles from "./Tab.module.css";
import { useTextStyle } from "../../../hooks/useTextStyle";

interface Props {
  darkMode: boolean;
  isSelected: boolean;
  text: string;
  onClick: () => void;
}

const Tab = ({ darkMode, isSelected, text, onClick }: Props): JSX.Element => {
  const textStyle = useTextStyle(
    { large: "1.7em", normal: "1.3em", small: "1.1em" },
    !isSelected
  );

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isSelected && styles.containerSelected}`;

  return (
    <div
      style={textStyle}
      className={container}
      role="button"
      tabIndex={0}
      onKeyPress={undefined}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default Tab;
