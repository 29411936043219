import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { setShowSettingsModal } from "../../store/interface";
import { ReactComponent as TextSize } from "../../assets/icons/text-size.svg";

import styles from "./GearButton.module.css";

const GearButton = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { showSettingsModal } = useSelector(
    (state: RootState) => state.interface
  );

  const onClick = () => {
    dispatch(setShowSettingsModal(!showSettingsModal));
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  return (
    <div
      role="button"
      className={container}
      aria-label="Open Settings Modal"
      onClick={onClick}
      onKeyDown={undefined}
      tabIndex={0}
    >
      <TextSize className={styles.icon} />
    </div>
  );
};

export default GearButton;
