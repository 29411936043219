import axios from "axios";

export interface FeedbackResponse {
  success: boolean;
  message: string;
}

export interface FeedbackPayload {
  email: string;
  comments: string;
}

const sendFeedback = async (
  payload: FeedbackPayload
): Promise<FeedbackResponse> => {
  const url = process.env.REACT_APP_API_FEEDBACK_URL;
  if (url === undefined) {
    throw new Error(`REACT_APP_API_FEEDBACK_URL is not set`);
  }

  const { data } = await axios.post(url, payload);

  return data;
};

export default sendFeedback;
