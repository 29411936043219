import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styles from "./styles.module.css";

import fade from "../../../../styles/transitions/fade.module.css";
import {
  selectedStoryMeta,
  selectedStoryVideo,
} from "../../../../store/translation/selectors";
import Story from "./Story";
import { RootState } from "../../../../store";
import { getCBTSubtitle, getCBTTitle } from "../../../../utils/videoList";

const CBT = (): JSX.Element => {
  const history = useHistory();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { code } = useSelector((state: RootState) => state.params);
  const { selectedVNLanguage } = useSelector(
    (state: RootState) => state.interface
  );
  const storyMeta = useSelector(selectedStoryMeta);
  const storyVideo = useSelector(selectedStoryVideo);

  const defaultToEnglish = code === "VNCBT" && selectedVNLanguage === "EN";

  const title = storyMeta ? getCBTTitle(storyMeta, defaultToEnglish) : "";
  const subtitle = storyMeta ? getCBTSubtitle(storyMeta, defaultToEnglish) : "";

  const key = `${title}${subtitle}`;

  const selectStoryVideo = (type: string) => {
    history.push(
      `/${code}/${storyMeta?.slug}/${type}${window.location.search}`
    );
  };

  return (
    <div className={styles.container}>
      <SwitchTransition mode="out-in">
        <CSSTransition classNames={fade} key={key} timeout={300}>
          <div>
            <Story
              darkMode={darkMode}
              title={title}
              reference={subtitle}
              videoTypes={
                storyMeta?.videos
                  .filter((video) => video.hls !== null)
                  .map((video) => video.type) || []
              }
              selectedVideoType={storyVideo?.type}
              selectVideoType={selectStoryVideo}
            />
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default CBT;
