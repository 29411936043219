import { createSelector } from "@reduxjs/toolkit";

import {
  TranslationType,
  CBTVideoMeta,
  isNewTestament,
  getBookName,
  LookupTable,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { RootState } from "..";
import { getBBBVideoSrc, getCBTVideoSrc } from "../../utils/videoHelper";

export interface FormattedBook {
  shortcode: string;
  isNewTestament: boolean;
  name: string;
  imgUrl: string;
  chapters: string[];
}

export const translationType = (
  state: RootState
): TranslationType | undefined => state.translation.rawData?.translation_type;

export const qualityChecks = (state: RootState): string[] | undefined =>
  state.translation.rawData?.quality_checks;

const translation = (state: RootState) => state.translation.rawData;

const bookOrSlug = (state: RootState) => state.params.bookOrSlug;
const chapter = (state: RootState) => state.params.chapter;
const verse = (state: RootState) => state.params.verse;
const videoType = (state: RootState) => state.params.cbtVideoType;

const teamsData = (state: RootState) => state.translation.rawData?.teams;
const rawBooks = (state: RootState) => state.translation.rawData?.books ?? [];

const rawStories = (state: RootState): CBTVideoMeta[] =>
  state.translation.rawData?.stories || [];

export const lookupTable = createSelector(translation, (t) =>
  t !== undefined ? new LookupTable(t) : undefined
);

const fullList = createSelector(lookupTable, (lut) => lut?.videos());

export const selectedStoryMeta = createSelector(
  rawStories,
  bookOrSlug,
  (videos, slug) => {
    if (slug !== undefined && videos.length > 0) {
      return videos.find((video) => video.slug === slug);
    }
    return undefined;
  }
);

export const nextChapter = createSelector(
  bookOrSlug,
  chapter,
  lookupTable,
  (currentBook, currentChapter, lut) => {
    if (
      lut === undefined ||
      currentBook === undefined ||
      currentChapter === undefined
    ) {
      return undefined;
    }

    const triplet = lut.nextChapterTriplet(currentBook, currentChapter);

    if (triplet === undefined) {
      return undefined;
    }

    return {
      book: triplet[0],
      chapter: triplet[1],
      verse: triplet[2],
    };
  }
);

export const prevChapter = createSelector(
  bookOrSlug,
  chapter,
  lookupTable,
  (currentBook, currentChapter, lut) => {
    if (
      lut === undefined ||
      currentBook === undefined ||
      currentChapter === undefined
    ) {
      return undefined;
    }

    const triplet = lut.prevChapterTriplet(currentBook, currentChapter);

    if (triplet === undefined) {
      return undefined;
    }

    return {
      book: triplet[0],
      chapter: triplet[1],
      verse: triplet[2],
    };
  }
);

export const selectedStoryVideo = createSelector(
  selectedStoryMeta,
  videoType,
  (meta, type) => {
    if (meta !== undefined && type !== undefined) {
      return meta.videos.find(
        (video) => video.type === type && video.hls !== null
      );
    }

    return undefined;
  }
);

export const formattedBooks = createSelector(
  rawBooks,
  lookupTable,
  (books, lut) => {
    if (lut === undefined) {
      return [];
    }

    const result: FormattedBook[] = books.map((book) => ({
      shortcode: book.book_shortcode,
      name: book.title,
      isNewTestament: isNewTestament(book.book_shortcode),
      imgUrl: book.videos[0]?.thumbnail_url,
      chapters: lut.chapterNumbers(book.book_shortcode),
    }));

    return result;
  }
);

export const selectedBBBVideo = createSelector(
  bookOrSlug,
  chapter,
  verse,
  lookupTable,
  (newBook, newChapter, newVerse, lut) => {
    if (
      lut === undefined ||
      newBook === undefined ||
      newChapter === undefined ||
      newVerse === undefined
    ) {
      return undefined;
    }

    return lut.lookupByReference(newBook, newChapter, newVerse.toString());
  }
);

export const currentTeam = createSelector(
  selectedBBBVideo,
  selectedStoryMeta,
  teamsData,
  (bbbVideo, cbtVideoMeta, teams) => {
    if (bbbVideo !== undefined) {
      return teams?.find((team) => team.id === bbbVideo.team_id);
    }

    if (cbtVideoMeta !== undefined) {
      return teams?.find((team) => team.id === cbtVideoMeta.team_id);
    }

    return undefined;
  }
);

export const currentBook = createSelector(selectedBBBVideo, (video) => {
  if (video !== undefined) {
    return getBookName(video);
  }

  return undefined;
});

const currentCBTVideoSrc = createSelector(
  selectedStoryMeta,
  selectedStoryVideo,
  (meta, video) => getCBTVideoSrc(meta, video)
);

const currentBBBVideoSrc = createSelector(selectedBBBVideo, (video) =>
  getBBBVideoSrc(video)
);

export const currentVideoSrc = createSelector(
  currentCBTVideoSrc,
  currentBBBVideoSrc,
  (cbt, bbb) => bbb ?? cbt ?? undefined
);

export const selectedChapterVideos = createSelector(
  bookOrSlug,
  chapter,
  lookupTable,
  (book, chap, lut) => {
    if (lut === undefined || book === undefined || chap === undefined) {
      return [];
    }

    return lut.videosInChapter(book, chap) ?? [];
  }
);

export const currentBBBIndex = createSelector(
  fullList,
  selectedBBBVideo,
  (list, currentVideo) => {
    if (currentVideo === undefined || list === undefined) {
      return undefined;
    }

    const index = list.findIndex(
      (video) => video.bbb_id === currentVideo.bbb_id
    );

    return index;
  }
);

export const nextBBBVideo = createSelector(
  fullList,
  currentBBBIndex,
  (list, index) => {
    if (index === undefined || list === undefined) {
      return undefined;
    }

    const video = list[(index + 1) % list.length];

    return video;
  }
);

export const prevBBBVideo = createSelector(
  fullList,
  currentBBBIndex,
  (list, index) => {
    if (index === undefined || list === undefined) {
      return undefined;
    }

    const video = list[(index + list.length - 1) % list.length];

    return video;
  }
);

export const nextCBTMeta = createSelector(
  rawStories,
  selectedStoryMeta,
  selectedStoryVideo,
  (list, currentMeta, currentVideo) => {
    const metaIndex = list.findIndex((meta) => meta === currentMeta);
    const currentVideos = currentMeta?.videos.filter(
      (video) => video.hls !== null
    );

    const videoIndex = currentVideos?.findIndex(
      (video) => video === currentVideo
    );

    if (
      currentVideos !== undefined &&
      metaIndex !== undefined &&
      videoIndex !== undefined &&
      metaIndex >= 0 &&
      videoIndex >= 0
    ) {
      if (videoIndex < currentVideos.length - 1) {
        return currentMeta;
      }

      if (metaIndex < list.length - 1) {
        return list[metaIndex + 1];
      }
    }

    return undefined;
  }
);

export const prevCBTMeta = createSelector(
  rawStories,
  selectedStoryMeta,
  selectedStoryVideo,
  (list, currentMeta, currentVideo) => {
    const metaIndex = list.findIndex((meta) => meta === currentMeta);
    const currentVideos = currentMeta?.videos.filter(
      (video) => video.hls !== null
    );

    const videoIndex = currentVideos?.findIndex(
      (video) => video === currentVideo
    );

    if (
      currentVideos !== undefined &&
      metaIndex !== undefined &&
      videoIndex !== undefined &&
      metaIndex >= 0 &&
      videoIndex >= 0
    ) {
      if (videoIndex <= 1) {
        return currentMeta;
      }

      if (metaIndex <= 1) {
        return list[metaIndex + 1];
      }
    }

    return undefined;
  }
);

export const nextCBTVideo = createSelector(
  rawStories,
  selectedStoryMeta,
  selectedStoryVideo,
  (list, currentMeta, currentVideo) => {
    const metaIndex = list.findIndex((meta) => meta === currentMeta);

    const currentVideos = currentMeta?.videos.filter(
      (video) => video.hls !== null
    );

    const videoIndex = currentVideos?.findIndex(
      (video) => video === currentVideo
    );

    if (
      currentMeta !== undefined &&
      currentVideos !== undefined &&
      videoIndex !== undefined
    ) {
      if (videoIndex < currentVideos.length - 1) {
        return currentVideos[videoIndex + 1];
      }

      if (metaIndex < list.length - 1) {
        const newMeta = list[metaIndex + 1];
        return newMeta.videos.filter((video) => video.hls !== null)[0];
      }
    }

    return undefined;
  }
);

export const prevCBTVideo = createSelector(
  rawStories,
  selectedStoryMeta,
  selectedStoryVideo,
  (list, currentMeta, currentVideo) => {
    const metaIndex = list.findIndex((meta) => meta === currentMeta);

    const currentVideos = currentMeta?.videos.filter(
      (video) => video.hls !== null
    );

    const videoIndex = currentVideos?.findIndex(
      (video) => video === currentVideo
    );

    if (
      currentMeta !== undefined &&
      currentVideos !== undefined &&
      videoIndex !== undefined
    ) {
      if (videoIndex > 0) {
        return currentVideos[videoIndex - 1];
      }

      if (metaIndex > 0) {
        const newMeta = list[metaIndex - 1];
        return newMeta.videos.filter((video) => video.hls !== null)[0];
      }
    }

    return undefined;
  }
);
