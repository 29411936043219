/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  faCommentDots,
  faChalkboardTeacher,
  faInfoCircle,
  faBible,
  IconDefinition,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";

import { CBTVideoMeta } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

interface CBTIcons {
  [key: string]: {
    label: string;
    icon: IconDefinition;
  };
}

export const cbtIcons: CBTIcons = {
  intro: {
    label: "intro",
    icon: faDoorOpen,
  },
  story: {
    label: "story",
    icon: faBible,
  },
  lesson: {
    label: "lesson",
    icon: faChalkboardTeacher,
  },
  commentary: {
    label: "commentary",
    icon: faCommentDots,
  },
  "more-info": {
    label: "more info",
    icon: faInfoCircle,
  },
  "intro-passage": {
    label: "intro & passage",
    icon: faBible,
  },
  "intro-passage-moreinfo": {
    label: "intro, passage & more info",
    icon: faChalkboardTeacher,
  },
};

export const getCBTTitle = (
  metadata: CBTVideoMeta,
  defaultToEnglish: boolean
): string =>
  defaultToEnglish
    ? metadata.title_english || ""
    : metadata.title || metadata.title_english || "";

export const getCBTSubtitle = (
  metadata: CBTVideoMeta,
  defaultToEnglish: boolean
): string =>
  defaultToEnglish
    ? metadata.subtitle_english || ""
    : metadata.subtitle || metadata.subtitle_english || "";
