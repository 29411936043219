import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";

import BBB from "./BBB";
import CBT from "./CBT";
import { translationType } from "../../../store/translation/selectors";

import fade from "../../../styles/transitions/fade.module.css";
import { RootState } from "../../../store";

const MetadataBar = (): JSX.Element => {
  const type = useSelector(translationType);
  const { code } = useSelector((state: RootState) => state.params);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition classNames={fade} timeout={300} key={code}>
        <div>
          {type === "BBB" && <BBB />}
          {type === "CBT" && <CBT />}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default MetadataBar;
