/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import {
  TextPanelSettingsState,
  getTextPanelSettings,
  setTextPanelSettings,
} from "../utils/localStorage";

const defaultValues: TextPanelSettingsState = {
  lineHeight: "normal",
  panelPosition: "left",
};

const initialState = getTextPanelSettings() || defaultValues;

const textPanelSettingsSlice = createSlice({
  name: "textPanelSettings",
  initialState,
  reducers: {
    setLineHeight: (state, action) => {
      state.lineHeight = action.payload;
      setTextPanelSettings(state);
    },
    setPanelPosition: (state, action) => {
      state.panelPosition = action.payload;
      setTextPanelSettings(state);
    },
  },
});

export const { setLineHeight, setPanelPosition } =
  textPanelSettingsSlice.actions;

export default textPanelSettingsSlice.reducer;
