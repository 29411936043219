import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideToast, setToastMessage } from "../store/interface";

const useNetworkEvents = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("offline", () => {
      dispatch(setToastMessage("Internet is disconnected"));
    });
    window.addEventListener("online", () => {
      dispatch(hideToast());
    });
  }, [dispatch]);
};

export default useNetworkEvents;
