import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

import { captureMessage } from "@sentry/browser";
import { RootState } from "../store";
import getTimestamps from "../utils/api/getTimestamps";
import { setTimestampLoadingState, setTimestamps } from "../store/timestamps";
import { LoadingState } from "../types/loadingState";

const useLoadTimestamps = (): void => {
  const dispatch = useDispatch();
  const { code, bookOrSlug } = useSelector((state: RootState) => state.params);

  const { data } = useQuery(
    ["timestamps", code, bookOrSlug],
    () => {
      try {
        dispatch(setTimestampLoadingState(LoadingState.loading));
        return getTimestamps(code, bookOrSlug);
      } catch (e) {
        dispatch(setTimestampLoadingState(LoadingState.error));
        return Promise.resolve([]);
      }
    },
    {
      onError: (error) => {
        captureMessage(
          `Error fetching timestamps for ${code} - ${bookOrSlug}: ${JSON.stringify(
            error
          )}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    dispatch(setTimestampLoadingState(LoadingState.success));
    dispatch(setTimestamps(data));
  }, [dispatch, data]);
};

export default useLoadTimestamps;
