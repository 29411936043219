import React, { useRef } from "react";

import styles from "./styles.module.css";
import Step from "./Step";
import useRangeSlider from "../../../hooks/useRangeSlider";
import Slide from "./Slide";

interface Props {
  options: string[];
  selectedOption: string;
  selectOption: (option: string) => void;
  darkMode: boolean;
  labels?: React.ReactNode[];
}

const RangeSlider = ({
  options,
  selectedOption,
  selectOption,
  darkMode,
  labels = [],
}: Props): JSX.Element => {
  const rangeRef = useRef<HTMLDivElement>(null);

  const slideOffset = useRangeSlider(
    rangeRef.current,
    options,
    selectedOption,
    selectOption
  );

  const rangeClass = `${styles.range} ${darkMode && styles.rangeDarkMode}`;

  return (
    <div ref={rangeRef} className={styles.container}>
      <div className={styles.rangeContainer}>
        <div className={rangeClass}>
          {options.map((option) => {
            return <Step key={option} darkMode={darkMode} />;
          })}
        </div>

        <Slide offset={slideOffset} />
      </div>
      {labels.length > 0 && (
        <div className={styles.labels}>
          {labels.map((label) => (
            <div className={styles.label}>{label}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RangeSlider;
