// eslint-disable camelcase

import axios from "axios";

import { BaseTranslation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

export interface TranslationsByCountry {
  countryCode: string;
  translations: BaseTranslation[];
}

export const getTranslationsByCountry = async (
  countryCode: string
): Promise<BaseTranslation[]> => {
  if (countryCode) {
    const { data } = await axios.get<BaseTranslation[]>(
      `${process.env.REACT_APP_NEW_API}/countries/${countryCode}/translations`
    );

    return data;
  }

  return [];
};

const getTranslations = async (
  countryCodes: string[]
): Promise<TranslationsByCountry[]> => {
  const result = await Promise.all(
    countryCodes.map(async (code) => {
      const translations = await getTranslationsByCountry(code);
      return {
        translations,
        countryCode: code,
      };
    })
  );

  return result;
};

export default getTranslations;
