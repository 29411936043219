import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { captureMessage } from "@sentry/browser";
import getInternationalization, {
  AllInternationalization,
} from "../utils/api/getInternationalization";
import { setData } from "../store/internationalization";

const useLoadInternationalization = (): void => {
  const dispatch = useDispatch();

  const { data } = useQuery<AllInternationalization>(
    [],
    getInternationalization,
    {
      onError: (error) => {
        captureMessage(
          `Error fetching internationalization: ${JSON.stringify(error)}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    dispatch(setData(data));
  }, [dispatch, data]);
};

export default useLoadInternationalization;
