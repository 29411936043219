import { useTextStyle } from "../../../hooks/useTextStyle";
import Item from "./Item";

import styles from "./Title.module.css";

interface Props {
  textSections: string[];
  darkMode: boolean;
}

const Title = ({ textSections, darkMode }: Props) => {
  const textStyle = useTextStyle(
    {
      large: "2.5em",
      normal: "1.2em",
      small: "1em",
    },
    true
  );

  return (
    <Item darkMode={darkMode}>
      <div style={textStyle} className={styles.title}>
        {textSections.map((section) => (
          <div key={section}>{section}</div>
        ))}
      </div>
    </Item>
  );
};

export default Title;
