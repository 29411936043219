import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useURLManipulator from "../../../hooks/useURLManipulator";
import Title from "./Title";
import Verse from "./Verse";
import LoadingIndicator from "../../LoadingIndicator";

import { Verse as Section } from "../../../types/searchResult";
import { NIVCopyright } from "./NIVCopyright";

interface Props {
  verses: Section[];
}

const BrowseItems = ({ verses }: Props): JSX.Element => {
  const { verse, chapter, textVersion } = useSelector(
    (state: RootState) => state.params
  );
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const { updateChapterVerse } = useURLManipulator();

  return verses.length > 0 ? (
    <>
      {verses.map((row) => {
        const isSelected =
          verse !== undefined && row?.number === verse.toString();

        const onClick = () => {
          if (chapter !== undefined && row.number !== undefined) {
            updateChapterVerse(chapter, row.number);
          }
        };

        if (row.type === "title") {
          return (
            <Title
              key={row.sections.toString()}
              textSections={row.sections}
              darkMode={darkMode}
            />
          );
        }

        return (
          <Verse
            id={`row-${row.number}`}
            key={`row-${row.number}`}
            verseNumber={row?.number ?? ""}
            textSections={row.sections}
            darkMode={darkMode}
            isSelected={isSelected}
            select={onClick}
          />
        );
      })}

      {textVersion?.toUpperCase() === "NIV" && <NIVCopyright />}
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default BrowseItems;
