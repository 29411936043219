import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import useInternationalization from "../../hooks/useInternationalization";
import TextField from "./TextField";

interface Props {
  email: string;
  darkMode: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Email = ({ email, darkMode, onChange }: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  return (
    <TextField
      placeholder={getIntlText("email")}
      value={email}
      onChange={onChange}
      darkMode={darkMode}
      icon={faEnvelope}
      required
    />
  );
};

export default Email;
