import { useSelector } from "react-redux";
import { textPanelStyle } from "../../store/screen/panelSelectors";
import Header from "./Header";

import styles from "./styles.module.css";
import SettingsModal from "./SettingsModal";
import Content from "./Content";

const TextPanel = (): JSX.Element => {
  const containerStyle = useSelector(textPanelStyle);

  return (
    <div style={containerStyle} className={styles.container}>
      <div className={styles.innerContainer}>
        <Header />
        <SettingsModal />
        <Content />
      </div>
    </div>
  );
};

export default TextPanel;
