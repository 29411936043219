interface Reference {
  bookOrSlug?: string;
  chapter?: string;
  verse?: string;
  cbtVideoType?: string;
}

interface URLParams extends Reference {
  code?: string;
  start?: string;
  end?: string;
  textVersion?: string;
  embed?: boolean;
}

export const deconstructRef = (ref: string): Reference => {
  if (ref !== undefined) {
    const [bookOrSlug, chapter, verse] = ref.split(".");
    return {
      bookOrSlug,
      chapter,
      verse,
    };
  }
  return {};
};

const deconstructQuery = (query: string): { [key: string]: string }[] => {
  const entries = query.split("&");
  return entries.map((entry) => {
    const keyValue = entry.split("=");
    return { key: keyValue[0], value: keyValue[1] };
  });
};

export const deconstructParams = (pathname: string): string[] =>
  pathname.substring(1).split("/");

export const getChapterVerseParams = (): [
  string | undefined,
  string | undefined
] => {
  const [, ref] = deconstructParams(window.location.pathname);
  const partialRef = deconstructRef(ref);

  return [partialRef.chapter, partialRef.verse];
};

export const getURLParams = (): URLParams => {
  const query = window.location.search.substring(1);
  const params = query.length > 3 ? deconstructQuery(query) : [];

  const [code, ref, cbtVideoType] = deconstructParams(window.location.pathname);
  const partialRef = deconstructRef(ref);

  const textVersion = params.find((param) => param.key === "text")?.value;

  const isEmbed = params.some(
    (param) => param.key === "mode" && param.value === "embed"
  );

  return {
    code,
    ...partialRef,
    cbtVideoType,
    start: params.find((param) => param.key === "start")?.value,
    end: params.find((param) => param.key === "end")?.value,
    embed: isEmbed,
    textVersion,
  };
};

interface TimeRange {
  start: string | undefined;
  end: string | undefined;
}

export const getTimeRange = (): TimeRange => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const start = params.get("start");
  const end = params.get("end");

  return {
    start: start || undefined,
    end: end || undefined,
  };
};

export const replaceChapterVerse = (
  newChapter: string,
  newVerse: string
): void => {
  const { code, bookOrSlug, chapter, verse } = getURLParams();

  if (newChapter !== chapter || newVerse !== verse) {
    window.history.replaceState(
      window.history.state,
      "",
      `/${code}/${bookOrSlug}.${newChapter}.${newVerse}${window.location.search}`
    );
  }
};

export const updateStartTime = (time: number) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.set("start", time.toString());

  window.history.replaceState(
    window.history.state,
    "",
    `${url.pathname}?${params.toString()}`
  );
};

export const updateEndTime = (time: number) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.set("end", time.toString());

  window.history.replaceState(
    window.history.state,
    "",
    `${url.pathname}?${params.toString()}`
  );
};

export const paramsWithoutRange = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete("start");
  params.delete("end");

  return params.toString();
};

export const urlWithRange = (
  startTime: string | undefined,
  endTime: string | undefined
) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (startTime !== undefined && endTime !== undefined) {
    params.set("start", startTime.toString());
    params.set("end", endTime.toString());
  } else {
    params.delete("start");
    params.delete("end");
  }

  return `${url.pathname}${
    params.toString() !== "" ? `?${params.toString()}` : ""
  }`;
};
