import { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  internationalizationLUT,
  languages,
} from "../store/internationalization/selectors";
import { Language, NativeWords } from "../utils/api/getInternationalization";
import defaultIntl from "../data/default_intl.json";

interface Intl {
  getIntlText: (text: string) => string;
  getLanguages: () => Language[];
}

const useInternationalization = (): Intl => {
  const lut = useSelector(internationalizationLUT);
  const availableLanguages = useSelector(languages);
  const defaultValues = defaultIntl as NativeWords;

  const getIntlText = useCallback(
    (text: string) => {
      if (lut !== undefined && lut[text] !== undefined) {
        return lut[text];
      }
      if (defaultValues[text] !== undefined) {
        return defaultValues[text];
      }
      return text;
    },
    [defaultValues, lut]
  );

  const getLanguages = useCallback(
    () => availableLanguages || [],
    [availableLanguages]
  );

  return {
    getIntlText,
    getLanguages,
  };
};

export default useInternationalization;
