import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStamp, faUsers } from "@fortawesome/free-solid-svg-icons";

import styles from "./ApprovalIcon.module.css";

interface Props {
  icon: "church" | "consultant";
  color: string;
}

const ApprovalIcon = ({ icon, color }: Props): JSX.Element => {
  const style = {
    border: `1px solid ${color}`,
    color,
  };

  const approvalIcon = icon === "church" ? faUsers : faStamp;

  return (
    <div style={style} className={styles.iconContainer}>
      <FontAwesomeIcon icon={approvalIcon} className={styles.icon} />
    </div>
  );
};

export default ApprovalIcon;
