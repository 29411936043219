import axios from "axios";

export interface Country {
  id: string;
  name: string;
  flag_url: string;
  display_order: number;
}

export default async (): Promise<Country[]> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_NEW_API}/countries`
  );
  return data;
};
