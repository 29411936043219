import { useLayoutEffect } from "react";

interface Dimensions {
  width: number;
  height: number;
}

type ResizeCallback = (dimensions: Dimensions) => void;

const getDimensions = (element: Element): Dimensions => {
  const { width, height } = element.getBoundingClientRect();
  return { width, height };
};

const useResizeObserver = (
  active: boolean,
  element: Element | null,
  callback: ResizeCallback
) => {
  useLayoutEffect(() => {
    if (active && element !== null) {
      callback(getDimensions(element));

      const handler = () => {
        if (element !== null) {
          callback(getDimensions(element));
        }
      };

      const observer = new ResizeObserver(handler);
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [active, callback, element]);
};

export default useResizeObserver;
