import { SwitchTransition, CSSTransition } from "react-transition-group";
import LanguageButton from "./LanguageButton";

import styles from "./styles.module.css";
import fade from "../../styles/transitions/fade.module.css";
import contains from "../../utils/strings";
import { Language } from "../../utils/api/getInternationalization";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  searchText: string;
  darkMode: boolean;
  selectedLanguage: string;
  closeSelf: () => void;
  noLanguageText: string;
  languages: Language[];
}

const LanguageButtonsGroup = ({
  searchText,
  darkMode,
  selectedLanguage,
  closeSelf,
  noLanguageText,
  languages,
}: Props): JSX.Element => {
  const filteredLanguages = languages.filter(
    (language) =>
      contains(language.name, searchText) ||
      contains(language.englishName, searchText)
  );

  const key = filteredLanguages.reduce((acc, cur) => acc.concat(cur.code), "");

  const textStyle = useTextStyle({
    large: "1.4em",
    normal: "1em",
    small: "0.9em",
  });

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={key} timeout={300} classNames={fade}>
        <div className={styles.buttonsContainer}>
          {filteredLanguages.length > 0 ? (
            filteredLanguages.map((language) => {
              return (
                <LanguageButton
                  key={language.code}
                  code={language.code}
                  name={language.name}
                  englishName={language.englishName}
                  closeSelf={closeSelf}
                  darkMode={darkMode}
                  selectedLanguage={selectedLanguage}
                />
              );
            })
          ) : (
            <div style={textStyle} className={styles.noAvail}>
              {noLanguageText}
            </div>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default LanguageButtonsGroup;
