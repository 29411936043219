import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./styles.module.css";

export enum Type {
  Primary = "primary",
  Secondary = "secondary",
}

interface Props {
  onClick: () => void;
  children?: React.ReactNode;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  type?: Type;
  lighterVersion?: boolean;
  darkMode?: boolean;
  morePadding?: boolean;
}

const Button = ({
  onClick,
  children,
  leftIcon,
  rightIcon,
  type = Type.Primary,
  lighterVersion = false,
  darkMode,
  morePadding = false,
}: Props): JSX.Element => {
  const isSecondary = type === Type.Secondary;

  const buttonStyle = `${styles.button} 
  ${type === Type.Primary && styles.primaryButton} 
  ${isSecondary && styles.secondaryButton} 
  ${isSecondary && darkMode && styles.darkModeSecondaryButton} 
  ${isSecondary && lighterVersion && styles.lighterSecondaryButton} 
  ${
    isSecondary &&
    lighterVersion &&
    darkMode &&
    styles.darkModeLighterSecondaryButton
  }
  ${morePadding && styles.morePadding}
  `;

  const iconColorStyle = `${isSecondary && styles.icon} ${
    isSecondary && darkMode && styles.darkModeIcon
  }`;

  return (
    <button type="button" onClick={onClick} className={buttonStyle}>
      {leftIcon && (
        <FontAwesomeIcon
          icon={leftIcon}
          className={`${styles.leftIcon} ${iconColorStyle}`}
        />
      )}
      {children}
      {rightIcon && (
        <FontAwesomeIcon
          icon={rightIcon}
          className={`${styles.rightIcon} ${iconColorStyle}`}
        />
      )}
    </button>
  );
};

export default Button;
