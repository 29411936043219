import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../store";
import { setDarkMode } from "../../store/settings";
import colors from "../../styles/colors.module.css";
import { ReactComponent as Sun } from "../../assets/icons/sun.svg";

import styles from "./Theme.module.css";

const ThemeSwitch = (): JSX.Element => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const toggleTheme = () => dispatch(setDarkMode(!darkMode));

  return (
    <Switch
      checked={darkMode}
      offColor={colors.mainSurfaceDark}
      onColor={colors.mainSurfaceLight}
      handleDiameter={22.5}
      offHandleColor={colors.mainSurfaceLight}
      onHandleColor={colors.gray5}
      uncheckedIcon={
        <div className={styles.iconContainer}>
          <Sun className={styles.sunIcon} />
        </div>
      }
      checkedIcon={
        <div className={styles.iconContainer}>
          <FontAwesomeIcon
            icon={faMoon}
            color={colors.mainSurfaceDark}
            className={styles.moonIcon}
          />
        </div>
      }
      onChange={toggleTheme}
    />
  );
};

export default ThemeSwitch;
