import { useQuery } from "react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getTextVersionsMetadata from "../utils/api/getTextVersionsMetadata";
import {
  setPreviouslySelectedVersion,
  setVersionsMetadata,
} from "../store/textPanelContent";
import useHomeCountryCode from "./useHomeCountryCode";
import { RootState } from "../store";
import { setPrevTextVersion } from "../utils/localStorage";

const useLoadTextVersionsMetadata = (): void => {
  const dispatch = useDispatch();

  const { previouslySelectedVersion } = useSelector(
    (state: RootState) => state.textPanelContent
  );

  const homeCountryCode = useHomeCountryCode();

  const { data } = useQuery(["textVersionsMetadata"], getTextVersionsMetadata, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (previouslySelectedVersion === undefined && data !== undefined) {
      const textLanguage =
        data.find((language) =>
          language.homeCountries.some((country) => homeCountryCode === country)
        ) || data.find((language) => language.code === "EN");

      const defaultVersion = textLanguage?.versions[0].code || "NIV";
      setPrevTextVersion(defaultVersion);
      dispatch(setPreviouslySelectedVersion(defaultVersion));
    }
  }, [homeCountryCode, data, previouslySelectedVersion, dispatch]);

  useEffect(() => {
    if (data !== undefined) {
      const index = data.findIndex((language) =>
        language.homeCountries.some((country) => country === homeCountryCode)
      );

      if (index >= 0) {
        const sortedData = [...data];
        [sortedData[0], sortedData[index]] = [sortedData[index], sortedData[0]];
        dispatch(setVersionsMetadata(sortedData));
      } else {
        dispatch(setVersionsMetadata(data));
      }
    }
  }, [dispatch, data, homeCountryCode]);
};

export default useLoadTextVersionsMetadata;
