import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import styles from "./SendButton.module.css";

import useInternationalization from "../../hooks/useInternationalization";
import Button from "../Button";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  inProgress: boolean;
  onClick: () => void;
}

const SendButton = ({ inProgress, onClick }: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  const textStyle = useTextStyle({
    large: "1.4em",
    normal: "1em",
    small: "0.9em",
  });

  return (
    <div style={textStyle} className={styles.sendButtonContainer}>
      <Button
        rightIcon={inProgress ? undefined : faPaperPlane}
        onClick={onClick}
      >
        {inProgress ? (
          <div className={styles.spinner}>
            <PulseLoader size={10} color="#E8EEF2" />
          </div>
        ) : (
          getIntlText("send_feedback")
        )}
      </Button>
    </div>
  );
};

export default SendButton;
