import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

import breakpoints from "../../styles/breakpoints.module.css";

export const BPMobile = parseInt(breakpoints.BPmobile.slice(0, -2), 10);

export const windowWidth = (state: RootState): number =>
  state.screen.dimensions.width;
export const windowHeight = (state: RootState): number =>
  state.screen.dimensions.height;

export const isMobile = createSelector(
  windowWidth,
  (width) => BPMobile > width
);

export const headerHeight = (_state: RootState): number => 92;

export const logoType = createSelector(isMobile, (isMobil) =>
  isMobil ? "short" : "long"
);
