import { useCallback, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CBTVideoType } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { RootState } from "../../../../store";
import { setExpandedItem } from "../../../../store/interface";
import useInternationalization from "../../../../hooks/useInternationalization";

import fade from "../../../../styles/transitions/fade.module.css";
import { getCBTTitle } from "../../../../utils/videoList";
import { keepNavigatorOpen } from "../../../../store/screen/navigationSidebarSelectors";
import { Title } from "../Title";
import { Content } from "../Content";
import { CBTVideo } from "../Content/CBTVideo";

interface Props {
  filterText: string;
  closeSidebar: () => void;
}

const CBT = ({ filterText, closeSidebar }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const show = useSelector(
    (state: RootState) => state.interface.showSelectorList
  );

  const keepNavOpen = useSelector(keepNavigatorOpen);
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { code, bookOrSlug, cbtVideoType } = useSelector(
    (state: RootState) => state.params
  );

  const { expandedItem: selectedStory, selectedVNLanguage } = useSelector(
    (state: RootState) => state.interface
  );

  const useEnglishTitle = code === "VNCBT" && selectedVNLanguage === "EN";

  useEffect(() => {
    if (show || keepNavOpen) {
      const element = document.getElementById(bookOrSlug || "");
      element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [show, bookOrSlug, keepNavOpen]);

  const { getIntlText } = useInternationalization();

  const setSelectedStory = (newStory: string) => {
    dispatch(setExpandedItem(newStory));
  };

  const cbtVideos = useSelector(
    (state: RootState) => state.translation.rawData?.stories || []
  );

  const filteredCbtVideos = cbtVideos.filter((cbtVideo) =>
    getCBTTitle(cbtVideo, useEnglishTitle)
      .toLowerCase()
      .includes(filterText.toLowerCase())
  );

  const selectStory = useCallback(
    (slug: string, videoType: CBTVideoType): void => {
      closeSidebar();
      history.push(`/${code}/${slug}/${videoType}`);
    },
    [closeSidebar, code, history]
  );

  const key = filteredCbtVideos.reduce((acc, cur) => acc + cur.slug, "");

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        mountOnEnter
        unmountOnExit
        classNames={fade}
        timeout={300}
        key={key}
      >
        <div>
          {filteredCbtVideos.map((cbtVideo) => {
            const isSelected = cbtVideo.slug === selectedStory;
            const subItems = cbtVideo.videos
              .filter((video) => video.hls !== null)
              .map((video) => ({
                id: video.type,
                text: getIntlText(video.type),
              }));

            const selectVideoType = (videoType: string) =>
              selectStory(cbtVideo.slug, videoType as CBTVideoType);

            const onClick = () => {
              setSelectedStory(cbtVideo.slug);
              if (subItems.length === 1) {
                selectVideoType(subItems[0].id);
              }
            };

            const selectedSubItem =
              cbtVideo.slug === bookOrSlug ? cbtVideoType : undefined;

            return (
              <div key={cbtVideo.slug}>
                <Title
                  isSelected={isSelected}
                  onClick={onClick}
                  text={getCBTTitle(cbtVideo, useEnglishTitle)}
                />
                <Content darkMode={darkMode} show={isSelected}>
                  {subItems.map(({ id, text }) => {
                    const subOnClick = () => {
                      selectStory(cbtVideo.slug, id);
                    };
                    return (
                      <CBTVideo
                        key={id}
                        onClick={subOnClick}
                        isSelected={selectedSubItem === id}
                        darkMode={darkMode}
                        id={id}
                        text={text}
                      />
                    );
                  })}
                </Content>
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default CBT;
