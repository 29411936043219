import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import sendFeedback from "../../utils/api/apiFeedback";
import Modal from "../Modal";
import FeedbackVideo from "../SidebarModal/FeedbackVideo";

import styles from "./styles.module.css";
import fade from "../../styles/transitions/fade.module.css";

import { RootState } from "../../store";
import { setShowFeedbackModal } from "../../store/interface";

import useInternationalization from "../../hooks/useInternationalization";
import ThankYou from "./ThankYou";
import ErrorMessage from "./ErrorMessage";
import SendButton from "./SendButton";
import Email from "./Email";
import Comments from "./Comments";
import { useTextStyle } from "../../hooks/useTextStyle";

const FeedbackModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { showFeedbackModal } = useSelector(
    (state: RootState) => state.interface
  );
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const [mode, setMode] = useState<"form" | "thankYou">("form");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);

  const { getIntlText } = useInternationalization();

  const feedbackContainerRef = useRef<HTMLDivElement>(null);

  const show = showFeedbackModal;

  const closeSelf = () => {
    dispatch(setShowFeedbackModal(false));
    setTimeout(() => {
      setEmail("");
      setComments("");
      setInProgress(false);
      setMode("form");
      setShowErrorMessage(false);
      setHideHeader(false);
    }, 1000); // Need to ask Gabe which is best practice here
  };

  const submitFeedback = async () => {
    const payload = {
      email,
      comments,
    };

    const response = await sendFeedback(payload);

    if (response.success) {
      setMode("thankYou");
      setHideHeader(true);
    } else {
      setShowErrorMessage(true);
      setInProgress(false);
    }
  };

  const textStyle = useTextStyle(
    {
      large: "1.4em",
      normal: "1em",
      small: "0.9em",
    },
    true
  );

  return (
    <Modal
      show={show}
      closeSelf={closeSelf}
      title={getIntlText("feedback")}
      ariaLabel="Close the Feedback modal"
      hideHeader={hideHeader}
    >
      <div ref={feedbackContainerRef} className={styles.feedbackFormContainer}>
        <SwitchTransition mode="out-in">
          <CSSTransition key={mode} classNames={fade} timeout={300}>
            {mode === "form" ? (
              <div className={styles.formContainer}>
                <div className={styles.topRow}>
                  {/* Next line will be replace soon */}
                  <FeedbackVideo play={show && mode === "form"} />
                  <div style={textStyle} className={styles.title}>
                    {getIntlText("how_can_we_improve_and_serve_you_better")}
                  </div>
                </div>
                <Email
                  email={email}
                  darkMode={darkMode}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setEmail(newValue);
                  }}
                />
                <Comments
                  comments={comments}
                  darkMode={darkMode}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setComments(newValue);
                  }}
                />
                <SendButton
                  inProgress={inProgress}
                  onClick={() => {
                    if (!inProgress) {
                      setInProgress(true);
                      submitFeedback();
                    }
                  }}
                />
                <ErrorMessage show={showErrorMessage} darkMode={darkMode} />
              </div>
            ) : (
              <ThankYou closeSelf={closeSelf} darkMode={darkMode} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
