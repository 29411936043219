import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { captureMessage } from "@sentry/browser";
import getCountries, { Country } from "../utils/api/getCountries";

import { RootState } from "../store";
import { setSelectedCountry } from "../store/translations";
import {
  hideToast,
  setShowCountriesDropdown,
  setShowTranslationsDropdown,
  setToastMessage,
} from "../store/interface";
import { setCode } from "../store/params";
import { setTranslation } from "../store/translation";

interface CountryList {
  countries: Country[];
  listCode: string[];
  selectedCountry: Country | undefined;
  selectCountry: (countryCode: string) => void;
  isLoading: boolean;
}

const useCountries = (): CountryList => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translationsByCountry = useSelector(
    (state: RootState) => state.translations.rawData
  );
  const selectedCountryCode = useSelector(
    (state: RootState) => state.translations.selectedCountry
  );

  const selectedCode = useSelector((state: RootState) => state.params.code);

  const { data, isLoading } = useQuery(
    "countries",
    async () => {
      try {
        const countries = await getCountries();
        const listCode = countries.map((country) => country.id);
        dispatch(hideToast());

        return { countries, listCode };
      } catch (err) {
        dispatch(setToastMessage("Internet is disconnected"));
        return undefined;
      }
    },
    {
      onError: (error) => {
        captureMessage(
          `Error fetching countries metadata: ${JSON.stringify(error)}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const selectedCountry = data?.countries.find(
    (country) => country.id === selectedCountryCode
  );

  const selectCountry = useCallback(
    (countryCode: string) => {
      dispatch(setSelectedCountry(countryCode));

      const rawData = translationsByCountry?.find(
        (dataa) => dataa.countryCode === countryCode
      );

      if (rawData !== undefined && rawData.translations.length > 1) {
        dispatch(setShowCountriesDropdown(false));
        dispatch(setShowTranslationsDropdown(true));
      } else {
        const code = rawData?.translations[0].translation_shortcode;
        if (code !== selectedCode) {
          dispatch(setCode(code));
          dispatch(setTranslation(undefined));
          history.push(`/${code}`);
        }
      }
    },
    [selectedCode, dispatch, history, translationsByCountry]
  );

  return useMemo(
    () => ({
      selectedCountry,
      selectCountry,
      countries:
        data?.countries.sort((a, b) => a.name.localeCompare(b.name)) || [],
      listCode: data?.listCode || [],
      isLoading,
    }),
    [data, isLoading, selectCountry, selectedCountry]
  );
};

export default useCountries;
