/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  BBBVideo,
  CBTVideoMeta,
  CBTVideoType,
  Translation,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

interface SelectedSecondaryVideo {
  translation: Translation;
  video: BBBVideo | CBTVideoMeta;
  type?: CBTVideoType;
}

interface TranslationState {
  rawData?: Translation;
  secondary?: Translation[];
  selectedSecondaryVideo?: SelectedSecondaryVideo;
  showSecondaryVideo: boolean;
}

const initialState: TranslationState = {
  showSecondaryVideo: false,
};

const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setTranslation: (state, action): void => {
      state.rawData = action.payload;
    },
    setSecondaryTranslations: (state, action): void => {
      state.secondary = action.payload;
    },
    setSelectedSecondaryVideo: (state, action): void => {
      if (action.payload === undefined) {
        state.showSecondaryVideo = false;
      } else {
        state.selectedSecondaryVideo = action.payload;
        state.showSecondaryVideo = true;
      }
    },
  },
});

export const {
  setTranslation,
  setSecondaryTranslations,
  setSelectedSecondaryVideo,
} = translationSlice.actions;

export default translationSlice.reducer;
