import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "../store";
import {
  setDownloadProgress,
  setVideoDownloadState,
  VideoDownloadState,
} from "../store/interface";
import {
  selectedBBBVideo,
  selectedStoryMeta,
  selectedStoryVideo,
} from "../store/translation/selectors";
import {
  clipVideo,
  downloadUrl,
  formatDownloadLink,
  loadFFmpeg,
} from "../utils/videoHelper";
import { download } from "../utils/xmlRequest";
import { bbbFilename, cbtFilename } from "../utils/filename";

interface DownloadParams {
  isDownloadAvailable: boolean;
  initiateDownload: () => Promise<void>;
}

const useDownload = (): DownloadParams => {
  const dispatch = useDispatch();
  const selectedVideo = useSelector(selectedBBBVideo);
  const selectedCBTVideo = useSelector(selectedStoryVideo);
  const selectedCBTMeta = useSelector(selectedStoryMeta);

  const { startTime, endTime, code, bookOrSlug, cbtVideoType } = useSelector(
    (state: RootState) => state.params
  );

  const isDownloadAvailable =
    selectedVideo?.download !== null ||
    selectedCBTVideo?.download !== null ||
    false;

  const bbbTitle = useMemo(() => {
    if (
      code !== undefined &&
      bookOrSlug !== undefined &&
      selectedVideo !== undefined
    ) {
      return bbbFilename(code, bookOrSlug, selectedVideo);
    }

    return undefined;
  }, [bookOrSlug, code, selectedVideo]);

  const cbtTitle = useMemo(() => {
    if (
      code !== undefined &&
      cbtVideoType !== undefined &&
      selectedCBTMeta !== undefined
    ) {
      return cbtFilename(code, selectedCBTMeta, cbtVideoType);
    }

    return undefined;
  }, [cbtVideoType, code, selectedCBTMeta]);

  const filename = bbbTitle ?? cbtTitle ?? undefined;
  const url =
    selectedVideo?.download ?? selectedCBTVideo?.download ?? undefined;

  const initiateDownload = async () => {
    if (
      startTime !== undefined &&
      endTime !== undefined &&
      url !== undefined &&
      filename !== undefined
    ) {
      dispatch(setVideoDownloadState(VideoDownloadState.Init));
      await loadFFmpeg();

      dispatch(setVideoDownloadState(VideoDownloadState.Downloading));
      download(
        url,
        ({ percentComplete }) => {
          dispatch(setDownloadProgress(percentComplete));
        },
        (blob) => {
          dispatch(setVideoDownloadState(VideoDownloadState.Clipping));

          clipVideo(url, filename, blob, startTime, endTime).then(
            (urlObject) => {
              dispatch(setVideoDownloadState(VideoDownloadState.Init));
              downloadUrl(urlObject, `${filename}.mp4`);
            }
          );
        }
      );
    } else {
      window.location.href = formatDownloadLink(url, filename);
    }
  };

  return {
    isDownloadAvailable,
    initiateDownload,
  };
};

export default useDownload;
