import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";

import scaler from "../../utils/scaler";

import styles from "./styles.module.css";

interface CircularProps {
  countryCode?: string;
  diameter: number;
}

const formatCode = (text?: string) => {
  if (text !== undefined) {
    const code = text.toLowerCase();

    switch (code) {
      case "catalan":
        return "es-ct";
      case "ukl":
        return "ua";
      default:
        return code;
    }
  }

  return undefined;
};

const CircularFlag = ({
  countryCode,
  diameter,
}: CircularProps): JSX.Element => {
  const formattedCode = formatCode(countryCode);
  const scaleRatio = scaler(45, diameter);

  const flagScaled = {
    transform: `scale(${scaleRatio})`,
    borderRadius: `${diameter * 2}px`,
  };

  const containerStyle = {
    width: diameter,
    height: diameter,
  };

  return (
    <div key={countryCode} style={containerStyle} className={styles.container}>
      {countryCode !== undefined ? (
        <div
          style={flagScaled}
          className={`flag flag-${formattedCode} ${styles.flag}`}
        />
      ) : (
        <div style={containerStyle} className={styles.placeholder}>
          <FontAwesomeIcon
            style={containerStyle}
            className={styles.placeholderIcon}
            icon={faEarthAmericas}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(CircularFlag);
