import { CBTVideoMeta } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

export const getFirstCBTVideoSuffixUrl = (stories: CBTVideoMeta[]): string => {
  const { slug } = stories[0];
  const firstType = stories[0].videos.find(
    (video) => video.type !== "topic" && video.hls !== null
  )?.type;

  return `/${slug}/${firstType}`;
};
