import { Tooltip } from "react-tooltip";

import styles from "./Button.module.css";
import colors from "../../../styles/colors.module.css";

interface Props {
  isActive?: boolean;
  disabled?: boolean;
  emphasized?: boolean;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const Button = ({
  disabled = false,
  isActive = false,
  emphasized = false,
  label,
  icon,
  onClick,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    isActive && styles.containerActive
  } ${emphasized && styles.containerEmphasized} ${
    disabled && styles.containerDisabled
  }`;

  const handler = () => {
    if (!disabled) {
      onClick();
    }
  };

  const tooltipPadding = emphasized ? "13px 15px" : "9px 15px";

  const tooltipId = `tooltip-${label}-${emphasized}`;

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        role="button"
        aria-label={label}
        tabIndex={-1}
        onKeyDown={undefined}
        className={container}
        onClick={handler}
      >
        {icon}
      </div>
      <Tooltip
        id={tooltipId}
        className={styles.tooltip}
        style={{ padding: tooltipPadding, background: colors.gray4 }}
        place="left"
      >
        {label}
      </Tooltip>
    </>
  );
};

export default Button;
