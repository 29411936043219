import axios from "axios";

const getCountryCode = async (): Promise<string> => {
  const { data } = await axios.get<string>(
    "https://deafbible.com/utility/country-translation"
  );

  return data.toUpperCase();
};

export default getCountryCode;
