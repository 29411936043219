import { useDispatch, useSelector } from "react-redux";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import {
  Controls,
  Layout,
  VideoPlayer,
} from "@deaf-bible-society-2-0/deafbible-video-player";
import { useEffect, useRef } from "react";
import Overlay from "../Overlay";
import { RootState } from "../../store";
import {
  Mode,
  setMode,
  setSelectedSearchResult,
} from "../../store/textPanelContent";
import { selectedSearchResult } from "../../store/textPanelContent/selectors";
import styles from "./styles.module.css";

import fade from "../../styles/transitions/fade.module.css";
import useTimestampsForSearch from "../../hooks/useTimestampsForSearch";
import { getRange } from "../../utils/timestamp";

const SearchResultDisplay = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const searchResult = useSelector(selectedSearchResult);

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { mode } = useSelector((state: RootState) => state.textPanelContent);
  const searchResultType = useSelector(
    (state: RootState) => state.textPanelContent.searchResult?.type
  );

  const isPassageType = searchResultType === "passage";

  const { panelPosition } = useSelector(
    (state: RootState) => state.textPanelSettings
  );

  const { code, textVersion } = useSelector((state: RootState) => state.params);

  const show =
    searchResult !== undefined &&
    mode === "search" &&
    textVersion !== undefined;

  const closeSelf = () => {
    dispatch(setSelectedSearchResult(undefined));
  };

  const containerStyle = {
    left: panelPosition === "left" ? 0 : undefined,
    right: panelPosition === "right" ? 0 : undefined,
  };

  const src = searchResult?.videoSrcUrl;
  const url = `${searchResult?.baseUrl}${window.location.search}`;
  const thumbnail = searchResult?.thumbnailUrl;
  const title = searchResult?.reference;
  const text = searchResult?.text;
  const bookId = searchResult?.bookId;
  const bbbId = searchResult?.bbbId;

  const timestamps = useTimestampsForSearch({
    code,
    book: bookId,
    bbbId,
  });

  const range = getRange(
    timestamps,
    searchResult?.chapter,
    searchResult?.verse
  );

  const markers = isPassageType
    ? timestamps?.map((ts) => ({ label: `${ts.c}:${ts.v}`, time: ts.ts }))
    : undefined;

  const showVideo = timestamps !== undefined;

  const key = `${title}-${text}`;

  const controls =
    (timestamps === undefined && Controls.none) ||
    ((searchResultType === "passage" || timestamps?.length === 0) &&
      Controls.partialControlBar) ||
    Controls.nonInteractiveSeekBar;

  useEffect(() => {
    if (showVideo) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    }
  }, [showVideo]);

  const goto = () => {
    dispatch(setMode(Mode.browse));
    history.push(url);
  };

  const videoClass = `${styles.video} ${!showVideo && styles.hideVideo}`;

  const modalContainer = `${styles.modalContainer} ${
    darkMode && styles.modalContainerDarkMode
  }`;

  const closeButton = `${styles.closeButton} ${
    darkMode && styles.closeButtonDarkMode
  }`;

  return (
    <Overlay
      show={show}
      showTextPanel
      showHeader
      closeOnEsc
      closeOnExternalClick={false}
      closeSelf={closeSelf}
      darkerBackground
    >
      {(ref) => (
        <SwitchTransition mode="out-in">
          <CSSTransition key={key} timeout={300} classNames={fade}>
            <div style={containerStyle} className={styles.container} ref={ref}>
              <div className={modalContainer}>
                <div
                  role="button"
                  aria-label="Close"
                  onKeyDown={undefined}
                  tabIndex={0}
                  className={closeButton}
                  onClick={closeSelf}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>

                <div className={styles.videoContainer}>
                  <div className={videoClass}>
                    <VideoPlayer
                      src={src}
                      ref={videoRef}
                      poster={thumbnail}
                      layout={Layout.mobile}
                      transitionDelay={300}
                      range={range}
                      markers={markers}
                      autoplay
                      loop
                      controls={controls}
                    />
                  </div>
                </div>

                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{text}</div>
                <button onClick={goto} className={styles.button} type="button">
                  <FontAwesomeIcon className={styles.icon} icon={faCheck} />
                  Select
                </button>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )}
    </Overlay>
  );
};

export default SearchResultDisplay;
