import { ChapterVerse, Timestamp } from "../types/timestamp";
import { Timestamps } from "../types/timestamps";

export const filterTimestamps = (
  bbbId: string | undefined,
  ts: Timestamps[] | undefined
) => {
  return ts?.find((timestamp) => timestamp.id === bbbId)?.seg ?? [];
};

export const getRange = (
  ts: Timestamp[] | undefined,
  chapter: string | undefined,
  verse: string | undefined
) => {
  if (ts !== undefined && chapter !== undefined && verse !== undefined) {
    for (let i = 0; i < ts.length; i += 1) {
      if (chapter === ts[i].c.toString() && verse === ts[i].v.toString()) {
        return {
          start: ts[i].ts,
          end: ts[i + 1]?.ts ?? undefined,
        };
      }
    }
  }

  return undefined;
};

export const getChapterVerse = (
  timestamp: number | undefined | null,
  lut: Timestamp[]
): ChapterVerse | undefined => {
  if (timestamp !== undefined && timestamp !== null) {
    const index = lut.findIndex((i) => i.ts > timestamp);
    switch (index) {
      case -1:
        return lut[lut.length - 1];
      case 0:
        return lut[0];
      default:
        return lut[index - 1];
    }
  }

  return undefined;
};

const appendZero = (time: number): string =>
  time < 10 ? `0${time}` : time.toString();

export const hasHours = (time: number): boolean => time / 3600 >= 1;

export const convertTimestampToString = (
  time: number,
  withHours = true
): string => {
  const hours = Math.floor(time / 3600);
  const secondsRemaining = time % 3600;
  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;

  return `${withHours ? `${appendZero(hours)}:` : ""}${appendZero(
    minutes
  )}:${appendZero(seconds)}`;
};

const findPrevTimestamp = (
  currentTimestamp: number,
  timestamps: Timestamp[]
): Timestamp | undefined =>
  timestamps.find((timestamp) => currentTimestamp - timestamp.ts > 0.5);

export const seekPrevVerse = (
  currentTime: number,
  lut: Timestamp[] | undefined
): number | undefined => {
  let result;
  lut
    ?.slice()
    .reverse()
    .every(({ ts }, index, self) => {
      if (ts < currentTime) {
        // continue forward to find the next index different from the current ts
        const next = findPrevTimestamp(ts, self.slice(index));
        result = next?.ts;
      }

      return ts >= currentTime;
    });

  return result;
};

export const seekNextVerse = (
  currentTime: number,
  lut: Timestamp[] | undefined
): number | undefined => {
  let result;

  lut?.every(({ ts }) => {
    if (ts > currentTime) {
      result = ts;
    }

    return ts <= currentTime;
  });

  return result;
};

export const isOnAMarker = (
  currentPosition: number,
  positions: number[]
): boolean => {
  let found = false;
  positions.every((position) => {
    const diff = currentPosition - position;

    if (diff >= 0 && diff <= 6) {
      found = true;
    }

    return position <= currentPosition;
  });

  return found;
};

export const getTimestamp = (
  verse: string,
  chapter: string,
  lut: Timestamp[]
): number | undefined =>
  lut.find((i) => i.v.toString() === verse && i.c.toString() === chapter)?.ts;

export const convertTimestampToSeconds = (timestamp: string): number => {
  const [m, s, ms] = timestamp.split(":");

  return parseInt(m, 10) * 60 + parseInt(s, 10) + parseInt(ms, 10) / 60;
};
