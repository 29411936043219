import axios from "axios";

export interface Language {
  label: string;
  code: string;
  versions: Version[];
  homeCountries: string[];
}

interface RawLanguage {
  label: string;
  code: string;
  versions: Version[];
  home_countries: string[];
}

export interface Version {
  label: string;
  code: string;
  id: string;
}

const transformLanguage = (language: RawLanguage): Language => ({
  ...language,
  homeCountries: language.home_countries,
});

const getTextVersionsMetadata = async (): Promise<Language[]> => {
  const { data: versions } = await axios.get<RawLanguage[]>(
    `${process.env.REACT_APP_DEAFBIBLE_API}/text`
  );

  return versions.map(transformLanguage);
};

export default getTextVersionsMetadata;
