import React, { CSSProperties } from "react";

import styles from "./styles.module.css";

interface Props {
  children: React.ReactNode;
  wrap?: boolean;
}

const ButtonGroup = ({ children, wrap = false }: Props): JSX.Element => {
  const style = {
    flexFlow: wrap ? "wrap" : "nowrap",
  } as CSSProperties;

  return (
    <div style={style} className={styles.container}>
      {children}
    </div>
  );
};

export default ButtonGroup;
