import { useDispatch, useSelector } from "react-redux";

import SearchBox from "../../SearchBox";
import { RootState } from "../../../store";
import { setSearchInput } from "../../../store/textPanelContent";

import styles from "./styles.module.css";

interface Props {
  toggleHeaderMode: () => void;
  darkMode: boolean;
}

const SearchModeHeader = ({
  toggleHeaderMode,
  darkMode,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { searchInput } = useSelector(
    (state: RootState) => state.textPanelContent
  );

  const setValue = (text: string) => {
    dispatch(setSearchInput(text));
  };

  const cancelButton = `${styles.cancelButton} ${
    darkMode && styles.cancelButtonDarkMode
  }`;

  const cancel = () => {
    dispatch(setSearchInput(""));
    toggleHeaderMode();
  };

  return (
    <>
      <SearchBox setValue={setValue} value={searchInput} darkMode={darkMode} />
      <button className={cancelButton} type="button" onClick={cancel}>
        Cancel
      </button>
    </>
  );
};

export default SearchModeHeader;
