import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { setShowShareModal } from "../../../store/interface";

import useInternationalization from "../../../hooks/useInternationalization";

import Button from "./Button";

interface Props {
  darkMode: boolean;
  isMobile: boolean;
}

const ShareModalButton = ({ darkMode, isMobile }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { getIntlText } = useInternationalization();

  const shareHandler = async () => {
    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title: "Deaf Bible Web App",
          text: "Check out this inspiring Bible video!",
          url: window.location.href,
        });
      } catch (e) {
        if (e instanceof DOMException && e.name !== "AbortError")
          dispatch(setShowShareModal(true));
      }
    } else {
      dispatch(setShowShareModal(true));
    }
  };

  return (
    <Button
      darkMode={darkMode}
      isMobile={isMobile}
      onClick={shareHandler}
      text={getIntlText("share")}
      icon={<FontAwesomeIcon icon={faShareFromSquare} />}
    />
  );
};

export default ShareModalButton;
