import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  currentTeam,
  currentVideoSrc,
} from "../../store/translation/selectors";

import {
  videoPanelStyle,
  videoInnerContainerStyle,
  metaInnerContainerStyle,
  isMobileWidthVideoPanel,
} from "../../store/screen/panelSelectors";

import fade from "../../styles/transitions/fade.module.css";
import styles from "./styles.module.css";
import { RootState } from "../../store";
import ActionBar from "./ActionBar";
import MetadataBar from "./MetadataBar";
import CopyrightBar from "./CopyrightBar";
import Arrow from "./Arrow";
import MainVideoPlayer from "../MainVideoPlayer";
import LoadingIndicator from "../LoadingIndicator";
import { isMobile } from "../../store/screen/selectors";

const VideoPanel = (): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const { panelPosition } = useSelector(
    (state: RootState) => state.textPanelSettings
  );

  const innerContainerStyle = useSelector(videoInnerContainerStyle);
  const metaContainerStyle = useSelector(metaInnerContainerStyle);
  const outerContainerStyle = useSelector(videoPanelStyle);
  const videoSrc = useSelector(currentVideoSrc);
  const isMobileDevice = useSelector(isMobile);
  const isMobileVideoPanel = useSelector(isMobileWidthVideoPanel);

  const addPadding =
    !isMobileDevice && isMobileVideoPanel && panelPosition === "left";

  const isRealMobile = isMobileDevice;

  const innerContainer = `${styles.innerContainer} ${
    darkMode && styles.innerContainerDarkMode
  }`;

  const outerContainer = `${styles.outerContainer} ${
    addPadding && styles.outerContainerMobileWithTab
  } ${isRealMobile && styles.outerContainerMobile}`;

  const show = videoSrc !== undefined;

  const team = useSelector(currentTeam);
  const loadingIndicatorImg = team?.icon_url;

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div className={outerContainer} style={outerContainerStyle}>
        <div className={styles.mainContainer}>
          <div className={innerContainer} style={innerContainerStyle}>
            <div className={styles.arrowsContainer}>
              <Arrow direction="prev" />
              <Arrow direction="next" />
            </div>
            <LoadingIndicator
              imgUrl={loadingIndicatorImg}
              isMobile={isRealMobile}
            />
            <MainVideoPlayer />
          </div>
          <div className={innerContainer} style={metaContainerStyle}>
            <ActionBar />
            <MetadataBar />
            <CopyrightBar />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default VideoPanel;
