import { createSelector } from "@reduxjs/toolkit";
import { selectedBBBVideo } from "../translation/selectors";
import { RootState } from "../index";
import { filterTimestamps } from "../../utils/timestamp";
import { Timestamps } from "../../types/timestamps";

const rawTimestamps = (state: RootState): Timestamps[] | undefined =>
  state.timestamps.rawData;

export const currentTimestamps = createSelector(
  selectedBBBVideo,
  rawTimestamps,
  (selected, timestamps) => filterTimestamps(selected?.bbb_id, timestamps) ?? []
);

export const hasTimestamps = createSelector(
  currentTimestamps,
  (timestamps) => timestamps !== undefined && timestamps.length > 0
);
