import { useTextStyle } from "../../../hooks/useTextStyle";
import removeDuplicates from "../../../utils/array";
import adjustHeight from "../../../utils/thumbnail";
import Item from "./Item";

import styles from "./SearchResult.module.css";

const highlightText = (text: string, highlights: string[]): React.ReactNode => {
  const filteredHighlights = removeDuplicates(highlights);
  const regex = new RegExp(
    filteredHighlights.join("|").replace(/\\|\//g, "").replace(/\?/g, "\\?"),
    "gi"
  );

  const parts = text.split(regex);
  const matches = text.match(regex);

  return parts.map((part, index) => {
    const key = `${part}-${index}`;

    return (
      <span key={key}>
        {part}
        {matches !== null && matches[index] !== undefined && (
          <span className={styles.highlight}>{matches[index]}</span>
        )}
      </span>
    );
  });
};

interface Props {
  darkMode: boolean;
  thumbnailUrl?: string;
  id: string;
  text: string;
  isSelected: boolean;
  reference: string;
  highlightedText: string[];
  select: () => void;
}

const SearchResult = ({
  darkMode,
  id,
  thumbnailUrl,
  text,
  reference,
  isSelected,
  highlightedText,
  select,
}: Props) => {
  const textStyle = useTextStyle(
    { large: "1.6em", normal: "1.2em", small: "1em" },
    !isSelected
  );

  return (
    <Item
      id={id}
      darkMode={darkMode}
      select={select}
      bordered
      isSelected={isSelected}
    >
      {thumbnailUrl !== undefined && (
        <div className={styles.thumbnailContainer}>
          <img
            src={adjustHeight(thumbnailUrl, 80)}
            crossOrigin="anonymous"
            className={styles.thumbnail}
            alt=""
          />
        </div>
      )}
      <div style={textStyle} className={styles.textContainer}>
        <div className={styles.text}>
          {highlightText(text, highlightedText)}
        </div>
        <div className={styles.reference}>{reference}</div>
      </div>
    </Item>
  );
};

export default SearchResult;
