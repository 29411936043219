import { useQuery } from "react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { captureMessage } from "@sentry/browser";
import { RootState } from "../store";
import getTextVersion from "../utils/api/getTextVersion";
import {
  setTextPanelContent,
  setTextPanelError,
} from "../store/textPanelContent";
import { selectedVersionCode } from "../store/textPanelContent/selectors";

const useLoadTextVersion = (): void => {
  const dispatch = useDispatch();
  const { bookOrSlug, chapter } = useSelector(
    (state: RootState) => state.params
  );

  const versionCode = useSelector(selectedVersionCode);

  const params =
    versionCode !== undefined &&
    bookOrSlug !== undefined &&
    chapter !== undefined
      ? [versionCode, bookOrSlug, chapter]
      : [null, null, null];

  const { data, error } = useQuery(
    ["textVersion", ...params],
    () => getTextVersion(params[0], params[1], params[2]),
    {
      onError: (err) => {
        captureMessage(
          `Error fetching text content for ${versionCode} - ${bookOrSlug} ${chapter}: ${JSON.stringify(
            err
          )}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
    }
  );

  useEffect(() => {
    if (error !== null) {
      dispatch(
        setTextPanelError(
          "Unable to access text content. Please contact Deaf Bible Society about this issue."
        )
      );
    } else if (data !== undefined && data.length === 0) {
      dispatch(
        setTextPanelError("No text content is available for this book/chapter.")
      );
    } else {
      dispatch(setTextPanelError(undefined));
    }

    dispatch(setTextPanelContent(data));
  }, [error, dispatch, data]);
};

export default useLoadTextVersion;
