import { useRef } from "react";
import { useSelector } from "react-redux";
import Overlay from "../Overlay";
import Heading from "./Heading";

import styles from "./styles.module.css";
import { RootState } from "../../store";
import { isMobile } from "../../store/screen/selectors";
import useMaxHeight from "../../hooks/useMaxHeight";

interface Props {
  show: boolean;
  closeSelf: () => void;
  title?: string;
  ariaLabel: string;
  children: React.ReactNode;
  hideHeader?: boolean;
}

const Modal = ({
  show,
  closeSelf,
  title = "",
  ariaLabel,
  hideHeader = false,
  children,
}: Props): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const isMobileDevice = useSelector(isMobile);

  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const maxHeight = useMaxHeight(childrenContainerRef, 500);

  const containerClass = `${styles.container} ${
    !darkMode && styles.lightMode
  } ${isMobileDevice && styles.isMobile}`;
  const containerStyle = { maxHeight: isMobileDevice ? "100%" : maxHeight };

  return (
    <Overlay
      show={show}
      closeSelf={closeSelf}
      showHeader={false}
      alwaysHideHeaderIfMobile
    >
      {(ref) => (
        <div ref={ref} className={containerClass} style={containerStyle}>
          {!hideHeader && (
            <Heading
              closeSelf={closeSelf}
              title={title}
              ariaLabel={ariaLabel}
              darkMode={darkMode}
            />
          )}
          <div ref={childrenContainerRef} className={styles.childrenContainer}>
            {children}
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default Modal;
