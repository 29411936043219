import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Timestamp } from "../types/timestamp";
import {
  currentVideoSrc,
  translationType,
} from "../store/translation/selectors";
import { currentTimestamps } from "../store/timestamps/selectors";
import { VideoSrc } from "../utils/videoHelper";
import { RootState } from "../store";
import { LoadingState } from "../types/loadingState";
import { setTimestampLoadingState } from "../store/timestamps";
import { getChapterVerseParams } from "../utils/history";

export type MainVideoSource = {
  videoSrc?: VideoSrc;
  startTime?: number;
  timestamps?: Timestamp[];
};

const useLoadMainVideoSource = (): MainVideoSource => {
  const videoSrc = useSelector(currentVideoSrc);
  const type = useSelector(translationType);
  const timestamps = useSelector(currentTimestamps);
  const { loadingState } = useSelector((state: RootState) => state.timestamps);
  const [mainVideoSrc, setMainVideoSrc] = useState<MainVideoSource>({});

  useEffect(() => {
    if (
      type === "CBT" ||
      (loadingState === LoadingState.success && timestamps !== undefined)
    ) {
      const [urlChapter, urlVerse] = getChapterVerseParams();
      setTimestampLoadingState(LoadingState.init);

      const newSrc = {
        videoSrc,
        timestamps,
        startTime: timestamps?.find(
          ({ c, v }) => c.toString() === urlChapter && v.toString() === urlVerse
        )?.ts,
      };

      setMainVideoSrc(newSrc);
    }
  }, [loadingState, timestamps, type, videoSrc]);

  return mainVideoSrc;
};

export default useLoadMainVideoSource;
