import colors from "../styles/colors.module.css";

export type TextColor =
  | "black"
  | "white"
  | "yellow"
  | "lime"
  | "cyan"
  | "lightRed"
  | "cinnabar"
  | "green"
  | "navyBlue"
  | "purple";

export const darkColors = [
  "white",
  "yellow",
  "cyan",
  "lime",
  "lightRed",
] as TextColor[];
export const lightColors = [
  "black",
  "purple",
  "navyBlue",
  "green",
  "cinnabar",
] as TextColor[];

export const getFillColor = (textColor: TextColor): string => {
  switch (textColor) {
    case "yellow":
      return colors.textColorYellow;
    case "lime":
      return colors.textColorLime;
    case "cyan":
      return colors.textColorCyan;
    case "lightRed":
      return colors.textColorLightRed;
    case "cinnabar":
      return colors.cinnabar;
    case "purple":
      return colors.textColorPurple;
    case "green":
      return colors.textColorGreen;
    case "navyBlue":
      return colors.textColorNavyBlue;
    case "black":
      return colors.textLight;
    default:
      return colors.textDark;
  }
};

export const getInverseColor = (textColor: TextColor): TextColor => {
  const isDarkColor = darkColors.some((color) => color === textColor);

  const index = isDarkColor
    ? darkColors.findIndex((color) => color === textColor)
    : lightColors.findIndex((color) => color === textColor);

  return isDarkColor ? lightColors[index] : darkColors[index];
};
