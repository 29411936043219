import styles from "./ApprovalKey.module.css";
import ApprovalIcon from "./ApprovalIcon";
import colors from "../../../styles/colors.module.css";
import { VideoTooltip } from "../../VideoTooltip";

interface KeyProps {
  darkMode: boolean;
  iconType: "church" | "consultant";
  text: string;
}

const Key = ({ iconType, text, darkMode }: KeyProps): JSX.Element => {
  const color = darkMode ? colors.textDark : colors.textLight;

  return (
    <div className={styles.keyContainer}>
      <ApprovalIcon icon={iconType} color={color} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

interface Props {
  darkMode: boolean;
}

const ApprovalKey = ({ darkMode }: Props): JSX.Element => {
  const containerClass = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  return (
    <div className={containerClass}>
      <div className={styles.titleContainer}>
        <div>
          Icon
          <br /> Symbols
        </div>
        <VideoTooltip
          height={400}
          width={400}
          id="approvalTooltipSidebar"
          src="https://customer-00iafjcyy4sqllqg.cloudflarestream.com/b6dfddcd6483a1a86fb18a4d363b9e17/manifest/video.m3u8"
        />
      </div>
      <div className={styles.keysContainer}>
        <Key
          iconType="church"
          darkMode={darkMode}
          text="Church based quality check"
        />
        <Key
          iconType="consultant"
          darkMode={darkMode}
          text="Consultant based quality check"
        />
      </div>
    </div>
  );
};

export default ApprovalKey;
