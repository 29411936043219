import { faComments } from "@fortawesome/free-solid-svg-icons";

import useInternationalization from "../../hooks/useInternationalization";
import TextField, { Type } from "./TextField";

interface Props {
  comments: string;
  darkMode: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Comments = ({ comments, darkMode, onChange }: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  return (
    <TextField
      type={Type.multiLine}
      placeholder={getIntlText("comments")}
      value={comments}
      onChange={onChange}
      darkMode={darkMode}
      icon={faComments}
      required
    />
  );
};

export default Comments;
