import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import Overlay from "../Overlay";
import { RootState } from "../../store";

import {
  setClipProgress,
  setDownloadProgress,
  setVideoDownloadState,
  VideoDownloadState,
} from "../../store/interface";
import { cancel } from "../../utils/xmlRequest";
import { cancelFFmpegTask } from "../../utils/videoHelper";

import fade from "../../styles/transitions/fade.module.css";
import styles from "./styles.module.css";
import buttonStyles from "../VideoPanel/ActionBar/Button.module.css";

import ProgressBar from "./ProgressBar";

const DownloadProgressModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { videoDownloadState, downloadProgress, clipProgress } = useSelector(
    (state: RootState) => state.interface
  );

  const show = videoDownloadState !== VideoDownloadState.Init;

  const progress =
    (videoDownloadState === VideoDownloadState.Downloading &&
      downloadProgress) ||
    (videoDownloadState === VideoDownloadState.Clipping && clipProgress) ||
    0;

  const progressText =
    (videoDownloadState === VideoDownloadState.Downloading &&
      "Downloading...") ||
    (videoDownloadState === VideoDownloadState.Clipping &&
      "Clipping & transcoding video...") ||
    "";

  const closeSelf = () => {
    dispatch(setVideoDownloadState(VideoDownloadState.Init));
    dispatch(setDownloadProgress(0));
    dispatch(setClipProgress(0));
    cancel();
    cancelFFmpegTask();
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  return (
    <Overlay
      closeOnExternalClick={false}
      show={show}
      closeSelf={closeSelf}
      showHeader={false}
    >
      {(ref) => (
        <div className={container} ref={ref}>
          <SwitchTransition mode="out-in">
            <CSSTransition key={progressText} timeout={300} classNames={fade}>
              <div className={styles.progressText}>{progressText}</div>
            </CSSTransition>
          </SwitchTransition>

          <ProgressBar progress={progress} darkMode={darkMode} />

          <div
            role="button"
            tabIndex={0}
            style={{ marginLeft: 0, marginTop: 10 }}
            className={`${buttonStyles.container} ${
              darkMode && buttonStyles.containerDarkMode
            }`}
            onKeyPress={undefined}
            onClick={closeSelf}
          >
            <div className={buttonStyles.iconContainer}>
              <FontAwesomeIcon icon={faBan} />
            </div>
            <div className={buttonStyles.textContainer}>Cancel</div>
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default DownloadProgressModal;
