import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-thick.svg";

import styles from "./styles.module.css";
import { RootState } from "../../../store";
import { setShowVersionPickerModal } from "../../../store/interface";
import { selectedVersionLabel } from "../../../store/textPanelContent/selectors";

interface Props {
  width?: number | undefined;
  showBorder?: boolean;
}

const VersionPicker = React.forwardRef<HTMLDivElement, Props>(
  ({ width, showBorder = true }, ref): JSX.Element => {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((state: RootState) => state.settings);

    const { showVersionPickerModal } = useSelector(
      (state: RootState) => state.interface
    );

    const name = useSelector(selectedVersionLabel);

    const containerClass = `${styles.container} ${
      darkMode && styles.containerDarkMode
    } ${!showBorder && styles.containerNoBorder}`;

    const containerStyle = { width };

    const onClick = () => {
      dispatch(setShowVersionPickerModal(!showVersionPickerModal));
    };

    const arrowClass = `${styles.arrow} ${
      showVersionPickerModal && styles.arrowFlip
    } ${darkMode && styles.arrowDarkMode}`;
    const bookClass = `${styles.bookIcon} ${
      darkMode && styles.bookIconDarkMode
    }`;

    return (
      <div
        ref={ref}
        role="button"
        tabIndex={0}
        onKeyDown={undefined}
        onClick={onClick}
        style={containerStyle}
        className={containerClass}
      >
        <FontAwesomeIcon icon={faBook} className={bookClass} />
        <div className={styles.name}>{name}</div>
        <div className={styles.arrowContainer}>
          <Arrow className={arrowClass} />
        </div>
      </div>
    );
  }
);

export default VersionPicker;
