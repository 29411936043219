import { SwitchTransition, CSSTransition } from "react-transition-group";

import fade from "../../../../styles/transitions/fade.module.css";

import styles from "./styles.module.css";
import { useTextStyle } from "../../../../hooks/useTextStyle";

interface Props {
  darkMode: boolean;
  text: string;
  reference?: string;
}

const Title = ({ darkMode, text, reference }: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const titleStyle = useTextStyle(
    { large: "2.5em", normal: "1.5em", small: "1.3em" },
    true
  );

  const referenceStyle = useTextStyle(
    { large: "1.5em", normal: "1.1em", small: "1em" },
    true
  );

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={text} classNames={fade} timeout={300}>
        <div className={container}>
          <div style={titleStyle} className={styles.text}>
            {text}
          </div>
          <div style={referenceStyle} className={styles.reference}>
            {reference}
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Title;
