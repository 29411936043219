/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { AllInternationalization } from "../../utils/api/getInternationalization";

interface InternationalizationState {
  data: AllInternationalization;
}

const initialState: InternationalizationState = {
  data: {
    internationals: [],
    languages: [],
  },
};

const internationalizationSlice = createSlice({
  name: "internationalization",
  initialState,
  reducers: {
    setData: (state, action): void => {
      state.data = action.payload;
    },
  },
});

export const { setData } = internationalizationSlice.actions;

export default internationalizationSlice.reducer;
