import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faEnvelope,
  faLink,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
  faRedditAlien,
} from "@fortawesome/free-brands-svg-icons";
import Modal from "../Modal";
import LinkBall from "./LinkBall";

import styles from "./styles.module.css";
import { RootState } from "../../store";
import { setShowQRModal, setShowShareModal } from "../../store/interface";
import { isMobile } from "../../store/screen/selectors";
import colors from "../../styles/colors.module.css";

import useInternationalization from "../../hooks/useInternationalization";

const ShareModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLButtonElement>(null);
  const containterRef = useRef<HTMLDivElement>(null);
  const { showShareModal } = useSelector((state: RootState) => state.interface);

  const isMobileDevice = useSelector(isMobile);

  const { getIntlText } = useInternationalization();

  const copyTextHandler = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  const openQRModal = () => {
    dispatch(setShowShareModal(false));
    dispatch(setShowQRModal(true));
  };

  const closeSelf = () => {
    dispatch(setShowShareModal(false));
  };

  return (
    <Modal
      show={showShareModal}
      closeSelf={closeSelf}
      title={getIntlText("share")}
      ariaLabel="Close the Share modal"
    >
      <div ref={containterRef} className={styles.ballsContainer}>
        <LinkBall
          buttonRef={inputRef}
          tooltipOnClick={getIntlText("copied")}
          label={`${getIntlText("copy")} URL`}
          onClick={copyTextHandler}
          icon={faLink as IconProp}
          bgColor={colors.cinnabar}
        />
        <LinkBall
          label="QR Code"
          icon={faQrcode as IconProp}
          onClick={openQRModal}
          bgColor={colors.cinnabar}
        />
        <LinkBall
          label="Facebook"
          link={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}%2F&amp;src=sdkpreparse`}
          icon={faFacebookF as IconProp}
          bgColor={colors.facebookBlue}
        />
        <LinkBall
          label="Twitter"
          link={`https://twitter.com/intent/tweet?url=${window.location.href}`}
          icon={faTwitter as IconProp}
          bgColor={colors.twitterBlue}
        />
        <LinkBall
          label="Email"
          link={`mailto:?&subject=Deaf Bible Verse&body=${window.location.href}`}
          icon={faEnvelope as IconProp}
          bgColor={colors.emailGray}
        />
        <LinkBall
          label="WhatsApp"
          link={`${
            isMobileDevice ? "whatsapp://send?text=" : "https://wa.me/?text="
          }${window.location.href}`}
          icon={faWhatsapp as IconProp}
          bgColor={colors.whatsappGreen}
        />
        <LinkBall
          label="Reddit"
          link={`https://www.reddit.com/submit?url=${window.location.href}`}
          icon={faRedditAlien as IconProp}
          bgColor={colors.redditRed}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
