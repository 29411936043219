import { useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import styles from "./styles.module.css";
import fade from "../../../styles/transitions/fade.module.css";
import { RootState } from "../../../store";
import { setShowTextPanelSettingsModal } from "../../../store/interface";
import { textPanelHeaderStyle } from "../../../store/screen/panelSelectors";

import {
  Mode,
  setVersionPickerWidth,
  toggleMode,
} from "../../../store/textPanelContent";
import BrowseModeHeader from "./BrowseModeHeader";
import SearchModeHeader from "./SearchModeHeader";
import useResizeObserver from "../../../hooks/useResizeObserver";

const Header = (): JSX.Element => {
  const dispatch = useDispatch();

  const versionPickerRef = useRef<HTMLDivElement>(null);

  const { textVersion } = useSelector((state: RootState) => state.params);
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { mode } = useSelector((state: RootState) => state.textPanelContent);

  const resizeHandler = useCallback(
    ({ width }: { width: number }) => {
      dispatch(setVersionPickerWidth(width));
    },
    [dispatch]
  );

  useResizeObserver(
    textVersion !== undefined && mode === Mode.browse,
    versionPickerRef.current,
    resizeHandler
  );

  const style = useSelector(textPanelHeaderStyle);

  const { showTextPanelSettingsModal } = useSelector(
    (state: RootState) => state.interface
  );

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const toggleSettings = () => {
    dispatch(setShowTextPanelSettingsModal(!showTextPanelSettingsModal));
  };

  const toggleHeaderMode = () => {
    dispatch(toggleMode());
  };

  return (
    <div style={style} className={container}>
      <SwitchTransition mode="out-in">
        <CSSTransition key={mode} classNames={fade} timeout={300}>
          <div className={styles.innerContainer}>
            {mode === Mode.browse ? (
              <BrowseModeHeader
                toggleHeaderMode={toggleHeaderMode}
                toggleSettings={toggleSettings}
                versionPickerRef={versionPickerRef}
              />
            ) : (
              <SearchModeHeader
                darkMode={darkMode}
                toggleHeaderMode={toggleHeaderMode}
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Header;
