import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import { isNewTestament } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import {
  formattedBooks,
  lookupTable,
} from "../../../../store/translation/selectors";
import { RootState } from "../../../../store";

import fade from "../../../../styles/transitions/fade.module.css";
import { setExpandedItem } from "../../../../store/interface";
import { Title } from "../Title";
import { Content } from "../Content";
import { Chapter } from "../Content/Chapter";

interface Props {
  filterText: string;
  closeSidebar: () => void;
}

const Books = ({ filterText, closeSidebar }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedTestament } = useSelector(
    (state: RootState) => state.interface
  );
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { code, chapter, bookOrSlug } = useSelector(
    (state: RootState) => state.params
  );

  const { expandedItem: selectedBook } = useSelector(
    (state: RootState) => state.interface
  );

  const books = useSelector(formattedBooks);
  const lut = useSelector(lookupTable);

  const scrollToItem = useCallback(() => {
    const book = selectedBook;

    if ((selectedTestament === "new") === isNewTestament(book || "")) {
      const element = document.getElementById(book || "");
      element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else {
      document.getElementById("content-container")?.scrollTo({ top: 0 });
    }
  }, [selectedBook, selectedTestament]);

  useEffect(() => {
    if ((selectedTestament === "new") === isNewTestament(bookOrSlug || "")) {
      dispatch(setExpandedItem(bookOrSlug));
    }
  }, [selectedTestament, bookOrSlug, dispatch]);

  useEffect(() => {
    if ((selectedTestament === "new") === isNewTestament(bookOrSlug || "")) {
      scrollToItem();
    }
  }, [bookOrSlug, scrollToItem, selectedTestament]);

  const setSelectedBook = (newBook: string) => {
    dispatch(setExpandedItem(newBook));
  };

  const filteredBooks = books.filter(
    (book) =>
      book.name.toLowerCase().includes(filterText.toLowerCase()) &&
      book.isNewTestament === (selectedTestament === "new")
  );

  const loadChapter = useCallback(
    (newBook: string, newChapter: string): void => {
      if (lut === undefined) {
        return;
      }

      const newVerse = lut.firstVerseNumber(newBook, newChapter);

      history.push(
        `/${code}/${newBook}.${newChapter}.${newVerse}${window.location.search}`
      );
      scrollToItem();
      closeSidebar();
    },
    [closeSidebar, code, history, lut, scrollToItem]
  );

  const key = filteredBooks.reduce((acc, cur) => acc + cur.shortcode, "");

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        mountOnEnter
        unmountOnExit
        key={key}
        classNames={fade}
        addEndListener={scrollToItem}
        timeout={300}
      >
        <div>
          {filteredBooks.map(({ name, chapters, shortcode }) => {
            const selectBook = () => setSelectedBook(shortcode);
            const isSelected = selectedBook === shortcode;

            return (
              <div key={shortcode}>
                <Title
                  isSelected={isSelected}
                  onClick={selectBook}
                  text={name}
                />
                <Content darkMode={darkMode} show={isSelected}>
                  {chapters.map((c) => {
                    const selectChapter = () => loadChapter(shortcode, c);

                    return (
                      <Chapter
                        isSelected={bookOrSlug === shortcode && chapter === c}
                        darkMode={darkMode}
                        onClick={selectChapter}
                        key={`${shortcode}${c}`}
                        text={c}
                      />
                    );
                  })}
                </Content>
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Books;
