import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { isNewTestament } from "@deaf-bible-society-2-0/deafbible-metadata-utils";
import {
  setExpandedItem,
  setShowSelectorList,
  setShowTranslationsDropdown,
  setShowCountriesDropdown,
  setSelectedTestament,
} from "../store/interface";
import {
  setCBTParams,
  setSelectedChapterVerse,
  setSelectedBook,
  setCode,
  setIsEmbedded,
  setTextVersion,
} from "../store/params";

import { listTranslations } from "../store/translations/selectors";
import { RootState } from "../store";
import { lookupTable } from "../store/translation/selectors";
import {
  getSelectedTranslation,
  setLastURL,
  setPrevTextVersion,
  setSelectedTranslation,
} from "../utils/localStorage";
import useHomeCountryCode from "./useHomeCountryCode";
import { setSelectedCountry } from "../store/translations";
import { getFirstCBTVideoSuffixUrl } from "../utils/urlState";
import useCountries from "./useCountries";
import { getURLParams } from "../utils/history";

const blacklist = ["feedback", "embed"];

export default (): void => {
  const history = useHistory();
  const dispatch = useDispatch();

  const homeCountryCode = useHomeCountryCode();
  const { selectCountry } = useCountries();

  const { code, bookOrSlug, chapter, verse, cbtVideoType, embed, textVersion } =
    getURLParams();

  useEffect(() => {
    dispatch(setIsEmbedded(embed));
  }, [dispatch, embed]);

  useEffect(() => {
    if (textVersion !== undefined) {
      setPrevTextVersion(textVersion);
    }
    dispatch(setTextVersion(textVersion));
  }, [dispatch, textVersion]);

  const translationsByCountry = useSelector(
    (state: RootState) => state.translations.rawData
  );

  const lut = useSelector(lookupTable);
  const cbtVideos = useSelector(
    (state: RootState) => state.translation.rawData?.stories
  );

  const selectedTranslationType = useSelector(
    (state: RootState) => state.translation.rawData?.translation_type
  );

  const listTrans = useSelector(listTranslations);

  const getCountryByTranslation = useCallback(
    (translationCode: string | undefined): string | undefined =>
      translationsByCountry?.find((translation) =>
        translation.translations.some(
          (tran) => tran.translation_shortcode === translationCode
        )
      )?.countryCode,
    [translationsByCountry]
  );

  const isOnBlacklist = useCallback(
    (translationCode: string | undefined) =>
      blacklist.some((item) => translationCode?.includes(item)),
    []
  );

  /**
   *
   * URL structure: /<translationCode>/<ref>/[cbtVideoType]
   *
   * if BBB, <ref> format is <book>.<chapter>.<verse>. Example: GEN.1.1
   *
   * If CBT, <ref> is the slug of the story. Example: god-creates-everything
   *
   * In addition, [cbtVideoType] is the specific video of the story group that was selected.
   *
   * Full CBT URL example: /god-creates-everything/intro
   *
   */

  /**
   * Deals with the <translationCode> part of URL.
   *
   * 1. After fetching list of translations, check whether if translationCode in /<translationCode> exists.
   * 2. If it is empty or doesn't match any code from the list, check if selectedTranslaton exists in localStorage.
   * 3. If selectedTranslation exists in localStorage, use this and replace top of navigation stack with /<selectedTranslation>
   * 4. If it doesn't exist, check countryCode from geoip service
   * 5.
   *
   */
  useEffect(() => {
    if (isOnBlacklist(code)) {
      // ignore paths on the blacklist.
      return;
    }

    if (listTrans !== undefined && listTrans.length > 0) {
      if (
        listTrans.some(
          (translation) => translation.translation_shortcode === code
        )
      ) {
        dispatch(setCode(code));
        dispatch(setSelectedCountry(getCountryByTranslation(code)));
        setSelectedTranslation(code);
        return;
      }

      if (
        !listTrans.some(
          (translation) => translation.translation_shortcode === code
        )
      ) {
        // check local storage
        const selectedTranslation = getSelectedTranslation();
        if (selectedTranslation) {
          history.replace(`/${selectedTranslation}`);
          return;
        }

        if (homeCountryCode !== undefined) {
          const translations = translationsByCountry?.find(
            (data) => data.countryCode === homeCountryCode
          );

          if (translations !== undefined) {
            dispatch(setSelectedCountry(homeCountryCode));
            selectCountry(homeCountryCode);

            if (translations.translations.length > 1) {
              dispatch(setShowTranslationsDropdown(true));
              return;
            }

            if (translations.translations.length === 1) {
              history.replace(
                `/${translations.translations[0].translation_shortcode}`
              );
              return;
            }
          }

          dispatch(setShowCountriesDropdown(true));
        }
      }
    }
  }, [
    code,
    dispatch,
    getCountryByTranslation,
    history,
    selectCountry,
    homeCountryCode,
    isOnBlacklist,
    listTrans,
    translationsByCountry,
  ]);

  useEffect(() => {
    if (isOnBlacklist(code)) {
      // ignore paths on the blacklist.
      return;
    }
    if (code && selectedTranslationType === "CBT" && cbtVideos !== undefined) {
      const cbtVideoMeta = cbtVideos.find((story) => story.slug === bookOrSlug);

      if (
        cbtVideoMeta !== undefined &&
        cbtVideoMeta.videos.some(
          (video) => video.type === cbtVideoType && video.hls !== null
        )
      ) {
        dispatch(setExpandedItem(bookOrSlug));
        dispatch(setSelectedBook(bookOrSlug));
        dispatch(setCBTParams({ slug: bookOrSlug, type: cbtVideoType }));
        setLastURL(`/${code}/${bookOrSlug}/${cbtVideoType}`);
      } else {
        dispatch(setCode(code));
        dispatch(setShowSelectorList(true));
        history.replace(`/${code}${getFirstCBTVideoSuffixUrl(cbtVideos)}`);
      }
    }
  }, [
    bookOrSlug,
    cbtVideoType,
    cbtVideos,
    code,
    dispatch,
    history,
    isOnBlacklist,
    selectedTranslationType,
  ]);

  useEffect(() => {
    if (
      !isOnBlacklist(code) &&
      selectedTranslationType === "BBB" &&
      lut !== undefined
    ) {
      const [validatedBook, validatedChapter, validatedVerse] =
        lut.validateReference(bookOrSlug ?? "", chapter ?? "", verse ?? "");

      if (
        validatedBook !== bookOrSlug ||
        validatedChapter !== chapter ||
        validatedVerse !== verse
      ) {
        return history.replace(
          `/${code}/${validatedBook}.${validatedChapter}.${validatedVerse}`
        );
      }

      dispatch(setExpandedItem(validatedBook));
      dispatch(
        setSelectedTestament(isNewTestament(validatedBook) ? "new" : "old")
      );
      dispatch(setSelectedBook(validatedBook));
      dispatch(setSelectedChapterVerse({ chapter, verse }));
      setLastURL(
        `/${code}/${bookOrSlug}.${chapter}.${verse}${window.location.search}`
      );
    }
    return undefined;
  }, [
    bookOrSlug,
    chapter,
    code,
    dispatch,
    history,
    isOnBlacklist,
    lut,
    selectedTranslationType,
    verse,
  ]);
};
