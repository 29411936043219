import { useSelector } from "react-redux";
import useInternationalization from "./useInternationalization";
import { RootState } from "../store";
import { currentBook, selectedBBBVideo } from "../store/translation/selectors";
import { hasTimestamps } from "../store/timestamps/selectors";

const useVideoMetadata = () => {
  const { getIntlText } = useInternationalization();
  const { chapter, verse, cbtVideoType } = useSelector(
    (state: RootState) => state.params
  );

  const bbbVideo = useSelector(selectedBBBVideo);
  const timestamps = useSelector(hasTimestamps);
  const bookName = useSelector(currentBook);

  const text =
    (timestamps && `${bookName} ${chapter}:${verse}`) ||
    (bbbVideo !== undefined && (bbbVideo.title_native || bbbVideo.title)) ||
    (cbtVideoType !== undefined && getIntlText(cbtVideoType)) ||
    "nil";

  return text;
};

export default useVideoMetadata;
