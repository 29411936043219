import axios from "axios";

interface Internationalization {
  code: string;
  native: NativeWords;
}

export interface NativeWords {
  [key: string]: string;
}

export interface Language {
  code: string;
  englishName: string;
  name: string;
}

export interface AllInternationalization {
  internationals: Internationalization[];
  languages: Language[];
}

const getInternationalization = async (): Promise<AllInternationalization> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_NEW_API}/native-languages/all`
  );

  return data;
};

export default getInternationalization;
