import { configureStore, combineReducers } from "@reduxjs/toolkit";

import translation from "./translation";
import translations from "./translations";
import settings from "./settings";
import params from "./params";
import screen from "./screen";
import internationalization from "./internationalization";
import timestamps from "./timestamps";
import textPanelSettings from "./textPanelSettings";
import textPanelContent from "./textPanelContent";
import mainVideoPlayer from "./mainVideoPlayer";
import interfacee from "./interface";
import globalTextSettings from "./globalTextSettings";

const reducer = combineReducers({
  translation,
  translations,
  settings,
  params,
  screen,
  mainVideoPlayer,
  internationalization,
  timestamps,
  textPanelSettings,
  textPanelContent,
  globalTextSettings,
  interface: interfacee,
});

const store = configureStore({
  reducer,
  // disable for better performance in dev mode
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof reducer>;

export default store;
