import React, { CSSProperties } from "react";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-thick.svg";
import styles from "./styles.module.css";

interface Props {
  show: boolean;
  darkMode: boolean;
  disableAnimation?: boolean;
  arrowOrientation: "inwards" | "outwards";
  side: "left" | "right";
  onClick: () => void;
  offset: number;
  offsetAnimationDuration: string;
  icon?: React.ReactNode;
  showShadow?: boolean;
}

const SideTab = ({
  darkMode,
  onClick,
  offset,
  offsetAnimationDuration,
  disableAnimation = false,
  showShadow = false,
  show,
  arrowOrientation,
  icon,
  side,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${icon === undefined && styles.containerNoIcon}`;

  const isOnLeftSide = side === "left";
  const isFacingOutwards = arrowOrientation === "outwards";

  const containerStyle = {
    opacity: show ? 1 : 0,
    flexDirection: isOnLeftSide ? "row-reverse" : "row",
    pointerEvents: show ? "auto" : "none",
    transform: ` translateX(${isOnLeftSide ? offset : -offset}px)`,
    left: isOnLeftSide ? 0 : undefined,
    right: isOnLeftSide ? undefined : 0,
    borderRadius: isOnLeftSide ? "0 30px 30px 0" : "30px 0 0 30px",
    boxShadow: showShadow ? "0 0 5px black" : undefined,
    transition: `0.3s background, 0.3s color, 0.3s fill, 0.3s opacity${
      disableAnimation ? "" : ", 0.3s transform"
    }`,
  } as CSSProperties;

  const arrowContainerStyle = {
    justifyContent: !isOnLeftSide ? "flex-end" : "flex-start",
  };

  const arrowStyle = {
    transitionDelay: offsetAnimationDuration,
    transform: `rotate(${
      (isOnLeftSide && isFacingOutwards) || (!isOnLeftSide && !isFacingOutwards)
        ? 180
        : 0
    }deg)`,
  };

  return (
    <div
      style={containerStyle}
      className={container}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={undefined}
      role="button"
    >
      <div className={styles.arrowContainer} style={arrowContainerStyle}>
        <Arrow style={arrowStyle} className={styles.arrow} />
      </div>
      {icon !== undefined && <div className={styles.iconContainer}>{icon}</div>}
    </div>
  );
};

export default SideTab;
