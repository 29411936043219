import { useLocation } from "react-router-dom";

const useScreenKey = (): string => {
  const location = useLocation();

  const path = location.pathname.slice(1);

  return (
    (path === "feedback" && "feedback") || (path === "" && "splash") || "main"
  );
};

export default useScreenKey;
