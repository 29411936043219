import { CSSProperties, MouseEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles.module.css";
import { RootState } from "../../store";
import { panelSeparatorStyle } from "../../store/screen/panelSelectors";
import { setIsPanelSeparatorDragging } from "../../store/interface";

const PanelSeparator = (): JSX.Element => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const containerStyle = useSelector(panelSeparatorStyle) as CSSProperties;

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const handler = `${styles.handler} ${darkMode && styles.handlerDarkMode}`;

  const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = () => {
    dispatch(setIsPanelSeparatorDragging(true));
  };

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      role="button"
      tabIndex={0}
      onMouseDown={onMouseDownHandler}
      style={containerStyle}
      className={container}
    >
      <div className={handler} />
    </div>
  );
};

export default PanelSeparator;
