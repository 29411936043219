/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../types/loadingState";
import { Timestamps } from "../../types/timestamps";

interface TimestampsState {
  rawData?: Timestamps[];
  loadingState: LoadingState;
}

const initialState: TimestampsState = {
  loadingState: LoadingState.init,
};

const timestampsSlice = createSlice({
  name: "timestamps",
  initialState,
  reducers: {
    setTimestampLoadingState: (state, action): void => {
      state.loadingState = action.payload;
    },
    setTimestamps: (state, action): void => {
      state.rawData = action.payload;
    },
  },
});

export const { setTimestamps, setTimestampLoadingState } =
  timestampsSlice.actions;

export default timestampsSlice.reducer;
