import { useDispatch, useSelector } from "react-redux";

import Tabs from "./index";
import { RootState } from "../../../store";
import { setSelectedVNLanguage } from "../../../store/interface";

const options = ["VN", "EN"];
const labels = ["tiếng Việt", "English"];

const VNLanguagesTabs = (): JSX.Element => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { selectedVNLanguage } = useSelector(
    (state: RootState) => state.interface
  );

  const select = (language: string) => {
    dispatch(setSelectedVNLanguage(language));
  };

  return (
    <Tabs
      darkMode={darkMode}
      options={options}
      labels={labels}
      selectOption={select}
      selectedOption={selectedVNLanguage}
    />
  );
};

export default VNLanguagesTabs;
