import { useSelector } from "react-redux";
import { Tooltip, TooltipRefProps } from "react-tooltip";
import { MouseEventHandler, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useTextStyle } from "../../../hooks/useTextStyle";
import Item from "./Item";

import colors from "../../../styles/colors.module.css";
import styles from "./Verse.module.css";
import { RootState } from "../../../store";
import { currentBook } from "../../../store/translation/selectors";

interface Props {
  verseNumber: string;
  textSections: string[];
  darkMode: boolean;
  isSelected?: boolean;
  select: () => void;
  id: string;
}

const Verse = ({
  verseNumber,
  textSections,
  darkMode,
  isSelected = false,
  id,
  select,
}: Props) => {
  const { chapter, textVersion } = useSelector(
    (state: RootState) => state.params
  );

  const book = useSelector(currentBook);

  const [hovering, setHovering] = useState(false);
  const tooltipRef = useRef<TooltipRefProps>(null);

  const { textSize } = useSelector(
    (state: RootState) => state.globalTextSettings
  );
  const textStyle = useTextStyle(
    { large: "2.5em", normal: "1.2em", small: "1em" },
    !isSelected
  );

  const clipboardButton = `${styles.clipboardButton} ${
    darkMode && styles.clipboardButtonDarkMode
  } ${
    hovering && navigator.clipboard !== undefined && styles.clipboardButtonShow
  }`;

  const verseNumberStyle = {
    ...textStyle,
    width: textSize === "large" ? 60 : 40,
  };

  const copy: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (navigator.clipboard !== undefined) {
      tooltipRef.current?.open();

      const verseText = `${book} ${chapter}:${verseNumber} - ${textVersion}\n${textSections.join(
        "\n"
      )}\n\n${window.location.href}`;

      navigator.clipboard.writeText(verseText);
    }
  };

  return (
    <Item
      onPointerEnter={() => setHovering(true)}
      onPointerLeave={() => {
        setHovering(false);
        tooltipRef.current?.close();
      }}
      key={id}
      id={id}
      isSelected={isSelected}
      select={select}
      darkMode={darkMode}
    >
      <div style={verseNumberStyle}>{verseNumber}</div>
      <div style={textStyle} className={styles.verseContent}>
        {textSections.map((section, i) => (
          <div key={section + i.toString()}>{section}</div>
        ))}
      </div>

      <button
        className={clipboardButton}
        data-tooltip-id={verseNumber}
        onClick={copy}
        type="button"
        aria-label="Copy verse to clipboard"
      >
        <FontAwesomeIcon className={styles.clipboardButtonIcon} icon={faCopy} />
      </button>
      <Tooltip
        openOnClick
        id={verseNumber}
        ref={tooltipRef}
        afterShow={() => {
          tooltipRef.current?.close({ delay: 2000 });
        }}
        place="left"
        style={{ background: colors.gray4, zIndex: 1000000 }}
      >
        Copied to clipboard!
      </Tooltip>
    </Item>
  );
};

export default Verse;
