import styles from "./Language.module.css";

interface Props {
  code: string;
  label: string;
}

const Language = ({ code, label }: Props): JSX.Element => {
  return (
    <div key={code} className={styles.container}>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default Language;
