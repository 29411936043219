import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";

import Modal from "../Modal";
import { RootState } from "../../store";
import { setShowQRModal } from "../../store/interface";
import useVideoMetadata from "../../hooks/useVideoMetadata";

import styles from "./QRModal.module.css";

const QRModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { showQRModal: show } = useSelector(
    (state: RootState) => state.interface
  );

  const { code } = useSelector((state: RootState) => state.params);
  const text = useVideoMetadata();

  const closeSelf = () => {
    dispatch(setShowQRModal(false));
  };

  return (
    <Modal show={show} closeSelf={closeSelf} ariaLabel="QR Code">
      <div className={styles.qrCodeContainer}>
        <QRCodeSVG className={styles.qrCode} value={window.location.href} />
      </div>

      <div className={styles.label}>{`${code} | ${text}`}</div>
    </Modal>
  );
};

export default QRModal;
