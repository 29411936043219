import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import styles from "./styles.module.css";
import { useTextStyle } from "../../../hooks/useTextStyle";

export enum Type {
  oneLine = "oneline",
  multiLine = "multiLine",
}

interface Props {
  type?: Type;
  placeholder: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  darkMode: boolean;
  icon?: IconProp;
  required?: boolean;
}

const TextField = ({
  type = Type.oneLine,
  placeholder,
  value,
  onChange,
  darkMode,
  icon = undefined,
  required = false,
}: Props): JSX.Element => {
  const [inputInFocus, setInputInFocus] = useState<boolean>(false);

  const textStyle = useTextStyle({
    large: "1.5em",
    normal: "1em",
    small: "0.9em",
  });

  return (
    <div
      style={textStyle}
      className={`${styles.container} ${darkMode && styles.darkModeContainer} ${
        inputInFocus && styles.inputInFocus
      }`}
      onFocus={() => {
        setInputInFocus(true);
      }}
      onBlur={() => {
        setInputInFocus(false);
      }}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`${styles.icon} ${darkMode && styles.darkModeIcon}`}
        />
      )}
      {type === Type.multiLine ? (
        <textarea
          value={value}
          required={required}
          placeholder={placeholder}
          className={`${styles.textareaContainer}`}
          onChange={onChange}
        />
      ) : (
        <input
          type={type}
          value={value}
          required={required}
          placeholder={placeholder}
          className={styles.inputContainer}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default TextField;
