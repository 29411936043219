import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import getTimestamps from "../utils/api/getTimestamps";
import { filterTimestamps } from "../utils/timestamp";
import { Timestamp } from "../types/timestamp";

interface Props {
  code?: string;
  book?: string;
  bbbId?: string;
}

const useTimestampsForSearch = ({ code, book, bbbId }: Props) => {
  const [timestamps, setTimestamps] = useState<Timestamp[] | undefined>();

  const { data } = useQuery(
    ["timestampsForSearch", code, book],
    async () => {
      setTimestamps(undefined);
      try {
        if (code !== undefined && book !== undefined) {
          return await getTimestamps(code, book);
        }

        return [];
      } catch (e) {
        return [];
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    setTimestamps(filterTimestamps(bbbId, data ?? []));
  }, [bbbId, data]);

  return timestamps;
};

export default useTimestampsForSearch;
