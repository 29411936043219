import { CBTVideoType } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { getCBTIcon } from "../../../../utils/navigatorHelper";
import Item from "../Item";
import Title from "../Title";

import useInternationalization from "../../../../hooks/useInternationalization";
import styles from "./Story.module.css";

interface Props {
  darkMode: boolean;
  title: string;
  reference: string;
  videoTypes: CBTVideoType[];
  selectedVideoType: CBTVideoType | undefined;
  selectVideoType: (videoType: string) => void;
}

const Story = ({
  title,
  reference,
  darkMode,
  videoTypes,
  selectedVideoType,
  selectVideoType,
}: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  const videoTypesContainer = `${styles.videoTypesContainer} ${
    darkMode && styles.videoTypesContainerDarkMode
  }`;

  return (
    <div className={styles.container}>
      <Title darkMode={darkMode} text={title} reference={reference} />
      <div className={styles.innerContainer}>
        <div className={videoTypesContainer}>
          {videoTypes.map((videoType) => {
            const onClick = () => selectVideoType(videoType);

            return (
              <Item
                key={videoType}
                darkMode={darkMode}
                onClick={onClick}
                text={getIntlText(videoType)}
                isSelected={selectedVideoType === videoType}
                icon={getCBTIcon(videoType)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Story;
