/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Translation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";
import { Testament } from "../types/testament";

interface SelectedSecondaryVideo {
  translation: Translation;
  reference: string;
}

export type VNLanguage = "EN" | "VN";

export enum VideoDownloadState {
  Init,
  Downloading,
  Clipping,
}

interface InterfaceState {
  textLanguage: string;
  expandedItem: string;
  selectedTestament: Testament;
  selectedVNLanguage: VNLanguage;
  showSelectorList: boolean;
  showSidebar: boolean;
  showVersionPickerModal: boolean;
  showTextPanelSettingsModal: boolean;
  isPanelSeparatorDragging: boolean;
  showToast: boolean;
  messageToast: string;
  showCountriesDropdown: boolean;
  showTranslationsDropdown: boolean;
  showLanguageModal: boolean;
  showSettingsModal: boolean;
  showFeedbackModal: boolean;
  showQRModal: boolean;
  showShareModal: boolean;
  selectedSecondaryVideo: SelectedSecondaryVideo | undefined;
  showASLVBanner: boolean;
  videoDownloadState: VideoDownloadState;
  downloadProgress: number;
  clipProgress: number;
}

const initialState: InterfaceState = {
  expandedItem: "",
  selectedTestament: "old",
  selectedVNLanguage: "VN",
  textLanguage: "EN",
  showSelectorList: false,
  showSidebar: false,
  showToast: false,
  messageToast: "",
  showCountriesDropdown: false,
  showTranslationsDropdown: false,
  showLanguageModal: false,
  showSettingsModal: false,
  showFeedbackModal: false,
  showQRModal: false,
  showVersionPickerModal: false,
  showTextPanelSettingsModal: false,
  isPanelSeparatorDragging: false,
  showShareModal: false,
  selectedSecondaryVideo: undefined,
  showASLVBanner: true,
  videoDownloadState: VideoDownloadState.Init,
  downloadProgress: 0,
  clipProgress: 0,
};

const interfaceSlice = createSlice({
  name: "interface",
  initialState,
  reducers: {
    setShowSidebar: (state, action): void => {
      state.showSidebar = action.payload;
    },
    setShowSelectorList: (state, action): void => {
      state.showSelectorList = action.payload;
    },
    toggleShowSidebar: (state): void => {
      state.showSidebar = !state.showSidebar;
    },
    toggleShowSelectorList: (state): void => {
      state.showSelectorList = !state.showSelectorList;
    },
    setExpandedItem: (state, action): void => {
      state.expandedItem = action.payload;
    },
    setSelectedTestament: (state, action): void => {
      state.selectedTestament = action.payload;
    },
    setSelectedVNLanguage: (state, action): void => {
      state.selectedVNLanguage = action.payload;
    },
    setTextLanguage: (state, action): void => {
      state.textLanguage = action.payload;
    },
    setToastMessage: (state, action): void => {
      state.showToast = true;
      state.messageToast = action.payload;
    },
    hideToast: (state): void => {
      state.showToast = false;
    },
    setShowCountriesDropdown: (state, action): void => {
      state.showCountriesDropdown = action.payload;
    },
    setToggleCountriesDropdown: (state): void => {
      state.showCountriesDropdown = !state.showCountriesDropdown;
    },
    setShowTranslationsDropdown: (state, action): void => {
      state.showTranslationsDropdown = action.payload;
    },
    setShowLanguageModal: (state, action) => {
      state.showLanguageModal = action.payload;
    },
    setShowSettingsModal: (state, action) => {
      state.showSettingsModal = action.payload;
    },
    setShowFeedbackModal: (state, action) => {
      state.showFeedbackModal = action.payload;
    },
    setShowQRModal: (state, action) => {
      state.showQRModal = action.payload;
    },
    setShowVersionPickerModal: (state, action) => {
      state.showVersionPickerModal = action.payload;
    },
    setShowTextPanelSettingsModal: (state, action) => {
      state.showTextPanelSettingsModal = action.payload;
    },
    setIsPanelSeparatorDragging: (state, action) => {
      state.isPanelSeparatorDragging = action.payload;
    },
    setShowShareModal: (state, action) => {
      state.showShareModal = action.payload;
    },
    setShowASLVBanner: (state, action): void => {
      state.showASLVBanner = action.payload;
    },
    setClipProgress: (state, action) => {
      state.clipProgress = action.payload;
    },
    setDownloadProgress: (state, action) => {
      state.downloadProgress = action.payload;
    },
    setVideoDownloadState: (state, action) => {
      state.videoDownloadState = action.payload;
    },
  },
});

export const {
  setToastMessage,
  hideToast,
  setExpandedItem,
  setSelectedTestament,
  setSelectedVNLanguage,
  setTextLanguage,
  setShowQRModal,
  setShowSelectorList,
  setShowSettingsModal,
  setShowSidebar,
  setShowLanguageModal,
  setShowFeedbackModal,
  setShowShareModal,
  setToggleCountriesDropdown,
  toggleShowSelectorList,
  setShowCountriesDropdown,
  setShowTextPanelSettingsModal,
  setShowVersionPickerModal,
  setIsPanelSeparatorDragging,
  setShowTranslationsDropdown,
  setShowASLVBanner,
  setClipProgress,
  setDownloadProgress,
  setVideoDownloadState,
} = interfaceSlice.actions;

export default interfaceSlice.reducer;
