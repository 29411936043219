import { RefObject, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getChapterVerseParams } from "../utils/history";
import { RootState } from "../store";
import { MainVideoSource } from "./useLoadMainVideoSource";
import { Timestamp } from "../types/timestamp";

const useVideoTimeFixer = (
  on: boolean,
  mainVideoSrc: MainVideoSource,
  ref: RefObject<HTMLVideoElement>
) => {
  const srcStore = useRef<MainVideoSource>();
  const chapterStore = useRef<string>();
  const verseStore = useRef<string>();
  const { bookOrSlug, code } = useSelector((state: RootState) => state.params);

  const history = useHistory();

  const markerByTimestamp = useCallback(
    (currentTime: number, ts: Timestamp[]) =>
      ts
        .slice()
        .reverse()
        .find((marker) => marker.ts <= currentTime),
    []
  );

  const markerByReference = useCallback(
    (
      newChapter: string | undefined,
      newVerse: string | undefined,
      ts: Timestamp[]
    ) =>
      ts.find(
        (marker) =>
          marker.c.toString() === newChapter && marker.v.toString() === newVerse
      ),
    []
  );

  const srcAligner = useCallback(
    (src: MainVideoSource) => {
      if (ref.current !== null && src !== srcStore.current) {
        // console.log("srcAligner triggered", src);
        const element = ref.current;

        const [urlChapter, urlVerse] = getChapterVerseParams();
        srcStore.current = src;
        chapterStore.current = urlChapter;
        verseStore.current = urlVerse;

        if (src.startTime !== undefined) {
          element.currentTime = src.startTime;
        }

        return true;
      }

      return false;
    },
    [ref]
  );

  const urlChangeHandler = useCallback(
    (ts: Timestamp[]): boolean => {
      if (ref.current !== null) {
        const videoElement = ref.current;

        const [urlChapter, urlVerse] = getChapterVerseParams();

        if (
          chapterStore.current !== urlChapter ||
          verseStore.current !== urlVerse
        ) {
          const newMarker = markerByReference(urlChapter, urlVerse, ts);

          if (newMarker !== undefined) {
            chapterStore.current = urlChapter;
            verseStore.current = urlVerse;
            // console.log("urlChangeHandler triggered - marker found", {
            //   urlChapter,
            //   urlVerse,
            // });
            videoElement.currentTime = newMarker.ts;
            // } else {
            //   console.log("urlChangeHandler triggered - marker not found", {
            //     urlChapter,
            //     urlVerse,
            //   });
          }

          return true;
        }
      }

      return false;
    },
    [markerByReference, ref]
  );

  const timeChangeHandler = useCallback(
    (currentTime: number, ts: Timestamp[]) => {
      const currentMarker = markerByTimestamp(currentTime, ts);

      if (currentMarker !== undefined) {
        const markerChapter = currentMarker.c.toString();
        const markerVerse = currentMarker.v.toString();

        if (
          markerChapter !== chapterStore.current ||
          markerVerse !== verseStore.current
        ) {
          // console.log("timeChangeHandler triggered", currentMarker);
          chapterStore.current = markerChapter;
          verseStore.current = markerVerse;

          history.replace(
            `/${code}/${bookOrSlug}.${markerChapter}.${markerVerse}${window.location.search}`
          );
        }
      }
    },
    [bookOrSlug, code, history, markerByTimestamp]
  );

  const mainHandler = useCallback(() => {
    if (mainVideoSrc.timestamps !== undefined && ref.current !== null) {
      const isSrcChanged = srcAligner(mainVideoSrc);

      if (isSrcChanged) {
        return;
      }

      const isUrlChanged = urlChangeHandler(mainVideoSrc.timestamps);

      if (isUrlChanged) {
        return;
      }

      if (ref.current.readyState > ref.current.HAVE_CURRENT_DATA) {
        timeChangeHandler(ref.current.currentTime, mainVideoSrc.timestamps);
      }
    }
  }, [mainVideoSrc, ref, srcAligner, timeChangeHandler, urlChangeHandler]);

  useEffect(() => {
    if (on) {
      let rafHandler: number;

      const handler = () => {
        mainHandler();
        rafHandler = window.requestAnimationFrame(handler);
      };

      rafHandler = window.requestAnimationFrame(handler);

      return () => {
        cancelAnimationFrame(rafHandler);
      };
    }

    return undefined;
  }, [mainHandler, on]);
};

export default useVideoTimeFixer;
