import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { lookupTable } from "../translation/selectors";
import {
  convertPassageToItems,
  convertTopicToItems,
} from "../../utils/searchResult";

const textVersion = (state: RootState) => state.params.textVersion;
const metadata = (state: RootState) => state.textPanelContent.versionsMetadata;

const selectedVersion = createSelector(
  textVersion,
  metadata,
  (version, meta) => {
    if (version !== undefined && meta !== undefined) {
      for (let i = 0; i < meta.length; i += 1) {
        for (let j = 0; j < meta[i].versions.length; j += 1) {
          if (
            version.toLowerCase() === meta[i].versions[j].code.toLowerCase()
          ) {
            return meta[i].versions[j];
          }
        }
      }
    }

    return undefined;
  }
);

export const selectedVersionCode = createSelector(
  selectedVersion,
  (version) => version?.code
);

export const selectedVersionLabel = createSelector(
  selectedVersion,
  (version) => version?.label
);

export const searchResults = createSelector(
  (state: RootState) => state.textPanelContent.searchResult?.results,
  (state: RootState) => state.textPanelContent.searchResult?.type,
  (state: RootState) => state.params.code,
  lookupTable,
  (results, searchResultType, code, lut) => {
    if (
      results === undefined ||
      searchResultType === undefined ||
      code === undefined ||
      lut === undefined
    ) {
      return undefined;
    }

    const isPassageType = searchResultType === "passage";
    const convert = isPassageType ? convertPassageToItems : convertTopicToItems;

    return convert(results, code, lut);
  }
);

export const searchResultKey = createSelector(searchResults, (results) =>
  results?.map((result) => result.id).join("-")
);

export const selectedSearchResult = createSelector(
  (state: RootState) => state.textPanelContent.selectedSearchResult,
  searchResults,
  (selected, results) => {
    if (selected === undefined || results === undefined) {
      return undefined;
    }

    return results.find((item) => item.id === selected);
  }
);
