import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../../store";
import SearchResult from "./SearchResult";
import {
  RequestStatus,
  setSelectedSearchResult,
} from "../../../store/textPanelContent";
import LoadingIndicator from "../../LoadingIndicator";

import styles from "./SearchResultItems.module.css";
import { textPanelWidth } from "../../../store/screen/panelSelectors";
import { SearchListItem } from "../../../types/searchResult";

interface Props {
  items: SearchListItem[] | undefined;
  requestStatus: RequestStatus;
}

const SearchResultItems = ({ items, requestStatus }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const width = useSelector(textPanelWidth);

  const { searchInput, selectedSearchResult } = useSelector(
    (state: RootState) => state.textPanelContent
  );

  const showThumbnail = width > 520;

  const { darkMode } = useSelector((state: RootState) => state.settings);

  return (
    (requestStatus === RequestStatus.loading && <LoadingIndicator />) ||
    (requestStatus === RequestStatus.init && (
      <div className={styles.innerContainer}>
        <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlass} />
        <div className={styles.text}>Search for a topic or passage</div>
      </div>
    )) ||
    (requestStatus === RequestStatus.succeeded &&
      items !== undefined &&
      items.length > 0 && (
        <>
          {items.map((result) => {
            const select = () => {
              dispatch(setSelectedSearchResult(result.id));
            };

            return (
              <SearchResult
                id={result.id}
                key={result.id}
                darkMode={darkMode}
                text={result.text}
                thumbnailUrl={showThumbnail ? result.thumbnailUrl : undefined}
                reference={result.reference}
                highlightedText={searchInput.toLowerCase().split(" ")}
                select={select}
                isSelected={selectedSearchResult === result.id}
              />
            );
          })}
        </>
      )) || (
      <div className={styles.innerContainer}>
        <FontAwesomeIcon className={styles.icon} icon={faFileCircleXmark} />
        <div className={styles.text}>No results found</div>
        <div className={styles.secondaryText}>Please try again</div>
      </div>
    )
  );
};

export default SearchResultItems;
