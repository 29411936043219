import { useDispatch, useSelector } from "react-redux";
import SettingBox from "./index";
import RangeSlider from "../RangeSlider";
import { setTextSize } from "../../../store/globalTextSettings";
import { RootState } from "../../../store";
import { GlobalTextSize } from "../../../utils/localStorage";

const TextSize = (): JSX.Element => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { textSize } = useSelector(
    (state: RootState) => state.globalTextSettings
  );

  return (
    <SettingBox label="Text Size" darkMode={darkMode}>
      <RangeSlider
        options={Object.keys(GlobalTextSize)}
        selectedOption={textSize}
        selectOption={(option) => {
          dispatch(setTextSize(option));
        }}
        darkMode={darkMode}
      />
    </SettingBox>
  );
};

export default TextSize;
