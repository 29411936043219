import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setShowSelectorList } from "../store/interface";
import { getLastURL } from "../utils/localStorage";

const useURLInit = (): void => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const path = location.pathname;
  const lastURL = getLastURL();

  useEffect(() => {
    if (path === "/" && lastURL !== undefined) {
      history.replace(lastURL);
      dispatch(setShowSelectorList(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useURLInit;
