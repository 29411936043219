import { useDispatch, useSelector } from "react-redux";

import { faBible } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideTab from "./index";
import { isMobile } from "../../store/screen/selectors";
import { RootState } from "../../store";
import { setShowSelectorList } from "../../store/interface";

import styles from "./NavigatorTab.module.css";
import { sidebarWidth } from "../../store/screen/navigationSidebarSelectors";

interface Props {
  isOpen?: boolean;
  isPlaceholder?: boolean;
}

const NavigatorTab = ({
  isOpen = false,
  isPlaceholder = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isMobileDevice = useSelector(isMobile);
  const offset = useSelector(sidebarWidth);
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const show = !(isMobileDevice || (isPlaceholder && isOpen));

  const onClick = () => {
    dispatch(setShowSelectorList(!isOpen));
  };

  return (
    <SideTab
      show={show}
      darkMode={darkMode}
      arrowOrientation={isOpen ? "inwards" : "outwards"}
      side="right"
      showShadow={!isOpen}
      onClick={onClick}
      offset={isPlaceholder && isOpen ? offset : 0}
      offsetAnimationDuration="0.3s"
      icon={<FontAwesomeIcon icon={faBible} className={styles.icon} />}
    />
  );
};

export default NavigatorTab;
