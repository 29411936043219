import { useSelector } from "react-redux";

import { RootState } from "../../store";

import styles from "./ExternalLink.module.css";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  imgIcon: string;
  title: string;
  onClick: () => void;
  description: string;
}

const ExternalLink = ({
  imgIcon,
  title,
  onClick,
  description,
}: Props): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const externalLinkItem = `${styles.externalLinkItem} ${
    darkMode && styles.externalLinkItemDarkMode
  }`;

  const descriptionClassName = `${styles.description} ${
    darkMode && styles.descriptionDarkMode
  }`;

  const textStyle = useTextStyle(
    { large: "1.2em", normal: "1em", small: "0.9em" },
    true
  );

  return (
    <div
      style={textStyle}
      className={externalLinkItem}
      onClick={onClick}
      tabIndex={0}
      onKeyPress={undefined}
      role="link"
    >
      <div className={styles.iconContainer}>
        <img
          crossOrigin="anonymous"
          src={imgIcon}
          alt={title}
          className={styles.icon}
        />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div className={descriptionClassName}>{description}</div>
      </div>
    </div>
  );
};

export default ExternalLink;
