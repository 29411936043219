import Item from "../Item";
import Flag from "../../Flag";

import styles from "./Country.module.css";
import { useTextStyle } from "../../../hooks/useTextStyle";

interface Props {
  countryCode: string;
  countryName: string;
  darkMode: boolean;
  isSelected: boolean;
  handler: () => void;
}

const Country = ({
  countryCode,
  countryName,
  isSelected,
  darkMode,
  handler,
}: Props): JSX.Element => {
  const textStyle = useTextStyle(
    {
      large: "2.1em",
      normal: "1.5em",
      small: "1em",
    },
    !isSelected
  );

  return (
    <Item
      id={countryCode}
      handler={handler}
      isSelected={isSelected}
      darkMode={darkMode}
    >
      <div className={styles.container}>
        <div className={styles.flagContainer}>
          <Flag countryCode={countryCode} diameter={45} />
        </div>
        <div style={textStyle} className={styles.nameContainer}>
          {countryName}
        </div>
      </div>
    </Item>
  );
};

export default Country;
