import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadFFmpeg, setFFmpegProgressCallback } from "../utils/videoHelper";
import { setClipProgress } from "../store/interface";

const useInitFFmpeg = (): void => {
  const dispatch = useDispatch();
  const [isLibLoaded, setIsLibLoaded] = useState(false);

  useEffect(() => {
    if (isLibLoaded) {
      setFFmpegProgressCallback(({ ratio }: { ratio: number }) => {
        dispatch(setClipProgress(ratio));
      });
    } else if (crossOriginIsolated) {
      loadFFmpeg().then(() => {
        setIsLibLoaded(true);
      });
    } else {
      // throw new Error("Unable to load FFmpeg. CORS issues.");
      // console.log("FFmpeg - CORS issues!");
    }
  }, [dispatch, isLibLoaded]);
};

export default useInitFFmpeg;
