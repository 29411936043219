import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { RootState } from "../../store";

import styles from "./FeatureLink.module.css";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  icon?: IconDefinition;
  iconChild?: React.ReactNode;
  onClick: () => void;
  text: string;
}

const FeatureLink = ({
  icon,
  text,
  iconChild,
  onClick,
}: Props): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const featureLinkItem = `${styles.featureLinkItem} ${
    darkMode && styles.featureLinkItemDarkMode
  }`;

  const textStyle = useTextStyle(
    { large: "1.7em", normal: "1.3em", small: "1.1em" },
    true
  );

  return (
    <div
      style={textStyle}
      className={featureLinkItem}
      role="button"
      onClick={onClick}
      onKeyPress={undefined}
      tabIndex={0}
    >
      <div className={styles.featureLinkIconContainer}>
        {icon !== undefined && (
          <FontAwesomeIcon icon={icon} className={styles.featureLinkIcon} />
        )}
        {iconChild !== undefined && iconChild}
      </div>
      <div className={styles.featureLinkText}>{text}</div>
    </div>
  );
};

export default FeatureLink;
