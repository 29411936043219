import axios from "axios";
import { Timestamps } from "../../types/timestamps";

const getTimestamps = async (
  translationCode: string | undefined,
  bookCode: string | undefined
): Promise<Timestamps[]> => {
  if (translationCode !== undefined && bookCode !== undefined) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_NEW_API}/translations/${translationCode}/${bookCode}/timestamp`
    );

    return data;
  }
  return [];
};

export default getTimestamps;
