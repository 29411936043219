import Tab from "./Tab";
import styles from "./styles.module.css";

interface Props {
  darkMode: boolean;
  options: string[];
  labels: string[];
  selectedOption: string;
  selectOption: (option: string) => void;
}

const Tabs = ({
  darkMode,
  options,
  labels,
  selectedOption,
  selectOption,
}: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {options.map((option, index) => (
        <Tab
          key={option}
          darkMode={darkMode}
          isSelected={selectedOption === option}
          text={labels[index]}
          onClick={() => selectOption(option)}
        />
      ))}
    </div>
  );
};

export default Tabs;
