import { useSelector } from "react-redux";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import {
  selectedBBBVideo,
  selectedChapterVideos,
} from "../../../../store/translation/selectors";

import { RootState } from "../../../../store";
import Passage from "./Passage";
import fade from "../../../../styles/transitions/fade.module.css";
import useURLManipulator from "../../../../hooks/useURLManipulator";
import { isMobileWidthVideoPanel } from "../../../../store/screen/panelSelectors";

const BBB = (): JSX.Element => {
  const chapterVideos = useSelector(selectedChapterVideos);
  const selectedChapter =
    useSelector((state: RootState) => state.params.chapter) ?? "";

  const isMobileDevice = useSelector(isMobileWidthVideoPanel);

  const code = useSelector((state: RootState) => state.params.code);

  const { selectBBBVideo } = useURLManipulator();

  const selectedVideo = useSelector(selectedBBBVideo);
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const verticalLayout = code === "HNBBB" || isMobileDevice;

  return (
    <div>
      <SwitchTransition mode="out-in">
        <CSSTransition classNames={fade} timeout={300} key={selectedChapter}>
          {selectedVideo !== undefined ? (
            <Passage
              darkMode={darkMode}
              chapter={selectedChapter}
              videos={chapterVideos}
              selectedVideo={selectedVideo}
              selectVideo={selectBBBVideo}
              verticalLayout={verticalLayout}
              groupByTitles={code === "HNBBB"}
            />
          ) : (
            <div />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default BBB;
