import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { Language, NativeWords } from "../../utils/api/getInternationalization";

const languageCode = (state: RootState): string => state.interface.textLanguage;

const rawData = (state: RootState) =>
  state.internationalization?.data?.internationals;

export const internationalizationLUT = createSelector(
  rawData,
  languageCode,
  (data, code): NativeWords | undefined =>
    data?.find((intl) => intl.code === code)?.native
);

export const languages = (state: RootState): Language[] =>
  state.internationalization?.data?.languages;
