import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import { RootState } from "../../../../store";
import { useTextStyle } from "../../../../hooks/useTextStyle";

interface Props {
  text: string;
  onClick: () => void;
  isSelected: boolean;
}

export const Title = ({ text, isSelected, onClick }: Props) => {
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isSelected && styles.containerSelected}`;

  const textStyle = useTextStyle(
    {
      large: "2.1em",
      normal: "1.3em",
      small: "1.1em",
    },
    !isSelected
  );

  return (
    <button
      style={textStyle}
      tabIndex={0}
      type="button"
      onClick={onClick}
      className={container}
    >
      {text}
    </button>
  );
};
