import { useSelector, useDispatch } from "react-redux";

import NavigatorSidebar from "../index";
import { translationType } from "../../../store/translation/selectors";
import { setShowSelectorList } from "../../../store/interface";

import { RootState } from "../../../store";
import NavigatorTab from "../../SideTab/NavigatorTab";
import BBB from "./BBB";
import CBT from "./CBT";

import styles from "./styles.module.css";
import useInternationalization from "../../../hooks/useInternationalization";
import { keepNavigatorOpen } from "../../../store/screen/navigationSidebarSelectors";

const TranslationNavigator = (): JSX.Element => {
  const dispatch = useDispatch();
  const { getIntlText } = useInternationalization();

  const { showSelectorList, showTranslationsDropdown, showCountriesDropdown } =
    useSelector((state: RootState) => state.interface);

  const code = useSelector((state: RootState) => state.params.code);

  const keepOpen = useSelector(keepNavigatorOpen);

  const showNavigationSidebar = showSelectorList || keepOpen;

  const type = useSelector(translationType);

  const darkMode = useSelector((state: RootState) => state.settings.darkMode);

  const isBBB = type === "BBB";
  const isCBT = type === "CBT";

  const searchable =
    (isBBB && getIntlText("search_for_book")) ||
    (isCBT && getIntlText("search_for_story")) ||
    undefined;

  const hideList = () => {
    if (!showTranslationsDropdown && !showCountriesDropdown) {
      dispatch(setShowSelectorList(false));
    }
  };

  return (
    <NavigatorSidebar
      stretchBottom={code !== undefined}
      show={showNavigationSidebar}
      keepOpen={keepOpen}
      transitionType="sideSlide"
      hasTestamentTabs={isBBB}
      darkMode={darkMode}
      searchable={searchable}
      closeSelf={hideList}
      showVNTabs={code === "VNCBT"}
    >
      {(filterText) => {
        return (
          <div className={styles.container}>
            <div className={styles.tabContainer}>
              {!keepOpen && <NavigatorTab isOpen={showSelectorList} />}
            </div>

            {isBBB && <BBB filterText={filterText} closeSidebar={hideList} />}
            {isCBT && <CBT filterText={filterText} closeSidebar={hideList} />}
          </div>
        );
      }}
    </NavigatorSidebar>
  );
};

export default TranslationNavigator;
