import ApprovalIcon from "./ApprovalIcon";
import colors from "../../../styles/colors.module.css";
import styles from "./ApprovalStatus.module.css";

interface Props {
  darkMode: boolean;
  isSelected: boolean;
  hasChurchApproval: boolean;
  hasConsultantApproval: boolean;
}

const ApprovalStatus = ({
  darkMode,
  isSelected,
  hasConsultantApproval,
  hasChurchApproval,
}: Props): JSX.Element => {
  const containerClass = `${styles.container} ${
    darkMode && styles.darkModeContainer
  }`;

  const color = darkMode || isSelected ? colors.textDark : colors.textLight;

  return (
    <div className={containerClass}>
      {hasChurchApproval && (
        <div className={styles.iconContainer}>
          <ApprovalIcon icon="church" color={color} />
        </div>
      )}
      {hasConsultantApproval && (
        <div className={styles.iconContainer}>
          <ApprovalIcon icon="consultant" color={color} />
        </div>
      )}
    </div>
  );
};

export default ApprovalStatus;
