/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

import { TranslationsByCountry } from "../../utils/api/getTranslations";

interface TranslationsState {
  selectedCountry: string | undefined;
  rawData: TranslationsByCountry[] | undefined;
}

const initialState: TranslationsState = {
  selectedCountry: undefined,
  rawData: undefined,
};

const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    setSelectedCountry: (state, action): void => {
      state.selectedCountry = action.payload;
    },
    setTranslations: (state, action): void => {
      state.rawData = action.payload;
    },
  },
});

export const { setSelectedCountry, setTranslations } =
  translationsSlice.actions;

export default translationsSlice.reducer;
