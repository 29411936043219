import { createSelector } from "@reduxjs/toolkit";

import { headerHeight, isMobile, windowHeight, windowWidth } from "./selectors";
import breakpoints from "../../styles/breakpoints.module.css";
import { translationType } from "../translation/selectors";
import { RootState } from "..";
import { hasTextTranslation } from "../params/selectors";

const BPKeepNavigatorOpen = parseInt(
  breakpoints.keepNavigatorOpen.slice(0, -2),
  10
);

const textSize = (state: RootState) => state.globalTextSettings.textSize;

export const keepNavigatorOpen = createSelector(
  windowWidth,
  hasTextTranslation,
  translationType,
  (width, showPanel, type) =>
    (type !== "BBB" && BPKeepNavigatorOpen < width) ||
    (type === "BBB" && !showPanel && BPKeepNavigatorOpen < width)
);

export const sidebarWidth = createSelector(
  isMobile,
  windowWidth,
  textSize,
  (isMobileDevice, width, size) => {
    if (isMobileDevice) {
      return width;
    }

    switch (size) {
      case "small":
        return 400;
      case "large":
        return 550;
      default:
        return 438;
      // return 700;
    }
  }
);

export const sidebarHeight = createSelector(
  windowHeight,
  headerHeight,
  (windowH, headerH) => windowH - headerH
);
