import { useEffect, useState, useCallback, useRef } from "react";

const useRangeSlider = (
  rangeRef: HTMLDivElement | null,
  options: string[],
  selectedOption: string,
  selectOption: (option: string) => void
): number => {
  const [width, setWidth] = useState(rangeRef?.clientWidth || 0);
  const [isDragging, setIsDragging] = useState(false);

  const selectedOptionIndex =
    options.findIndex((option) => selectedOption === option) || 0;

  const step = (width - 18) / (options.length - 1);

  const rangeRefObserver = useRef(
    new ResizeObserver((entries) => {
      const { width: newWidth } = entries[0].contentRect;
      setWidth(newWidth);
    })
  );

  const findCurrentStep = useCallback(
    (positionX: number) => {
      if (rangeRef !== null) {
        const leftPosition = rangeRef.getBoundingClientRect().left;
        const offset = positionX - leftPosition - 9;

        const index = Math.min(Math.round(offset / step), options.length - 1);
        selectOption(options[index]);
      }
    },
    [options, rangeRef, selectOption, step]
  );

  useEffect(() => {
    if (rangeRef !== null) {
      rangeRefObserver.current.observe(rangeRef);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rangeRefObserver.current.unobserve(rangeRef);
      };
    }
    return undefined;
  }, [rangeRef, rangeRefObserver]);

  useEffect(() => {
    const handler = () => {
      setIsDragging(false);
    };

    window.addEventListener("mouseup", handler);

    return () => {
      window.removeEventListener("mouseup", handler);
    };
  }, []);

  useEffect(() => {
    if (rangeRef !== null) {
      const handler = (event: MouseEvent) => {
        setIsDragging(true);
        findCurrentStep(event.clientX);
      };

      rangeRef.addEventListener("mousedown", handler);

      return () => {
        rangeRef.removeEventListener("mousedown", handler);
      };
    }
    return undefined;
  }, [findCurrentStep, rangeRef]);

  useEffect(() => {
    if (isDragging && rangeRef !== null) {
      let rafHandler: number;

      const handler = (event: MouseEvent) => {
        if (rafHandler !== undefined) {
          window.cancelAnimationFrame(rafHandler);
        }

        rafHandler = window.requestAnimationFrame(() => {
          findCurrentStep(event.clientX);
        });
      };

      window.addEventListener("mousemove", handler);

      return () => {
        window.removeEventListener("mousemove", handler);
      };
    }
    return undefined;
  }, [findCurrentStep, isDragging, rangeRef]);

  return selectedOptionIndex * step;
};

export default useRangeSlider;
