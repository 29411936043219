import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";

import colors from "../styles/colors.module.css";
import { setTextColor } from "../store/globalTextSettings";
import { getGlobalTextSettings } from "../utils/localStorage";

export default (): void => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    if (getGlobalTextSettings() === undefined) {
      dispatch(setTextColor(darkMode ? "white" : "black"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (darkMode) {
      document.body.className = "darkMode";
      document.body.style.background = colors.mainSurfaceDark;
    } else {
      document.body.className = "";
      document.body.style.background = colors.secondarySurfaceLight;
    }
  }, [darkMode]);
};
