/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import { VideoSrc } from "../../utils/videoHelper";

interface MainVideoPlayerState {
  isVisible: boolean;
  videoSrc: VideoSrc | undefined;
  videoClipMode: boolean;
}

const initialState: MainVideoPlayerState = {
  videoClipMode: false,
  isVisible: false,
  videoSrc: undefined,
};

const mainVideoPlayerSlice = createSlice({
  name: "mainVideoPlayer",
  initialState,
  reducers: {
    setIsVisible: (state, action): void => {
      state.isVisible = action.payload;
    },
    setVideoSrc: (state, action): void => {
      state.videoSrc = action.payload;
    },
    setVideoClipMode: (state, action) => {
      state.videoClipMode = action.payload;
    },
  },
});

export const { setIsVisible, setVideoSrc, setVideoClipMode } =
  mainVideoPlayerSlice.actions;

export default mainVideoPlayerSlice.reducer;
