import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import SettingBox from "./index";
import ButtonGroup from "../ButtonGroup";
import Color from "../Button/ColorText";
import { setTextColor } from "../../../store/globalTextSettings";

import { lightColors, darkColors } from "../../../utils/colors";

const TextColorBox = (): JSX.Element => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector((state: RootState) => state.settings);

  const { textColor } = useSelector(
    (state: RootState) => state.globalTextSettings
  );

  const colors = darkMode ? darkColors : lightColors;

  return (
    <SettingBox label="Text Color" darkMode={darkMode}>
      <ButtonGroup wrap>
        {colors.map((color) => {
          const onClick = () => {
            dispatch(setTextColor(color));
          };
          return (
            <Color
              key={color}
              textColor={color}
              darkMode={darkMode}
              isSelected={textColor === color}
              onClick={onClick}
            />
          );
        })}
      </ButtonGroup>
    </SettingBox>
  );
};

export default TextColorBox;
