import { useEffect, useCallback, useRef } from "react";

import { useDispatch } from "react-redux";
import { setDimensions, setSeparatorPositionDiff } from "../store/screen";

const useUpdateDimensions = (): void => {
  const dispatch = useDispatch();
  const prevWidth = useRef<number>(window.innerWidth);

  const refresh = useCallback((): void => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    dispatch(setSeparatorPositionDiff(prevWidth.current - width));

    prevWidth.current = width;
    dispatch(setDimensions({ width, height }));
  }, [dispatch]);

  useEffect(() => {
    refresh();
    window.addEventListener("resize", refresh);
    return (): void => window.removeEventListener("resize", refresh);
  }, [refresh]);
};

export default useUpdateDimensions;
