import axios from "axios";
import { Translation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

const getTranslation = async (
  code: string | undefined
): Promise<Translation | undefined> => {
  try {
    if (code !== undefined) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_NEW_API}/translations/${code.toLowerCase()}`
      );

      return data;
    }
  } catch (err) {
    return undefined;
  }
  return undefined;
};

export default getTranslation;
