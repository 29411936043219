import {
  RefObject,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
} from "react";

interface OverlayModalOptions {
  closeOnEsc?: boolean | undefined;
  closeOnExternalClick?: boolean | undefined;
}

const defaultOptions = {
  closeOnEsc: true,
  closeOnExternalClick: true,
} as OverlayModalOptions;

const useOverlayModal = (
  show: boolean,
  closeSelf: () => void,
  options = defaultOptions
): RefObject<HTMLDivElement> => {
  const { closeOnEsc, closeOnExternalClick } = options;

  const ref = useRef<HTMLDivElement>(null);
  const deferredShow = useDeferredValue(show);

  const clickHandler = useCallback(
    (event: MouseEvent): void => {
      if (
        !ref.current?.contains(event.target as Node) &&
        closeOnExternalClick
      ) {
        closeSelf();
      }
    },
    [closeOnExternalClick, closeSelf]
  );

  const keypressHandler = useCallback(
    (event: KeyboardEvent): void => {
      if (event.code === "Escape" && closeOnEsc) {
        closeSelf();
      }
    },
    [closeOnEsc, closeSelf]
  );

  useEffect(() => {
    if (deferredShow) {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keypressHandler);
    }

    return () => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keypressHandler);
    };
  }, [clickHandler, keypressHandler, deferredShow]);

  return ref;
};

export default useOverlayModal;
