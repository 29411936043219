import ReactSwitch from "react-switch";

import colors from "../../../styles/colors.module.css";
import styles from "./Switch.module.css";
import { useTextStyle } from "../../../hooks/useTextStyle";

interface Props {
  label: string;
  setValue: (value: boolean) => void;
  value: boolean;
  isMobile: boolean;
  darkMode: boolean;
}

const Switch = ({
  label,
  setValue,
  value,
  isMobile,
  darkMode,
}: Props): JSX.Element => {
  const labelClassname = `${styles.label} ${darkMode && styles.labelDarkMode} ${
    isMobile && styles.labelMobile
  }`;

  const toggle = () => {
    setValue(!value);
  };

  const heightSwitch = isMobile ? 30 : 20;
  const widthSwitch = isMobile ? 60 : 40;
  const handleDiameter = isMobile ? 24 : 15;

  const textStyle = useTextStyle(
    { large: "1.5em", normal: "1em", small: "0.9em" },
    true
  );

  return (
    <div className={styles.container}>
      <ReactSwitch
        key={isMobile.toString()}
        checked={value}
        onChange={toggle}
        checkedIcon={false}
        uncheckedIcon={false}
        height={heightSwitch}
        width={widthSwitch}
        handleDiameter={handleDiameter}
        offColor={colors.gray1}
        onColor={colors.cinnabar}
      />

      <div style={textStyle} className={labelClassname}>
        {label}
      </div>
    </div>
  );
};

export default Switch;
