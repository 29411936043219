import { useRef } from "react";
import {
  Controls,
  VideoPlayer,
} from "@deaf-bible-society-2-0/deafbible-video-player";
import { Tooltip } from "react-tooltip";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTextStyle } from "../../hooks/useTextStyle";

import colors from "../../styles/colors.module.css";
import styles from "./styles.module.css";

interface Props {
  id: string;
  description?: string;
  width?: number;
  height?: number;
  src: string;
}

export const VideoTooltip = ({
  id,
  description,
  src,
  width = 200,
  height,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const tooltipTextStyle = useTextStyle(
    { large: "1.5em", normal: "1em", small: "0.9em" },
    false
  );

  return (
    <>
      <div data-tooltip-id={id} className={styles.container}>
        <FontAwesomeIcon icon={faQuestion} className={styles.icon} />
      </div>

      <Tooltip
        id={id}
        style={{ background: colors.gray4 }}
        className={styles.tooltip}
        afterHide={() => {
          if (videoRef.current !== null) {
            videoRef.current.currentTime = 0;
            videoRef.current.pause();
          }
        }}
        afterShow={() => {
          if (videoRef.current !== null) {
            videoRef.current.play();
          }
        }}
      >
        <div style={{ width, height }} className={styles.explanationVideo}>
          <VideoPlayer
            ref={videoRef}
            loop
            autoplay
            controls={Controls.nonInteractiveSeekBar}
            src={src}
          />
        </div>

        {description !== undefined && (
          <div style={tooltipTextStyle} className={styles.explanationText}>
            {description}
          </div>
        )}
      </Tooltip>
    </>
  );
};
