import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { Translation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { captureMessage } from "@sentry/browser";
import { RootState } from "../store";

import { setTranslation } from "../store/translation";
import getTranslation from "../utils/api/getTranslation";

import fixASLCBT from "../utils/temp";

const useLoadTranslation = (): void => {
  const dispatch = useDispatch();
  const { code } = useSelector((state: RootState) => state.params);

  const { data } = useQuery<Translation | undefined>(
    ["translation", code],
    () => getTranslation(code),
    {
      onError: (error) => {
        captureMessage(
          `Error fetching translation metadata for ${code}: ${JSON.stringify(
            error
          )}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    if (data !== undefined) {
      dispatch(setTranslation(code === "ASLCBT" ? fixASLCBT(data) : data));
    }
  }, [code, data, dispatch]);
};

export default useLoadTranslation;
