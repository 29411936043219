import { useRef } from "react";

import {
  Controls,
  VideoPlayer,
} from "@deaf-bible-society-2-0/deafbible-video-player";

import styles from "./Feedback.module.css";
import useResetOnShow from "../../hooks/videoPlayer/useResetOnShow";

const videoSrc = {
  hls: {
    url: "https://api.deafbiblesociety.com/gateway-video/6600d4e6ba25876a39813e2aa8345cc5/manifest/video.m3u8",
    type: "application/x-mpegURL",
  },
  dash: {
    url: "https://api.deafbiblesociety.com/gateway-video/6600d4e6ba25876a39813e2aa8345cc5/manifest/video.mpd",
    type: "application/dash+xml",
  },
};

interface Props {
  play: boolean;
}

const FeedbackVideo = ({ play }: Props): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useResetOnShow(videoRef, play);

  return (
    <div className={styles.videoContainer}>
      <VideoPlayer
        autoplay
        aspectRatio={1}
        ref={videoRef}
        controls={Controls.none}
        loop
        src={videoSrc.hls.url}
      />
    </div>
  );
};

export default FeedbackVideo;
