import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  currentCountry,
  translationsBySelectedCountry,
} from "../../../store/translations/selectors";
import { RootState } from "../../../store";
import { setSelectedCountry } from "../../../store/translations";
import {
  setExpandedItem,
  setShowTranslationsDropdown,
} from "../../../store/interface";
import styles from "./styles.module.css";
import NavigatorSidebar from "../index";
import { setCode } from "../../../store/params";
import { setTranslation } from "../../../store/translation";
import TranslationList from "./List";
import useInternationalization from "../../../hooks/useInternationalization";

const TranslationsNavigator = (): JSX.Element => {
  const translations = useSelector(translationsBySelectedCountry);
  const countryCode = useSelector(currentCountry);
  const darkMode = useSelector((state: RootState) => state.settings.darkMode);
  const { getIntlText } = useInternationalization();
  const history = useHistory();
  const dispatch = useDispatch();

  const show = useSelector(
    (state: RootState) => state.interface.showTranslationsDropdown
  );

  const selectedTranslationCode = useSelector(
    (state: RootState) => state.params.code
  );

  const closeSelf = (): void => {
    dispatch(setSelectedCountry(countryCode));
    dispatch(setShowTranslationsDropdown(false));
  };

  const selectTranslation = (translationCode: string) => {
    closeSelf();
    dispatch(setExpandedItem(""));
    dispatch(setCode(translationCode));
    dispatch(setTranslation(undefined));
    history.push(`/${translationCode}`);
  };

  const versionsContainer = `${styles.versionsContainer} ${
    darkMode && styles.versionsContainerDarkMode
  }`;

  return (
    <NavigatorSidebar
      stretchBottom={false}
      show={show}
      transitionType="dropdown"
      darkMode={darkMode}
      closeSelf={closeSelf}
    >
      {() => {
        return (
          <div className={styles.container}>
            <div className={versionsContainer}>{getIntlText("versions")}</div>
            <TranslationList
              translations={translations}
              selectTranslation={selectTranslation}
              selectedTranslationCode={selectedTranslationCode}
              darkMode={darkMode}
            />
          </div>
        );
      }}
    </NavigatorSidebar>
  );
};

export default TranslationsNavigator;
