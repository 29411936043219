import { useTextStyle } from "../../../hooks/useTextStyle";
import styles from "./NIVCopyright.module.css";

export const NIVCopyright = () => {
  const textStyle = useTextStyle(
    { large: "1.5em", normal: "1em", small: "0.8em" },
    true
  );

  return (
    <div style={textStyle} className={styles.nivCopyright}>
      The Holy Bible, New International Version® NIV® Copyright © 1973, 1978,
      1984, 2011 by Biblica, Inc.® Used by Permission of Biblica, Inc.® All
      rights reserved worldwide.
      <a
        className={styles.nivCopyrightLink}
        href="https://www.biblica.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn More
      </a>
    </div>
  );
};
