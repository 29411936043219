import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import NavigatorSidebar from "../index";
import { RootState } from "../../../store";

import CountryList from "./List";
import useCountries from "../../../hooks/useCountries";
import { setShowCountriesDropdown } from "../../../store/interface";
import contains from "../../../utils/strings";
import fade from "../../../styles/transitions/fade.module.css";
import useInternationalization from "../../../hooks/useInternationalization";

const CountriesNavigator = (): JSX.Element => {
  const { countries, selectCountry } = useCountries();
  const { getIntlText } = useInternationalization();
  const dispatch = useDispatch();
  const selectedCountry = useSelector(
    (state: RootState) => state.translations.selectedCountry
  );

  const show = useSelector(
    (state: RootState) => state.interface.showCountriesDropdown
  );

  const darkMode = useSelector((state: RootState) => state.settings.darkMode);

  const closeSelf = useCallback((): void => {
    dispatch(setShowCountriesDropdown(false));
  }, [dispatch]);

  return (
    <NavigatorSidebar
      show={show}
      darkMode={darkMode}
      closeSelf={closeSelf}
      stretchBottom
      searchable={getIntlText("search_for_country")}
    >
      {(filterText) => {
        const list = countries.filter((country) =>
          contains(country.name, filterText)
        );
        const keyList = list.reduce((acc, cur) => acc.concat(cur.id), "");

        return (
          <SwitchTransition mode="out-in">
            <CSSTransition key={keyList} classNames={fade} timeout={300}>
              <CountryList
                countries={list}
                closeSelf={closeSelf}
                selectCountry={selectCountry}
                selectedCountry={selectedCountry}
                darkMode={darkMode}
              />
            </CSSTransition>
          </SwitchTransition>
        );
      }}
    </NavigatorSidebar>
  );
};

export default CountriesNavigator;
