import {
  faBible,
  faCommentDots,
  faDoorOpen,
  faNoteSticky,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { CBTVideoType } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

export const chapterMargin = 2.5;

export const getSubItemSize = (
  containerWidth: number,
  containerPadding: number,
  maxCountInRow: number
): number =>
  Math.floor((containerWidth - 2 * containerPadding) / maxCountInRow);

export const getHeightOfContent = (
  isBBB: boolean,
  numItems: number,
  maxNumItemsInRow: number,
  padding: number,
  itemSize: number
): number =>
  !isBBB && numItems === 1
    ? 0
    : Math.ceil(numItems / maxNumItemsInRow) * itemSize + padding * 2;

export const getCBTIcon = (videoType: CBTVideoType | undefined): IconProp => {
  switch (videoType) {
    case "intro":
      return faDoorOpen;
    case "story":
    case "intro-passage":
      return faBible;
    case "lesson":
    case "intro-passage-moreinfo":
      return faPersonChalkboard;
    case "commentary":
      return faCommentDots;
    default:
      return faNoteSticky;
  }
};
