import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import axios, { AxiosError } from "axios";

import { captureMessage } from "@sentry/browser";
import { RootState } from "../store";

import {
  RequestStatus,
  setSearchRequestStatus,
  setSearchResult,
  setTextPanelError,
} from "../store/textPanelContent";
import { SearchResult } from "../types/searchResult";

const useLoadSearchResults = () => {
  const dispatch = useDispatch();

  const { textVersion } = useSelector((state: RootState) => state.params);

  const { searchInput } = useSelector(
    (state: RootState) => state.textPanelContent
  );

  const initSearchResult = useMemo(
    () => ({
      query: "",
      version: textVersion,
      type: "topic",
      results: [],
    }),
    [textVersion]
  );

  useEffect(() => {
    let handler: NodeJS.Timeout;
    let controller: AbortController;

    if (searchInput.length < 3) {
      dispatch(setSearchRequestStatus(RequestStatus.init));
      dispatch(setTextPanelError(undefined));
      dispatch(setSearchResult(initSearchResult));
    } else if (textVersion !== undefined) {
      dispatch(setTextPanelError(undefined));

      const url = new URL(
        `${process.env.REACT_APP_DEAFBIBLE_API}/text/${textVersion}/search`
      );

      url.searchParams.set("query", searchInput);

      handler = setTimeout(async () => {
        try {
          dispatch(setSearchRequestStatus(RequestStatus.loading));

          controller = new AbortController();

          const response = await axios.get<SearchResult>(url.toString(), {
            signal: controller.signal,
          });

          dispatch(setSearchRequestStatus(RequestStatus.succeeded));
          dispatch(setSearchResult(response.data));
        } catch (e) {
          const error = e as AxiosError;
          if (error.code !== "ERR_CANCELED") {
            dispatch(setTextPanelError(`Error: ${error.message}`));
          } else {
            captureMessage(
              `Error fetching search results for ${textVersion} - ${searchInput}: ${JSON.stringify(
                error
              )}`
            );
          }
        }
      }, 500);
    }

    return () => {
      controller?.abort("cancelled due to new search input");
      clearTimeout(handler);
    };
  }, [dispatch, initSearchResult, searchInput, textVersion]);
};

export default useLoadSearchResults;
