import React from "react";

import styles from "./styles.module.css";

interface Props {
  handler: () => void;
  isSelected: boolean;
  darkMode: boolean;
  children: React.ReactNode;
  id: string;
}

const Item = ({
  id,
  darkMode,
  handler,
  isSelected,
  children,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isSelected && styles.containerSelected}`;

  return (
    <div
      key={id}
      role="button"
      onKeyPress={undefined}
      tabIndex={0}
      onClick={handler}
      className={container}
    >
      {children}
    </div>
  );
};

export default Item;
