import { useSelector } from "react-redux";
import { RootState } from "../../../store";

import { textPanelContentHeight } from "../../../store/screen/panelSelectors";
import List from "./List";
import BrowseItems from "./BrowseItems";
import SearchResultItems from "./SearchResultItems";
import {
  searchResultKey,
  searchResults,
} from "../../../store/textPanelContent/selectors";
import { Mode } from "../../../store/textPanelContent";

const Content = (): JSX.Element => {
  const {
    error,
    mode,
    searchRequestStatus,
    rawData: verses,
  } = useSelector((state: RootState) => state.textPanelContent);

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { bookOrSlug, verse, chapter, textVersion } = useSelector(
    (state: RootState) => state.params
  );

  const { lineHeight } = useSelector(
    (state: RootState) => state.textPanelSettings
  );

  const height = useSelector(textPanelContentHeight);
  const results = useSelector(searchResults);
  const searchKey = useSelector(searchResultKey);

  const key =
    mode === Mode.browse
      ? `${error}-${textVersion}-${bookOrSlug}-${chapter}`
      : `${error}-${searchRequestStatus}-${searchKey}`;

  const contentStyle = {
    lineHeight:
      (lineHeight === "short" && 1.1) || (lineHeight === "normal" && 1.5) || 2,
  };

  return (
    <List
      darkMode={darkMode}
      selected={verse?.toString()}
      height={height}
      transitionKey={key}
      errorMessage={error}
    >
      <div style={contentStyle}>
        {mode === "browse" ? (
          <BrowseItems verses={verses ?? []} />
        ) : (
          <SearchResultItems
            requestStatus={searchRequestStatus}
            items={results}
          />
        )}
      </div>
    </List>
  );
};

export default Content;
