import {
  BBBVideo,
  getTitle,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import styles from "./Passage.module.css";
import Title from "../Title";
import { sortByTitles } from "../../../../utils/videoHelper";
import VerseRanges from "./VerseRanges";
import { useTextStyle } from "../../../../hooks/useTextStyle";

interface Props {
  darkMode: boolean;
  chapter: string;
  videos: BBBVideo[];
  selectedVideo: BBBVideo;
  selectVideo: (video: BBBVideo) => void;
  verticalLayout?: boolean;
  groupByTitles?: boolean;
}

const Passage = ({
  chapter,
  videos,
  darkMode,
  selectVideo,
  selectedVideo,
  verticalLayout = false,
  groupByTitles = false,
}: Props): JSX.Element => {
  const bookName = selectedVideo.book;
  const title = getTitle(selectedVideo);

  const innerContainer = `${styles.innerContainer} ${
    verticalLayout && styles.innerContainerVertical
  }`;

  const bookChapterContainer = `${styles.bookChapterContainer} ${
    darkMode && styles.bookChapterContainerDarkMode
  } ${verticalLayout && styles.bookChapterContainerVertical}`;

  const verseRangesContainer = `${styles.verseRangesContainer} ${
    darkMode && styles.verseRangesContainerDarkMode
  } ${verticalLayout && styles.verseRangesContainerVertical}`;

  const bookChapterText = `${styles.bookChapterText} ${
    verticalLayout && styles.bookChapterTextVertical
  }`;

  const bookChapterStyle = useTextStyle(
    { large: "1.5em", normal: "1.2em", small: "1em" },
    true
  );

  return (
    <div className={styles.container}>
      {!groupByTitles && <Title darkMode={darkMode} text={title} />}
      <div className={innerContainer}>
        <div style={bookChapterStyle} className={bookChapterContainer}>
          <div className={bookChapterText}>{`${bookName} ${chapter}`}</div>
        </div>
        <div className={verseRangesContainer}>
          {groupByTitles ? (
            sortByTitles(videos).map((group) => {
              return (
                <div key={group.title} className={styles.group}>
                  {group.title && (
                    <div className={styles.subTitle}>{group.title}</div>
                  )}
                  <VerseRanges
                    darkMode={darkMode}
                    videos={group.videos}
                    selectedVideo={selectedVideo}
                    selectVideo={selectVideo}
                    chapter={chapter}
                  />
                </div>
              );
            })
          ) : (
            <div className={styles.group}>
              <VerseRanges
                darkMode={darkMode}
                videos={videos}
                selectedVideo={selectedVideo}
                selectVideo={selectVideo}
                chapter={chapter}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Passage;
