import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { RootState } from "../../store";

import styles from "./styles.module.css";
import fade from "../../styles/transitions/fade.module.css";

const Toast = (): JSX.Element => {
  const { showToast: show, messageToast: message } = useSelector(
    (state: RootState) => state.interface
  );

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames={fade}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
        <div className={styles.message}>{message}</div>
      </div>
    </CSSTransition>
  );
};

export default Toast;
