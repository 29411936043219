import React from "react";

import styles from "./Button.module.css";

interface Props {
  darkMode: boolean;
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  isMobile?: boolean;
}

const Button = ({
  darkMode,
  onClick,
  text,
  icon,
  isMobile = false,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isMobile && styles.containerMobile}`;

  return (
    <div
      className={container}
      tabIndex={0}
      onClick={onClick}
      role="button"
      onKeyPress={undefined}
    >
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.textContainer}>{text}</div>
    </div>
  );
};

export default Button;
