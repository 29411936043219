/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  GlobalTextSettingsState,
  GlobalTextSize,
  getGlobalTextSettings,
  setGlobalTextSettings,
} from "../utils/localStorage";
import { setDarkMode } from "./settings";
import { getInverseColor } from "../utils/colors";

const defaultValues: GlobalTextSettingsState = {
  textSize: GlobalTextSize.normal,
  textColor: "white",
};

const initialState = getGlobalTextSettings() ?? defaultValues;

const globalTextSettingsSlice = createSlice({
  name: "globalTextSettings",
  initialState,
  reducers: {
    setTextSize: (state, action) => {
      state.textSize = action.payload;
      setGlobalTextSettings(state);
    },
    setTextColor: (state, action) => {
      state.textColor = action.payload;
      setGlobalTextSettings(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setDarkMode, (state) => {
      state.textColor = getInverseColor(state.textColor);
      setGlobalTextSettings(state);
    });
  },
});

export const { setTextSize, setTextColor } = globalTextSettingsSlice.actions;

export default globalTextSettingsSlice.reducer;
