import styles from "./Step.module.css";

interface Props {
  darkMode: boolean;
}

const Step = ({ darkMode }: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  return <div className={container} />;
};

export default Step;
