import { round } from "lodash";
import styles from "./ProgressBar.module.css";

interface Props {
  darkMode: boolean;
  progress: number;
}

const ProgressBar = ({ progress, darkMode }: Props): JSX.Element => {
  const percent = round(progress * 100, 2);

  const progressStyle = {
    width: `${percent}%`,
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  return (
    <div className={container}>
      <div className={styles.text}>{`${percent}%`}</div>
      <div style={progressStyle} className={styles.progress} />
    </div>
  );
};

export default ProgressBar;
