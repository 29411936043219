import { useDispatch } from "react-redux";
import screenfull from "screenfull";
import { useEffect } from "react";
import { isIOS, isAndroid } from "react-device-detect";

import { Orientation, setOrientation } from "../store/screen";

const useScreenOrientation = () => {
  const dispatch = useDispatch();

  const isMobile = isIOS || isAndroid;

  useEffect(() => {
    if (
      isMobile &&
      window.screen.orientation !== undefined &&
      window.screen.orientation.addEventListener !== undefined
    ) {
      const screenOrientationHandler = async function handler(
        this: ScreenOrientation
      ) {
        const isLandscape =
          this.type === "landscape-primary" ||
          this.type === "landscape-secondary";

        const containerElement = document.getElementById(
          "mainVideoPlayerContainer"
        );

        if (containerElement !== null && screenfull.isEnabled) {
          if (isLandscape && screenfull.isEnabled) {
            await screenfull.request(containerElement);
          } else {
            await screenfull.exit();
          }
        }

        dispatch(
          setOrientation(
            isLandscape ? Orientation.landscape : Orientation.portrait
          )
        );
      };

      window.screen.orientation.addEventListener(
        "change",
        screenOrientationHandler
      );

      return () => {
        window.screen.orientation.removeEventListener(
          "change",
          screenOrientationHandler
        );
      };
    }

    return undefined;
  }, [dispatch, isMobile]);
};

export default useScreenOrientation;
