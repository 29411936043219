import { useDispatch } from "react-redux";

import styles from "./styles.module.css";
import { setTextLanguage } from "../../store/interface";

import { setSelectedLanguage } from "../../utils/localStorage";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  code: string;
  name: string;
  englishName: string;
  darkMode: boolean;
  selectedLanguage: string;
  closeSelf: () => void;
}

const LanguageButton = ({
  code,
  name,
  englishName,
  darkMode,
  selectedLanguage,
  closeSelf,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const isSelected = code === selectedLanguage;
  const languageContainer = `${styles.languageContainer} ${
    darkMode && styles.languageContainerDarkMode
  } ${isSelected && styles.languageContainerSelected} `;

  const onClick = () => {
    closeSelf();
    setSelectedLanguage(code);
    dispatch(setTextLanguage(code));
  };

  const textStyle = useTextStyle(
    { large: "1.4em", normal: "1em", small: "0.9em" },
    !isSelected
  );

  return (
    <div
      role="button"
      key={code}
      tabIndex={0}
      style={textStyle}
      className={languageContainer}
      onKeyDown={undefined}
      onClick={onClick}
    >
      <div>{name}</div>
      <div className={styles.englishName}>{englishName}</div>
    </div>
  );
};

export default LanguageButton;
