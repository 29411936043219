interface Progress {
  percentComplete: number;
}

type ProgressCallback = (progress: Progress) => void;
type BlobReadyCallback = (videoBlob: Blob) => void;

let request: XMLHttpRequest | undefined;

const formatProgress = (e: ProgressEvent, _startTime: number): Progress => {
  const percentComplete = e.loaded / e.total;

  // if bytes per second is needed
  // const duration = (new Date().getTime() - startTime) / 1000;
  // const bps = e.loaded / duration;

  return {
    percentComplete,
  };
};

const createRequest = (url: string): XMLHttpRequest => {
  const newRequest = new XMLHttpRequest();

  newRequest.responseType = "blob";
  newRequest.open("get", url, true);

  return newRequest;
};

export const download = (
  url: string,
  progressCallback: ProgressCallback,
  blobReadyCallback: BlobReadyCallback
): void => {
  const newRequest = createRequest(url);
  request = newRequest;
  newRequest.send();

  const startTime = new Date().getTime();

  newRequest.onprogress = (e: ProgressEvent) =>
    progressCallback(formatProgress(e, startTime));

  newRequest.onloadend = () => {
    request = undefined;
  };

  newRequest.onload = () => {
    const { status, response } = newRequest;

    if (status === 200) {
      const blob = new Blob([response], { type: "video/mp4" });
      blobReadyCallback(blob);
    } else {
      // TODO: deal with download errors
    }
  };
};

export const cancel = (): void => {
  request?.abort();
  request = undefined;
};
