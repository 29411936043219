import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import SettingBox from "./index";
import ButtonGroup from "../ButtonGroup";
import { setLineHeight } from "../../../store/textPanelSettings";

import { ReactComponent as ShortIcon } from "../../../assets/icons/line-height-sm.svg";
import { ReactComponent as NormalIcon } from "../../../assets/icons/line-height-md.svg";
import { ReactComponent as TallIcon } from "../../../assets/icons/line-height-lg.svg";
import Button from "../Button/Square";
import { LineHeight } from "../../../utils/localStorage";

const heights = ["short", "normal", "tall"];

const getLineHeightIcon = (lineHeight: LineHeight) => {
  switch (lineHeight) {
    case "short":
      return ShortIcon;
    case "tall":
      return TallIcon;
    default:
      return NormalIcon;
  }
};

const LineHeightBox = (): JSX.Element => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { lineHeight } = useSelector(
    (state: RootState) => state.textPanelSettings
  );

  return (
    <SettingBox width={215} label="Line Height" darkMode={darkMode}>
      <ButtonGroup>
        {heights.map((height) => {
          const onClick = () => {
            dispatch(setLineHeight(height));
          };
          const Icon = getLineHeightIcon(height as LineHeight);

          return (
            <Button
              key={height}
              darkMode={darkMode}
              isSelected={lineHeight === height}
              onClick={onClick}
            >
              <Icon
                style={{
                  boxSizing: "border-box",
                  padding: "0 10px",
                  width: "100%",
                }}
              />
            </Button>
          );
        })}
      </ButtonGroup>
    </SettingBox>
  );
};

export default LineHeightBox;
