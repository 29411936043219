import {
  BBBVideo,
  getVerseRange,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import Item from "../Item";
import styles from "./VerseRanges.module.css";

interface Props {
  darkMode: boolean;
  videos: BBBVideo[];
  selectedVideo: BBBVideo;
  selectVideo: (video: BBBVideo) => void;
  chapter: string;
}

const VerseRanges = ({
  darkMode,
  videos,
  selectedVideo,
  selectVideo,
  chapter,
}: Props): JSX.Element => {
  const selectedVerseRange = getVerseRange(selectedVideo, chapter);

  return (
    <div className={styles.container}>
      {videos.map((video) => {
        const verseRange = getVerseRange(video, chapter);
        const onClick = () => selectVideo(video);
        return (
          <Item
            key={verseRange}
            isSelected={verseRange === selectedVerseRange}
            darkMode={darkMode}
            onClick={onClick}
            text={verseRange}
          />
        );
      })}
    </div>
  );
};

export default VerseRanges;
