/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import { CBTVideoType } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

interface ParamsState {
  code?: string;
  bookOrSlug?: string;
  chapter?: string;
  verse?: string;
  cbtVideoType?: CBTVideoType;
  startTime?: number;
  endTime?: number;
  isEmbedded: boolean;
  textVersion?: string | undefined;
}

const initialState: ParamsState = {
  isEmbedded: false,
};

const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setCode: (state, action): void => {
      state.code = action.payload;
      state.bookOrSlug = undefined;
      state.cbtVideoType = undefined;
      state.chapter = undefined;
      state.verse = undefined;
    },
    setCBTParams: (state, action): void => {
      const { slug, type } = action.payload;
      state.bookOrSlug = slug;
      state.cbtVideoType = type;
    },
    setSelectedBook: (state, action): void => {
      state.bookOrSlug = action.payload;
    },
    setSelectedChapterVerse: (state, action): void => {
      const { chapter, verse } = action.payload;
      state.chapter = chapter;
      state.verse = verse;
    },
    setTimeRange: (state, action): void => {
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
    },
    setStartTime: (state, action): void => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action): void => {
      state.endTime = action.payload;
    },
    setIsEmbedded: (state, action): void => {
      state.isEmbedded = action.payload;
    },
    setTextVersion: (state, action): void => {
      state.textVersion = action.payload;
    },
  },
});

export const {
  setCode,
  setSelectedBook,
  setSelectedChapterVerse,
  setCBTParams,
  setTimeRange,
  setStartTime,
  setEndTime,
  setIsEmbedded,
  setTextVersion,
} = paramsSlice.actions;

export default paramsSlice.reducer;
