import { useCallback } from "react";
import { useSelector } from "react-redux";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { RootState } from "../../../store";
import useInternationalization from "../../../hooks/useInternationalization";
import { isMobileWidthVideoPanel } from "../../../store/screen/panelSelectors";
import {
  currentTeam,
  qualityChecks,
} from "../../../store/translation/selectors";

import fade from "../../../styles/transitions/fade.module.css";
import styles from "./styles.module.css";
import { useTextStyle } from "../../../hooks/useTextStyle";

const qualityCheckText = (checks: string[]): string =>
  qualityChecks.length === 2 ? `${checks[0]} & ${checks[1]}` : checks[0];

const CopyrightBar = (): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { getIntlText } = useInternationalization();

  const isMobile = useSelector(isMobileWidthVideoPanel);

  const currentQualityChecks = useSelector(qualityChecks);

  const team = useSelector(currentTeam);

  const key = team?.id ?? "no-team";

  const imgUrl =
    darkMode && team?.logo_darkmode_url
      ? team?.logo_darkmode_url
      : team?.logo_url;

  const openTeamWebsite = useCallback((): void => {
    if (team !== undefined) {
      window.open(team.website_url);
    }
  }, [team]);

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isMobile && styles.containerMobile}`;

  const logoContainer = `${styles.logoContainer} ${
    darkMode && styles.logoContainerDarkMode
  }`;

  const textStyle = useTextStyle(
    { large: "1.5em", normal: "1em", small: "0.9em" },
    true
  );

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={key} timeout={300} classNames={fade}>
        <div style={textStyle}>
          <div className={container}>
            <div
              aria-label="Visit Website"
              className={logoContainer}
              role="button"
              onClick={openTeamWebsite}
              onKeyDown={undefined}
              tabIndex={0}
            >
              <img
                src={imgUrl}
                alt=""
                className={styles.logo}
                crossOrigin="anonymous"
              />
            </div>
            {team?.copyright && (
              <div className={styles.copyrightText}>{`${getIntlText(
                "translated_by"
              )}: ${team?.copyright}`}</div>
            )}
            {currentQualityChecks !== undefined &&
              currentQualityChecks.length > 0 && (
                <div className={styles.qualityChecks}>
                  {` | Quality-checked by ${qualityCheckText(
                    currentQualityChecks
                  )}`}
                  {/* <VideoTooltip
                    height={400}
                    width={400}
                    id="approvalTooltipBar"
                    src="https://customer-00iafjcyy4sqllqg.cloudflarestream.com/b6dfddcd6483a1a86fb18a4d363b9e17/manifest/video.m3u8"
                  /> */}
                </div>
              )}
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default CopyrightBar;
