import React, { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "../../store/screen/selectors";
import useInternationalization from "../../hooks/useInternationalization";
import styles from "./styles.module.css";
import Overlay from "../Overlay";
import TranslationBox from "../TranslationBox";
import SearchBox from "../SearchBox";
import TestamentTabs from "./Tabs/Testaments";
import VNLanguagesTabs from "./Tabs/VNLanguages";
import {
  sidebarHeight,
  sidebarWidth,
} from "../../store/screen/navigationSidebarSelectors";

interface Props {
  show: boolean;
  darkMode: boolean;
  searchable?: string | undefined;
  hasTestamentTabs?: boolean;
  transitionType?: "dropdown" | "sideSlide";
  stretchBottom?: boolean;
  keepOpen?: boolean;
  showVNTabs?: boolean;
  closeSelf: () => void;
  children: (filterText: string) => React.ReactNode;
}

const NavigatorSidebar = ({
  show,
  darkMode,
  searchable,
  closeSelf,
  hasTestamentTabs = false,
  keepOpen = false,
  transitionType = "dropdown",
  stretchBottom = false,
  children,
  showVNTabs = false,
}: Props): JSX.Element => {
  const [filterText, setFilterText] = useState("");

  const width = useSelector(sidebarWidth);
  const height = useSelector(sidebarHeight);
  const isMobileDevice = useSelector(isMobile);

  const { getIntlText } = useInternationalization();

  const contentHeight =
    height -
    (searchable ? 77 : 0) -
    (showVNTabs ? 77 : 0) -
    (hasTestamentTabs ? 77 : 0) -
    (isMobileDevice ? 70 : 0);

  const outerContainerStyle = {
    pointerEvents: keepOpen ? "auto" : undefined,
    width,
  } as CSSProperties;

  const innerContainerStyle = {
    transform:
      transitionType === "dropdown"
        ? `scaleY(${show ? 1 : 0.25})`
        : `translateX(${show ? 0 : width}px)`,
  };

  const innerContainer = `${styles.innerContainer} ${
    darkMode && styles.innerContainerDarkMode
  }`;

  const contentContainer = `${styles.contentContainer} ${
    darkMode && styles.contentContainerDarkMode
  }`;

  const contentContainerStyle = {
    maxHeight: !stretchBottom ? contentHeight : undefined,
    height: stretchBottom ? contentHeight : undefined,
    borderRadius: `0 0 ${stretchBottom ? "0 0" : "12px 12px"}`,
  };

  return (
    <Overlay show={show} closeSelf={closeSelf} keepOpen={keepOpen}>
      {(ref) => (
        <div className={styles.container} style={outerContainerStyle} ref={ref}>
          <div
            className={innerContainer}
            style={isMobileDevice ? {} : innerContainerStyle}
          >
            {isMobileDevice && (
              <div className={styles.mobileTranslationBoxContainer}>
                <TranslationBox />
              </div>
            )}
            {searchable && (
              <div className={styles.searchBoxContainer}>
                <SearchBox
                  value={filterText}
                  placeholder={getIntlText(searchable)}
                  setValue={setFilterText}
                  darkMode={darkMode}
                />
              </div>
            )}
            {hasTestamentTabs && (
              <div className={styles.testamentTabsContainer}>
                <TestamentTabs />
              </div>
            )}
            {showVNTabs && (
              <div className={styles.testamentTabsContainer}>
                <VNLanguagesTabs />
              </div>
            )}
            <div
              id="content-container"
              className={contentContainer}
              style={contentContainerStyle}
            >
              {children(filterText)}
            </div>
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default NavigatorSidebar;
