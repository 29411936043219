import { TextColor } from "./colors";

const LocalStorage = window.localStorage;

export const getSelectedTranslation = (): string | undefined =>
  LocalStorage.getItem("selectedTranslation") || undefined;

export const setSelectedTranslation = (
  translation: string | undefined
): void => {
  if (translation === undefined) {
    LocalStorage.removeItem("selectedTranslation");
  } else {
    LocalStorage.setItem("selectedTranslation", translation);
  }
};

export const getSelectedLanguage = (): string | undefined =>
  LocalStorage.getItem("selectedLanguage") || undefined;

export const setSelectedLanguage = (language: string | undefined): void => {
  if (language === undefined) {
    LocalStorage.removeItem("selectedLanguage");
  } else {
    LocalStorage.setItem("selectedLanguage", language);
  }
};

export type LineHeight = "short" | "normal" | "tall";
export type PanelPosition = "left" | "right";

export type TextSize = "smallest" | "small" | "medium" | "large" | "largest";

// export type GlobalTextSize = "small" | "normal" | "large";
export enum GlobalTextSize {
  small = "small",
  normal = "normal",
  large = "large",
}

export type GlobalTextSettingsState = {
  textSize: GlobalTextSize;
  textColor: TextColor;
};

export interface TextPanelSettingsState {
  lineHeight: LineHeight;
  panelPosition: PanelPosition;
}

export interface SettingsState {
  autoplay: boolean;
  autoadvance: boolean;
  darkMode: boolean;
  alwaysFitPanelWidth: boolean;
  saveSelectedTranslation: boolean;
  showASLCBTIntegration: boolean;
}

export const getGlobalTextSettings = ():
  | GlobalTextSettingsState
  | undefined => {
  const rawSettings = LocalStorage.getItem("globalTextSettings");
  if (rawSettings !== null) {
    return JSON.parse(rawSettings);
  }
  return undefined;
};

export const setGlobalTextSettings = (
  settings: GlobalTextSettingsState
): void => {
  LocalStorage.setItem("globalTextSettings", JSON.stringify(settings));
};

export const getTextPanelSettings = (): TextPanelSettingsState | undefined => {
  const rawSettings = LocalStorage.getItem("textPanelSettings");
  if (rawSettings !== null) {
    return JSON.parse(rawSettings);
  }
  return undefined;
};

export const setTextPanelSettings = (
  settings: TextPanelSettingsState
): void => {
  LocalStorage.setItem("textPanelSettings", JSON.stringify(settings));
};

export const getSettings = (): SettingsState | undefined => {
  const rawSettings = LocalStorage.getItem("settings");
  if (rawSettings !== null) {
    return JSON.parse(rawSettings);
  }
  return undefined;
};

export const getPrevTextVersion = (): string | undefined => {
  const version = LocalStorage.getItem("prevVersion");
  if (version !== null) {
    return version;
  }
  return undefined;
};

export const setPrevTextVersion = (version: string) => {
  LocalStorage.setItem("prevVersion", version);
};

export const setSettings = (settings: SettingsState): void => {
  LocalStorage.setItem("settings", JSON.stringify(settings));
};

export const setLastURL = (url: string): void => {
  LocalStorage.setItem("lastURL", url);
};

export const getLastURL = (): string | undefined => {
  const url = LocalStorage.getItem("lastURL");
  return url || undefined;
};
