import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./styles.module.css";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  setValue: (text: string) => void;
  value: string | undefined;
  placeholder?: string;
  darkMode: boolean;
}

const SearchBox = ({
  value,
  placeholder = "",
  setValue,
  darkMode,
}: Props): JSX.Element => {
  const showClearButton = value !== undefined && value.length > 0;
  const inputRef = useRef<HTMLInputElement>(null);

  const searchIconClassname = `${styles.icon} ${styles.searchIcon}`;

  const clearButton = `${styles.clearButton} ${
    darkMode && styles.clearButtonDarkMode
  } ${!showClearButton && styles.clearButtonHide}`;

  const clearIcon = `${styles.icon} ${styles.clearIcon}`;

  const containerClassname = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const inputClassName = `${styles.input} ${darkMode && styles.inputDarkMode}`;

  const textStyle = useTextStyle({
    large: "1.3em",
    normal: "1.1em",
    small: "1em",
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={containerClassname}>
      <FontAwesomeIcon icon={faSearch} className={searchIconClassname} />
      <input
        ref={inputRef}
        style={textStyle}
        type="text"
        value={value}
        placeholder={placeholder}
        className={inputClassName}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      />
      <div
        aria-label="Clear"
        className={clearButton}
        role="button"
        tabIndex={0}
        onKeyPress={undefined}
        onClick={() => {
          setValue("");
        }}
      >
        <FontAwesomeIcon
          icon={faXmarkCircle as IconProp}
          className={clearIcon}
        />
      </div>
    </div>
  );
};

export default SearchBox;
