import { useSelector } from "react-redux";
import styles from "./Slide.module.css";
import { RootState } from "../../../store";

interface Props {
  offset: number;
}

const Slide = ({ offset }: Props): JSX.Element => {
  const isSeparatorDragging = useSelector(
    (state: RootState) => state.interface.showSelectorList
  );

  const containerStyle = {
    transform: `translateX(${offset}px)`,
    transition: !isSeparatorDragging ? "transform 0.3s" : undefined,
  };

  return <div style={containerStyle} className={styles.container} />;
};

export default Slide;
