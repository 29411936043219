import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBible, faBookOpen } from "@fortawesome/free-solid-svg-icons";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import { toggleShowSelectorList } from "../../store/interface";

import styles from "./BrowseButton.module.css";
import fade from "../../styles/transitions/fadeScale.module.css";
import { RootState } from "../../store";

const BrowseButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.settings.darkMode);
  const showSelectorList = useSelector(
    (state: RootState) => state.interface.showSelectorList
  );

  const handler = (): void => {
    dispatch(toggleShowSelectorList());
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } `;

  return (
    <button
      type="button"
      className={container}
      onClick={handler}
      aria-label="Browse"
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={showSelectorList.toString()}
          timeout={300}
          classNames={fade}
        >
          <FontAwesomeIcon
            icon={showSelectorList ? faBookOpen : faBible}
            className={styles.icon}
          />
        </CSSTransition>
      </SwitchTransition>
    </button>
  );
};

export default BrowseButton;
