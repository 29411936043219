import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScissors } from "@fortawesome/free-solid-svg-icons";

import DownloadButton from "./DownloadButton";
import ShareButton from "./ShareButton";
import styles from "./styles.module.css";

interface Props {
  show: boolean;
  clipModeActive: boolean;
}

const SideButtons = ({ show, clipModeActive }: Props): JSX.Element => {
  const container = `${styles.container} ${
    clipModeActive && styles.containerClipModeActive
  } ${!show && styles.containerHide}`;

  const clipIconClass = `${styles.clipIcon} ${
    clipModeActive && styles.clipIconShow
  }`;

  return (
    <div className={container}>
      <ShareButton />
      <DownloadButton />
      <FontAwesomeIcon className={clipIconClass} icon={faScissors} />
    </div>
  );
};

export default SideButtons;
