import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useHomeCountryCode from "./useHomeCountryCode";
import {
  getSelectedLanguage,
  setSelectedLanguage,
} from "../utils/localStorage";

import countryLanguages from "../data/country_languages.json";
import { setTextLanguage } from "../store/interface";
import useLoadInternationalization from "./useLoadInternationalization";

const DEFAULT_LANGUAGE = "EN";

const useInitInternationalization = (): void => {
  useLoadInternationalization();

  const homeCountry = useHomeCountryCode();
  const dispatch = useDispatch();

  useEffect(() => {
    if (homeCountry !== undefined) {
      const selectedLanguage = getSelectedLanguage();
      if (selectedLanguage === undefined) {
        const countryLanguage = (countryLanguages as { [key: string]: string })[
          homeCountry
        ];

        if (countryLanguage !== undefined && countryLanguage !== "unknown") {
          dispatch(setTextLanguage(countryLanguage));
          setSelectedLanguage(countryLanguage);
        } else {
          dispatch(setTextLanguage(DEFAULT_LANGUAGE));
          setSelectedLanguage(DEFAULT_LANGUAGE);
        }
      } else {
        dispatch(setTextLanguage(selectedLanguage));
      }
    }
  }, [homeCountry, dispatch]);
};

export default useInitInternationalization;
