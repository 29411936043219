import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import SettingBox from "./index";
import ButtonGroup from "../ButtonGroup";
import { setPanelPosition } from "../../../store/textPanelSettings";

import { ReactComponent as LeftPosition } from "../../../assets/icons/panel-text-left.svg";
import { ReactComponent as RightPosition } from "../../../assets/icons/panel-text-right.svg";
import Button from "../Button/Rectangle";
import { rightPanelWidth } from "../../../store/screen/panelSelectors";
import { setSeparatorPosition } from "../../../store/screen";

const positions = ["left", "right"];

const PanelPosition = (): JSX.Element => {
  const dispatch = useDispatch();
  const newSeparatorPosition = useSelector(rightPanelWidth);

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { panelPosition } = useSelector(
    (state: RootState) => state.textPanelSettings
  );

  return (
    <SettingBox width={215} label="Panel Position" darkMode={darkMode}>
      <ButtonGroup>
        {positions.map((position) => {
          const onClick = () => {
            if (panelPosition !== position) {
              dispatch(setPanelPosition(position));
              dispatch(setSeparatorPosition(newSeparatorPosition));
            }
          };
          const Icon = position === "left" ? LeftPosition : RightPosition;

          return (
            <Button
              key={position}
              darkMode={darkMode}
              isSelected={panelPosition === position}
              onClick={onClick}
            >
              <Icon style={{ width: 50, height: 30 }} />
            </Button>
          );
        })}
      </ButtonGroup>
    </SettingBox>
  );
};

export default PanelPosition;
