import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BBBVideo,
  CBTVideo,
  firstVerseNumber,
  getBookName,
  getFirstChapterVerse,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { RootState } from "../store";
import { getURLParams } from "../utils/history";
import { setLastURL } from "../utils/localStorage";

interface URLManipulator {
  selectBBBVideo: (video: BBBVideo) => void;
  selectBBBVideoReset: (video: BBBVideo) => void;
  selectCBTVideo: (video: CBTVideo) => void;
  toggleTextVersion: (textVersion: string | undefined) => void;
  updateChapterVerse: (newChapter: string, newVerse: string) => void;
}

const useURLManipulator = (): URLManipulator => {
  const history = useHistory();

  const books = useSelector(
    (state: RootState) => state.translation.rawData?.books
  );

  const stories = useSelector(
    (state: RootState) => state.translation.rawData?.stories
  );

  const { code, bookOrSlug, chapter, verse } = getURLParams();

  const updateChapterVerse = useCallback(
    (newChapter: string, newVerse: string) => {
      if (verse !== newVerse || chapter !== newChapter) {
        history.replace(
          `/${code}/${bookOrSlug}.${newChapter}.${newVerse}${window.location.search}`
        );
      }
    },
    [bookOrSlug, chapter, code, history, verse]
  );

  const toggleTextVersion = useCallback(
    (textVersion: string | undefined): void => {
      const url = new URL(window.location.toString());
      const { searchParams: params } = url;

      if (textVersion === undefined) {
        params.delete("text");
      } else {
        params.set("text", textVersion);
      }
      const newUrl = `${url.pathname}${url.search}`;

      setLastURL(newUrl);
      history.push(newUrl);
    },
    [history]
  );

  const selectBBBVideo = useCallback(
    (video: BBBVideo): void => {
      if (bookOrSlug !== undefined && chapter !== undefined) {
        const newVerse = firstVerseNumber(video, chapter);

        if (newVerse !== undefined) {
          history.push(
            `/${code}/${bookOrSlug}.${chapter}.${newVerse}${window.location.search}`
          );
        }
      }
    },
    [bookOrSlug, chapter, code, history]
  );

  const selectBBBVideoReset = useCallback(
    (video: BBBVideo): void => {
      const bookName = getBookName(video);

      const newBook = books?.find(
        (book) => book.title.toLowerCase() === bookName.toLowerCase()
      )?.book_shortcode;

      if (newBook !== undefined) {
        const [newChapter, newVerse] = getFirstChapterVerse(video);

        history.push(
          `/${code}/${newBook}.${newChapter}.${newVerse}${window.location.search}`
        );
      }
    },
    [books, history, code]
  );

  const selectCBTVideo = useCallback(
    (video: CBTVideo): void => {
      const meta = stories?.find((story) =>
        story.videos.some((v) => v === video)
      );

      if (meta !== undefined) {
        history.push(
          `/${code}/${meta.slug}/${video.type}${window.location.search}`
        );
      }
    },
    [code, history, stories]
  );

  return {
    updateChapterVerse,
    selectBBBVideo,
    selectBBBVideoReset,
    selectCBTVideo,
    toggleTextVersion,
  };
};

export default useURLManipulator;
