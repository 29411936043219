import { createFFmpeg, fetchFile, ProgressCallback } from "@ffmpeg/ffmpeg";

import {
  BBBVideo,
  CBTVideo,
  CBTVideoMeta,
  getTitle,
} from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { convertTimestampToString } from "./timestamp";

export interface VideoSrc {
  poster?: string;
  src: {
    hls: string;
    dash: string;
  };
}

const tempFilename = "temp.mp4";
const clippedFilename = "clipped.mp4";
const outputFilename = "output.mp4";

const ffmpeg = createFFmpeg();

export const setFFmpegProgressCallback = (callback: ProgressCallback): void =>
  ffmpeg.setProgress(callback);

export const loadFFmpeg = async (): Promise<void> => {
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }
};

const deleteTempFiles = async () => {
  await ffmpeg.FS("unlink", tempFilename);
  await ffmpeg.FS("unlink", outputFilename);
  await ffmpeg.FS("unlink", clippedFilename);
};

export const cancelFFmpegTask = async () => {
  try {
    await deleteTempFiles();
    ffmpeg.exit();
  } catch (e) {
    // console.error(e);
  }
};

export const getCFID = (url: string): string => url.split("/")[4];

export const getCBTThumbnailUrl = (url: string, resolution: number): string => {
  if (url) {
    const id = getCFID(url);
    return `https://api.deafbiblesociety.com/gateway-video/${id}/thumbnails/thumbnail.jpg?time=1s&height=${resolution}`;
  }

  return "";
};

const titleToFilename = (title: string | undefined) =>
  `${title
    ?.toLowerCase()
    .replace(/; /g, "__")
    .replace(/;/g, "__")
    .replace(/ /g, "-")}.mp4`;

export const formatDownloadLink = (
  downloadUrl: string | undefined,
  title: string | undefined
): string => {
  const gatewayUrl = `https://api.deafbiblesociety.com/utility/download-video`;
  const filename = titleToFilename(title);
  return `${gatewayUrl}?url=${downloadUrl}&filename=${filename}`;
};

export const clipVideo = async (
  url: string,
  title: string,
  videoBlob: Blob,
  startTime: number,
  endTime: number
): Promise<string> => {
  ffmpeg.FS("writeFile", tempFilename, await fetchFile(videoBlob));
  await ffmpeg.run(
    "-ss",
    convertTimestampToString(startTime),
    "-i",
    tempFilename,
    "-t",
    convertTimestampToString(endTime - startTime),
    "-c",
    "copy",
    // "-c:v",
    // "libx264",
    // "-avoid_negative_ts",
    // "disabled",
    clippedFilename
  );

  await ffmpeg.run("-i", clippedFilename, outputFilename);

  const data = ffmpeg.FS("readFile", outputFilename);

  return URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
};

export const downloadUrl = (url: string, filename: string): void => {
  const anchorElement = document.createElement("a");
  anchorElement.href = url;
  anchorElement.download = filename;
  document.body.appendChild(anchorElement);

  anchorElement.click();

  document.body.removeChild(anchorElement);
  URL.revokeObjectURL(url);
};

interface TitleFirstVideos {
  title: string;
  videos: BBBVideo[];
}

export const sortByTitles = (videos: BBBVideo[]): TitleFirstVideos[] =>
  videos.reduce((acc, cur) => {
    if (acc.length === 0 || acc[acc.length - 1].title !== getTitle(cur)) {
      acc.push({
        title: getTitle(cur),
        videos: [],
      });
    }

    const lastItem = acc[acc.length - 1];

    lastItem.videos.push(cur);

    return acc;
  }, [] as TitleFirstVideos[]);

export const getBBBVideoSrc = (
  video: BBBVideo | undefined
): VideoSrc | undefined => {
  if (video !== undefined) {
    const { thumbnail_url: poster, video_url: src } = video;
    return {
      poster,
      src,
    } as VideoSrc;
  }
  return undefined;
};

export const getCBTVideoSrc = (
  meta: CBTVideoMeta | undefined,
  video: CBTVideo | undefined
): VideoSrc | undefined => {
  if (meta !== undefined && video !== undefined) {
    const { hls, dash } = video;

    return {
      poster: meta.thumbnail_image_url,
      src: {
        hls,
        dash,
      },
    } as VideoSrc;
  }
  return undefined;
};
