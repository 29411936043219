import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";
import {
  setShowTranslationsDropdown,
  setShowCountriesDropdown,
} from "../../store/interface";

import styles from "./styles.module.css";
import { RootState } from "../../store";
import { currentCountry } from "../../store/translations/selectors";
import Flag from "../Flag";
import BasicButton from "./BasicButton";
import { currentTeam } from "../../store/translation/selectors";

const TranslationBox = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(
    (state: RootState) => state.translations.selectedCountry
  );
  const activeCountry = useSelector(currentCountry);

  const code = useSelector((state: RootState) => state.params.code);

  const team = useSelector(currentTeam);

  const transitionKey = `${team?.id}:${code}`;

  const { showTranslationsDropdown, showCountriesDropdown } = useSelector(
    (state: RootState) => state.interface
  );

  const countryCode = activeCountry ?? selectedCountry;

  const switchTranslation = (): void => {
    dispatch(setShowCountriesDropdown(false));
    dispatch(setShowTranslationsDropdown(!showTranslationsDropdown));
  };

  const switchCountry = (): void => {
    dispatch(setShowTranslationsDropdown(false));
    dispatch(setShowCountriesDropdown(!showCountriesDropdown));
  };

  return (
    <div className={styles.container}>
      <BasicButton
        isOpen={showTranslationsDropdown}
        toggle={switchTranslation}
        transitionKey={transitionKey}
        text={code}
        icon={
          code !== undefined ? (
            <img
              crossOrigin="anonymous"
              alt=""
              className={styles.teamIconImg}
              src={team?.icon_url || ""}
            />
          ) : (
            <FontAwesomeIcon className={styles.teamIconImg} icon={faFilm} />
          )
        }
      />

      <BasicButton
        transitionKey={countryCode}
        isOpen={showCountriesDropdown}
        toggle={switchCountry}
        icon={<Flag countryCode={countryCode} diameter={33} />}
      />
    </div>
  );
};

export default TranslationBox;
