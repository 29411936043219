/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { Language } from "../../utils/api/getTextVersionsMetadata";
import { Verse } from "../../utils/api/getTextVersion";
import { getPrevTextVersion } from "../../utils/localStorage";
import { SearchResult } from "../../types/searchResult";

export enum Mode {
  browse = "browse",
  search = "search",
}

export enum RequestStatus {
  init = "init",
  failed = "failed",
  loading = "loading",
  succeeded = "succeeded",
}

interface TextPanelContentState {
  rawData?: Verse[];
  error?: undefined | string;
  searchInput: string;
  searchRequestStatus: RequestStatus;
  searchResult: SearchResult | undefined;
  selectedSearchResult: string | undefined;
  mode: Mode;
  versionsMetadata?: Language[];
  previouslySelectedVersion?: string;
  versionPickerWidth: number;
}

const initialState: TextPanelContentState = {
  mode: Mode.browse,
  previouslySelectedVersion: getPrevTextVersion(),
  versionPickerWidth: 0,
  searchInput: "",
  searchResult: undefined,
  searchRequestStatus: RequestStatus.init,
  selectedSearchResult: undefined,
};

const textPanelContentSlice = createSlice({
  name: "textPanelContent",
  initialState,
  reducers: {
    setMode: (state, action): void => {
      state.mode = action.payload;
    },
    setSearchInput: (state, action): void => {
      state.searchInput = action.payload;
    },
    setSearchResult: (state, action): void => {
      state.searchResult = action.payload;
    },
    setSelectedSearchResult: (state, action): void => {
      state.selectedSearchResult = action.payload;
    },
    setSearchRequestStatus: (state, action): void => {
      state.searchRequestStatus = action.payload;
    },
    toggleMode: (state): void => {
      state.mode = state.mode === Mode.browse ? Mode.search : Mode.browse;
    },
    setPreviouslySelectedVersion: (state, action): void => {
      state.previouslySelectedVersion = action.payload;
    },
    setTextPanelError: (state, action): void => {
      state.error = action.payload;
    },
    setTextPanelContent: (state, action): void => {
      state.rawData = action.payload;
    },
    setVersionsMetadata: (state, action): void => {
      state.versionsMetadata = action.payload;
    },
    setVersionPickerWidth: (state, action): void => {
      state.versionPickerWidth = action.payload;
    },
  },
});

export const {
  setMode,
  toggleMode,
  setSearchInput,
  setSearchResult,
  setTextPanelError,
  setTextPanelContent,
  setVersionsMetadata,
  setVersionPickerWidth,
  setSearchRequestStatus,
  setSelectedSearchResult,
  setPreviouslySelectedVersion,
} = textPanelContentSlice.actions;

export default textPanelContentSlice.reducer;
