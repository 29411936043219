import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import styles from "./styles.module.css";
import { useTextStyle } from "../../../../hooks/useTextStyle";

interface Props {
  darkMode: boolean;
  isSelected: boolean;
  onClick: () => void;
  icon?: IconProp;
  text: string;
}

const Item = ({
  darkMode,
  isSelected,
  onClick,
  icon,
  text,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isSelected && styles.containerSelected}`;

  const textStyle = useTextStyle(
    { large: "1.3em", normal: "1.15em", small: "1em" },
    !isSelected
  );

  return (
    <div
      style={textStyle}
      className={container}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={undefined}
    >
      {icon !== undefined && (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={icon} className={styles.icon} />
        </div>
      )}
      <div className={styles.textContainer}>{text}</div>
    </div>
  );
};

export default Item;
