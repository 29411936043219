import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";

import Button from "./Button";
import { setShowShareModal } from "../../../store/interface";
import { RootState } from "../../../store";

const ShareButton = () => {
  const dispatch = useDispatch();
  const { videoClipMode } = useSelector(
    (state: RootState) => state.mainVideoPlayer
  );

  const icon = <FontAwesomeIcon icon={faShareFromSquare} />;

  const onClick = () => {
    dispatch(setShowShareModal(true));
  };

  const label = `Share${videoClipMode ? " (Clip only)" : ""}`;

  return (
    <Button
      emphasized={videoClipMode}
      label={label}
      icon={icon}
      onClick={onClick}
    />
  );
};

export default ShareButton;
