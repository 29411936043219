import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import styles from "./ErrorMessage.module.css";
import useInternationalization from "../../hooks/useInternationalization";
import fade from "../../styles/transitions/fade.module.css";

interface Props {
  show: boolean;
  darkMode: boolean;
}

const ErrorMessage = ({ show, darkMode }: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div
        tabIndex={-1}
        className={`${styles.errorMessageContainer} ${
          darkMode && styles.errorMessageDarkModeContainer
        }`}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={styles.errorIcon}
        />
        {getIntlText("feedback_error_message")}
      </div>
    </CSSTransition>
  );
};

export default ErrorMessage;
