import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CBTVideoType } from "@deaf-bible-society-2-0/deafbible-metadata-utils";
import styles from "./CBTVideo.module.css";
import { useTextStyle } from "../../../../hooks/useTextStyle";
import { getCBTIcon } from "../../../../utils/navigatorHelper";

interface Props {
  id: CBTVideoType;
  text: string;
  darkMode: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export const CBTVideo = ({
  id,
  text,
  onClick,
  darkMode,
  isSelected,
}: Props) => {
  const buttonClass = `${styles.button} ${darkMode && styles.buttonDarkMode} ${
    isSelected && styles.buttonSelected
  }`;

  const textStyle = useTextStyle({
    large: "1.4em",
    normal: "1em",
    small: "0.9em",
  });

  return (
    <div className={styles.container} style={textStyle}>
      <button onClick={onClick} type="button" className={buttonClass}>
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={getCBTIcon(id)} className={styles.icon} />
        </div>
        <div className={styles.textContainer}>{text}</div>
      </button>
    </div>
  );
};
