import { ReactComponent as Download } from "../../../assets/icons/download.svg";

import useInternationalization from "../../../hooks/useInternationalization";

import styles from "./DownloadButton.module.css";
import Button from "./Button";
import useDownload from "../../../hooks/useDownload";

interface Props {
  darkMode: boolean;
  isMobile: boolean;
}

const DownloadButton = ({ darkMode, isMobile }: Props): JSX.Element => {
  const { isDownloadAvailable, initiateDownload } = useDownload();
  const { getIntlText } = useInternationalization();

  return isDownloadAvailable ? (
    <Button
      darkMode={darkMode}
      isMobile={isMobile}
      onClick={initiateDownload}
      text={getIntlText("download")}
      icon={<Download className={styles.icon} />}
    />
  ) : (
    <div />
  );
};

export default DownloadButton;
