import styles from "./Item.module.css";

interface Props {
  id?: string;
  children: React.ReactNode;
  darkMode: boolean;
  bordered?: boolean;
}

interface SelectableProps {
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  isSelected?: boolean;
  select?: () => void;
}

const SelectableItem = ({
  children,
  isSelected,
  select,
  id,
  bordered,
  darkMode,
  onPointerEnter,
  onPointerLeave,
}: Props & SelectableProps) => {
  const container = `${styles.container} ${styles.containerSelectable} ${
    bordered && styles.containerBorder
  } ${darkMode && styles.containerDarkMode} ${
    darkMode && styles.containerSelectableDarkMode
  } ${isSelected && styles.containerSelected} ${
    isSelected && darkMode && styles.containerSelectedDarkMode
  }`;

  return (
    <div
      id={id}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      className={container}
      onClick={select}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
    >
      {children}
    </div>
  );
};

const NormalItem = ({ children, darkMode, bordered, id }: Props) => {
  const container = `${styles.container} ${
    bordered && styles.containerBorder
  } ${darkMode && styles.containerDarkMode}`;

  return (
    <div id={id} className={container}>
      {children}
    </div>
  );
};

const Item = ({
  id,
  children,
  isSelected,
  select,
  onPointerEnter,
  onPointerLeave,
  darkMode,
  bordered = false,
}: Props & SelectableProps) => {
  const Element = select !== undefined ? SelectableItem : NormalItem;

  return (
    <Element
      key={id}
      id={id}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      isSelected={isSelected}
      bordered={bordered}
      select={select}
      darkMode={darkMode}
    >
      {children}
    </Element>
  );
};

export default Item;
