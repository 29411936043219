import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";

interface Props {
  children: React.ReactNode;
  darkMode: boolean;
  onClick: () => void;
  selected: boolean;
  hasCheckMark?: boolean;
  width?: number | string;
  height?: number | string;
}

const Button = ({
  children,
  darkMode,
  hasCheckMark = false,
  selected,
  onClick,
  width,
  height,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${selected && !hasCheckMark && styles.containerSelected}`;

  const style = {
    width,
    height,
  };

  const check = `${styles.check} ${darkMode && styles.checkDarkMode} ${
    selected && hasCheckMark && styles.checkSelected
  } `;

  const checkIcon = `${styles.checkIcon} ${selected && styles.checkIconShow}`;

  return (
    <div
      className={container}
      style={style}
      onClick={onClick}
      onKeyPress={undefined}
      role="button"
      tabIndex={0}
    >
      <div className={styles.content}>{children}</div>
      {hasCheckMark && (
        <div className={check}>
          <FontAwesomeIcon icon={faCheck} className={checkIcon} />
        </div>
      )}
    </div>
  );
};

export default Button;
