import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-thick.svg";
import styles from "./styles.module.css";
import { RootState } from "../../store";
import TextSize from "./SettingBox/TextSize";
import TextColorBox from "./SettingBox/TextColor";
import { isMobile } from "../../store/screen/selectors";
import { setShowSettingsModal } from "../../store/interface";
import Overlay from "../Overlay";

const GlobalSettingsModal = (): JSX.Element => {
  const dispatch = useDispatch();

  const isMobileDevice = useSelector(isMobile);
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const show = useSelector(
    (state: RootState) => state.interface.showSettingsModal
  );

  const close = () => {
    dispatch(setShowSettingsModal(false));
  };

  const width = !isMobileDevice ? 500 : "100%";

  const shouldRoundCorners = !isMobileDevice;

  const containerClass = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${show && styles.show} ${shouldRoundCorners && styles.containerRounded}`;

  const arrowClass = `${styles.arrow} ${show && styles.arrowOpen}`;

  return (
    <Overlay show={show} closeSelf={close}>
      {(ref) => (
        <div ref={ref} style={{ width }} className={containerClass}>
          <div className={styles.innerContainer}>
            <TextSize />
            <TextColorBox />
          </div>
          <div className={styles.bottom}>
            <Arrow className={arrowClass} onClick={close} />
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default GlobalSettingsModal;
