import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { RootState } from "../../../store";
import ShareModalButton from "./ShareModalButton";
import DownloadButton from "./DownloadButton";
import Switch from "./Switch";
import { videoPanelStyle } from "../../../store/screen/panelSelectors";
import useInternationalization from "../../../hooks/useInternationalization";
import {
  setAlwaysFitPanelWidth,
  setAutoadvance,
  setAutoplay,
} from "../../../store/settings";

import autoVideo from "../../../assets/videos/autoadvance.mp4";
import { VideoTooltip } from "../../VideoTooltip";

const ActionBar = (): JSX.Element => {
  const dispatch = useDispatch();
  const { autoplay, autoadvance, darkMode, alwaysFitPanelWidth } = useSelector(
    (state: RootState) => state.settings
  );

  const { textVersion } = useSelector((state: RootState) => state.params);
  const showTextPanel = textVersion !== undefined;

  const autoplayHandler = (value: boolean) => {
    dispatch(setAutoplay(value));
  };

  const autoadvanceHandler = (value: boolean) => {
    dispatch(setAutoadvance(value));
  };

  const panelWidthHandler = (value: boolean) => {
    dispatch(setAlwaysFitPanelWidth(value));
  };

  const panelStyle = useSelector(videoPanelStyle);
  const isMobileDevice = panelStyle.width < 600;
  const { getIntlText } = useInternationalization();

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isMobileDevice && styles.containerMobile}`;

  const switchesContainer = `${styles.switchesContainer} ${
    isMobileDevice && styles.switchesContainerMobile
  }`;

  const buttonsContainer = `${styles.buttonsContainer} ${
    isMobileDevice && styles.buttonsContainerMobile
  }`;

  return (
    <div className={container}>
      <div className={switchesContainer}>
        {!isMobileDevice && showTextPanel && (
          <Switch
            label="Stretch to fit"
            value={alwaysFitPanelWidth}
            setValue={panelWidthHandler}
            darkMode={darkMode}
            isMobile={isMobileDevice}
          />
        )}
        <Switch
          label={getIntlText("autoplay")}
          value={autoplay}
          setValue={autoplayHandler}
          darkMode={darkMode}
          isMobile={isMobileDevice}
        />
        <Switch
          label={getIntlText("auto_advance")}
          value={autoadvance}
          setValue={autoadvanceHandler}
          darkMode={darkMode}
          isMobile={isMobileDevice}
        />
        <div style={{ marginLeft: 5 }}>
          <VideoTooltip
            id="autoadvanceExplanation"
            src={autoVideo}
            description={getIntlText(
              "automatically_jump_to_the_next_video_when_the_current_video_ends"
            )}
          />
        </div>
      </div>
      <div className={buttonsContainer}>
        <ShareModalButton darkMode={darkMode} isMobile={isMobileDevice} />
        <DownloadButton darkMode={darkMode} isMobile={isMobileDevice} />
      </div>
    </div>
  );
};

export default ActionBar;
