import React from "react";

import styles from "./styles.module.css";

interface Props {
  label: string;
  darkMode: boolean;
  width?: string | number;
  children: React.ReactNode;
}

const SettingBox = ({
  label,
  darkMode,
  width = "100%",
  children,
}: Props): JSX.Element => {
  const labelClass = `${styles.label} ${darkMode && styles.labelDarkMode}`;

  const style = {
    width,
  };

  return (
    <div style={style} className={styles.container}>
      <div className={labelClass}>{label}</div>
      <div className={styles.setting}>{children}</div>
    </div>
  );
};

export default SettingBox;
