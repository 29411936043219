import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import styles from "./ThankYou.module.css";

import useInternationalization from "../../hooks/useInternationalization";
import Button, { Type } from "../Button";

interface Props {
  darkMode: boolean;
  closeSelf: () => void;
}

const ThankYou = ({ darkMode, closeSelf }: Props): JSX.Element => {
  const { getIntlText } = useInternationalization();

  return (
    <div
      className={`${styles.thankYouContainer} ${
        darkMode && styles.darkModeText
      }`}
    >
      <FontAwesomeIcon icon={faCircleCheck} className={styles.thankYouIcon} />
      <div className={styles.thankYouText}>{getIntlText("thank_you")}</div>
      <Button
        type={Type.Secondary}
        onClick={closeSelf}
        darkMode={darkMode}
        morePadding
        lighterVersion
      >
        {getIntlText("continue")}
      </Button>
    </div>
  );
};

export default ThankYou;
