/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { getSettings, setSettings, SettingsState } from "../utils/localStorage";

const defaultState: SettingsState = {
  autoplay: false,
  autoadvance: true,
  darkMode: true,
  alwaysFitPanelWidth: false,
  saveSelectedTranslation: true,
  showASLCBTIntegration: false,
};

const initialState = getSettings() || defaultState;

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSavedSelectedTranslation: (state, action): void => {
      state.saveSelectedTranslation = action.payload;
      setSettings(state);
    },
    setAlwaysFitPanelWidth: (state, action): void => {
      state.alwaysFitPanelWidth = action.payload;
      setSettings(state);
    },
    setAutoplay: (state, action): void => {
      state.autoplay = action.payload;
      setSettings(state);
    },
    setAutoadvance: (state, action): void => {
      state.autoadvance = action.payload;
      setSettings(state);
    },
    setDarkMode: (state, action): void => {
      state.darkMode = action.payload;
      setSettings(state);
    },
    setShowASLCBTIntegration: (state, action): void => {
      state.showASLCBTIntegration = action.payload;
      setSettings(state);
    },
  },
});

export const {
  setSavedSelectedTranslation,
  setShowASLCBTIntegration,
  setAlwaysFitPanelWidth,
  setAutoplay,
  setAutoadvance,
  setDarkMode,
} = settingsSlice.actions;

export default settingsSlice.reducer;
