import { ReactComponent as LatinIcon } from "../../../assets/icons/text-eng.svg";
import { ReactComponent as JapaneseIcon } from "../../../assets/icons/text-jap.svg";

import { getFillColor, TextColor } from "../../../utils/colors";

import Button from "./index";

interface Props {
  darkMode: boolean;
  textColor: TextColor;
  isJapanese?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const Color = ({
  textColor,
  darkMode,
  isJapanese = false,
  onClick,
  isSelected,
}: Props): JSX.Element => {
  const Icon = isJapanese ? JapaneseIcon : LatinIcon;

  const iconStyle = {
    fill: getFillColor(textColor),
    width: 30,
    height: 30,
    transition: "fill 0.1s",
  };

  return (
    <Button
      darkMode={darkMode}
      onClick={onClick}
      hasCheckMark
      selected={isSelected}
    >
      <Icon style={iconStyle} />
    </Button>
  );
};

export default Color;
