import React from "react";

import styles from "./styles.module.css";

interface Props {
  show: boolean;
  children: React.ReactNode;
  darkMode: boolean;
}

export const Content = ({ show, darkMode, children }: Props) => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${!show && styles.hide}`;

  return <div className={container}>{children}</div>;
};
