import { createSelector } from "@reduxjs/toolkit";
import { BaseTranslation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

import { RootState } from "..";
import { TranslationsByCountry } from "../../utils/api/getTranslations";

const selectedCountry = (state: RootState): string | undefined =>
  state.translations.selectedCountry;

const currentTranslation = (state: RootState) => state.params.code;

export const translationsByCountry = (
  state: RootState
): TranslationsByCountry[] | undefined => state.translations.rawData;

export const listTranslations = createSelector(
  translationsByCountry,
  (translations) =>
    translations?.reduce(
      (acc, cur) => acc.concat(cur.translations),
      [] as BaseTranslation[]
    ) || []
);

export const currentCountry = createSelector(
  currentTranslation,
  translationsByCountry,
  (code, translations) => {
    if (translations !== undefined) {
      const selected = translations.find((set) =>
        set.translations.some(
          (translation) => translation.translation_shortcode === code
        )
      );

      return selected?.countryCode;
    }
    return undefined;
  }
);

export const translationsBySelectedCountry = createSelector(
  selectedCountry,
  translationsByCountry,
  (countryCode, translations) => {
    if (translations !== undefined) {
      const selected = translations.find(
        (translation) => translation.countryCode === countryCode
      );

      if (selected !== undefined) {
        return selected.translations;
      }
    }

    return [];
  }
);
