import { useDispatch, useSelector } from "react-redux";

import Tabs from "./index";
import useInternationalization from "../../../hooks/useInternationalization";
import { RootState } from "../../../store";
import { setSelectedTestament } from "../../../store/interface";

const options = ["old", "new"];
const intlKeys = ["old_testament", "new_testament"];

const TestamentTabs = (): JSX.Element => {
  const dispatch = useDispatch();
  const { getIntlText } = useInternationalization();
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { selectedTestament } = useSelector(
    (state: RootState) => state.interface
  );

  const labels = intlKeys.map((key) => getIntlText(key));

  const selectTestament = (testament: string) => {
    dispatch(setSelectedTestament(testament));
  };

  return (
    <Tabs
      darkMode={darkMode}
      options={options}
      labels={labels}
      selectOption={selectTestament}
      selectedOption={selectedTestament}
    />
  );
};

export default TestamentTabs;
