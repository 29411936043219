import { useSelector } from "react-redux";

import logoDarkMode from "../../assets/images/logo_dark.svg";
import logoLightMode from "../../assets/images/logo_light.svg";
import logoMobile from "../../assets/images/logo_mobile.svg";

import styles from "./Logo.module.css";
import {
  keepNavigatorOpen,
  sidebarWidth,
} from "../../store/screen/navigationSidebarSelectors";

interface Props {
  type: "short" | "long";
  darkMode: boolean;
}

const Logo = (props: Props) => {
  const { type, darkMode } = props;

  const keepNavOpen = useSelector(keepNavigatorOpen);
  const navWidth = useSelector(sidebarWidth);
  const svgImage =
    (type === "short" && logoMobile) ||
    (darkMode ? logoDarkMode : logoLightMode);

  const width = type === "short" ? 40 : 180;

  return (
    <a
      href="/"
      className={styles.container}
      aria-label="Deaf Bible Logo"
      style={{
        marginLeft: -(width / 2) - (keepNavOpen ? navWidth / 2 : 0),
        width,
      }}
    >
      <img className={styles.img} src={svgImage} alt="" />
    </a>
  );
};

export default Logo;
