import axios from "axios";

export interface Verse {
  type: "verse" | "title";
  number?: string;
  sections: string[];
}

const getTextVersion = async (
  id: string | null,
  book: string | null,
  chapter: string | null
): Promise<Verse[] | undefined> => {
  if (id !== null && book !== null && chapter !== null) {
    const { data: verses } = await axios.get<Verse[]>(
      `${
        process.env.REACT_APP_DEAFBIBLE_API
      }/text/${id}/${book.toLowerCase()}/${chapter}`
    );

    return verses;
  }

  return undefined;
};

export default getTextVersion;
