import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { PanelPosition } from "../../utils/localStorage";
import {
  BPMobile,
  headerHeight,
  isMobile,
  windowHeight,
  windowWidth,
} from "./selectors";
import { translationType } from "../translation/selectors";

import {
  keepNavigatorOpen,
  sidebarHeight,
  sidebarWidth,
} from "./navigationSidebarSelectors";

import { getInnerContainerWidth } from "../../utils/resolution";
import { hasTextTranslation } from "../params/selectors";

const alwaysFitPanelWidth = (state: RootState) =>
  state.settings.alwaysFitPanelWidth;

export const minimumVideoPanelWidth = 500;
export const minimumTextPanelWidth = 400;

export const translationContentPadding = createSelector(
  isMobile,
  (isMobileDevice) => (isMobileDevice ? 24 : 18)
);

export const textPanelHeaderHeight = (): number => 100;

const showTextPanel = createSelector(
  hasTextTranslation,
  translationType,
  isMobile,
  (show, type, isMobileDevice) => show && type === "BBB" && !isMobileDevice
);

const isSeparatorDragging = (state: RootState) =>
  state.interface.isPanelSeparatorDragging;
const showTextPanelSettingsModal = (state: RootState) =>
  state.interface.showTextPanelSettingsModal;

const rawSeparatorPosition = (state: RootState): number =>
  state.screen.separatorPosition;

const textPanelPosition = (state: RootState): PanelPosition =>
  state.textPanelSettings.panelPosition;

const versionPickerBoxWidth = (state: RootState) =>
  state.textPanelContent.versionPickerWidth;

const showVersionPickerModal = (state: RootState) =>
  state.interface.showVersionPickerModal;

export const minimumLeftPanelWidth = createSelector(
  textPanelPosition,
  (position) =>
    position === "left" ? minimumTextPanelWidth : minimumVideoPanelWidth
);

export const minimumRightPanelWidth = createSelector(
  textPanelPosition,
  (position) =>
    position === "left" ? minimumVideoPanelWidth : minimumTextPanelWidth
);

export const separatorWidth = (): number => 20;

const separatorPosition = createSelector(
  rawSeparatorPosition,
  minimumLeftPanelWidth,
  minimumRightPanelWidth,
  separatorWidth,
  windowWidth,
  (position, minLeftWidth, minRightWidth, sWidth, totalWidth) => {
    return Math.min(
      Math.max(minLeftWidth, position),
      totalWidth - minRightWidth - sWidth
    );
  }
);

export const leftPanelWidth = createSelector(
  separatorPosition,
  showTextPanel,
  textPanelPosition,
  windowWidth,
  (width, showText, position, fullWidth) =>
    (position === "left" && !showText) || showText ? width : fullWidth
);

export const rightPanelWidth = createSelector(
  separatorPosition,
  separatorWidth,
  windowWidth,
  showTextPanel,
  textPanelPosition,
  (separatorP, separatorW, totalWidth, showText, panelPosition) => {
    return (
      totalWidth -
      (showText || (!showText && panelPosition === "right")
        ? separatorP + separatorW
        : 0)
    );
  }
);

export const panelTransition = createSelector(
  isSeparatorDragging,
  (isDragging) =>
    `background 0.3s, padding-top 0.3s, opacity 0.3s${
      !isDragging ? ", transform 0.3s, width 0.3s" : ""
    }`
);

export const panelTop = createSelector(headerHeight, (height) => height);

export const leftPanelTransform = createSelector(
  showTextPanel,
  textPanelPosition,
  leftPanelWidth,
  separatorWidth,
  (showPanel, panelPosition, panelWidth, separatorW) =>
    `translateX(${
      showPanel || (!showPanel && panelPosition === "right")
        ? 0
        : -(panelWidth + separatorW)
    }px)`
);

const rightPanelLeftPosition = createSelector(
  showTextPanel,
  textPanelPosition,
  windowWidth,
  leftPanelWidth,
  separatorWidth,
  (showPanel, panelPosition, totalWidth, panelWidth, separatorW) =>
    (!showPanel && panelPosition === "right" && totalWidth + separatorW) ||
    (showPanel && panelWidth + separatorW) ||
    0
);

export const rightPanelTransform = createSelector(
  rightPanelLeftPosition,
  (leftPosition) => `translateX(${leftPosition}px)`
);

export const panelSeparatorStyle = createSelector(
  headerHeight,
  separatorWidth,
  isSeparatorDragging,
  rightPanelLeftPosition,
  (height, width, isDragging, position) => ({
    width,
    top: height,
    transform: `translateX(${position - width}px)`,
    transition: `background 0.3s${isDragging ? "" : ", transform 0.3s"}`,
  })
);

export const leftPanelStyle = createSelector(
  leftPanelWidth,
  leftPanelTransform,
  panelTransition,
  panelTop,
  (width, transform, transition, top) => ({
    width,
    top,
    transition,
    transform,
  })
);

export const rightPanelStyle = createSelector(
  rightPanelWidth,
  rightPanelTransform,
  panelTransition,
  panelTop,
  (width, transform, transition, top) => ({
    top,
    transition,
    transform,
    width,
  })
);

export const videoPanelStyle = createSelector(
  leftPanelStyle,
  rightPanelStyle,
  textPanelPosition,
  keepNavigatorOpen,
  sidebarWidth,
  (leftStyle, rightStyle, textPosition, keepOpen, navigatorWidth) => {
    const style = textPosition === "left" ? rightStyle : leftStyle;

    return {
      ...style,
      width: style.width + (keepOpen ? -navigatorWidth : 0),
    };
  }
);

export const textPanelStyle = createSelector(
  leftPanelStyle,
  rightPanelStyle,
  textPanelPosition,
  showTextPanel,
  (leftStyle, rightStyle, textPosition, showText) => ({
    ...(textPosition === "right" ? rightStyle : leftStyle),
    opacity: showText ? 1 : 0,
  })
);

export const textPanelWidth = createSelector(
  textPanelStyle,
  (style) => style.width
);

export const textPanelContentHeight = createSelector(
  windowHeight,
  headerHeight,
  textPanelHeaderHeight,
  (totalHeight, globalHeaderHeight, textPanelHeadHeight) =>
    totalHeight - globalHeaderHeight - textPanelHeadHeight
);

export const isMobileWidthVideoPanel = createSelector(
  videoPanelStyle,
  (style) => style.width <= BPMobile
);

export const videoPanelWidth = createSelector(
  videoPanelStyle,
  (style) => style.width
);

export const videoInnerContainerWidth = createSelector(
  showTextPanel,
  alwaysFitPanelWidth,
  videoPanelWidth,
  sidebarHeight,
  isMobileWidthVideoPanel,
  isMobile,
  (show, stretchToFit, width, height, isWidthMobile, isRealMobile) => {
    const padding = !isRealMobile && isWidthMobile ? 20 : 40;

    if (show && stretchToFit && !isWidthMobile) {
      return width - padding - 140;
    }
    return getInnerContainerWidth(width, height, isRealMobile ? 0 : padding);
  }
);

export const videoHeight = createSelector(
  videoInnerContainerWidth,
  (width) => width * (9 / 16)
);

export const videoInnerContainerStyle = createSelector(
  videoInnerContainerWidth,
  (width) => ({ width })
);

export const metaInnerContainerStyle = createSelector(
  videoInnerContainerWidth,
  isMobile,
  (width, isMobileDevice) => ({
    width,
    padding: `10px ${isMobileDevice ? 20 : 0}px`,
  })
);

export const textPanelHeaderRightPadding = createSelector(
  textPanelWidth,
  textPanelPosition,
  (width, position) =>
    position === "right" ? Math.max(80 - Math.max(width - 750, 0) / 2, 0) : 0
);

export const textPanelHeaderStyle = createSelector(
  textPanelHeaderHeight,
  textPanelWidth,
  textPanelHeaderRightPadding,
  showTextPanelSettingsModal,
  (height, width, paddingRight, showSettingsModal) => ({
    height,
    borderRadius:
      width > 750 && !showSettingsModal ? "0 0 10px 10px" : undefined,
    paddingRight,
  })
);

export const versionPickerModalStyle = createSelector(
  versionPickerBoxWidth,
  textPanelStyle,
  textPanelHeaderRightPadding,
  showVersionPickerModal,
  (versionPickerWidth, panelStyle, padding, show) => ({
    ...panelStyle,
    left: (panelStyle.width - versionPickerWidth - 93 - padding) / 2,
    pointerEvents: show ? "auto" : "none",
    overflow: "hidden",
    transition: undefined,
    width: versionPickerWidth - 2,
  })
);
