/* eslint-disable react/require-default-props */

import { CSSTransition } from "react-transition-group";

import DeafBibleIcon from "./DeafBibleIcon";
import styles from "./styles.module.css";
import fade from "../../styles/transitions/fade.module.css";

interface Props {
  size?: number;
  show?: boolean;
  imgUrl?: string;
  isMobile?: boolean;
}

const LoadingIndicator = ({
  size = 150,
  show = true,
  isMobile = false,
  imgUrl,
}: Props): JSX.Element => {
  const container = `${styles.outerContainer} ${isMobile && styles.mobile}`;

  return (
    <div className={container}>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={show}
        classNames={fade}
        timeout={300}
      >
        {imgUrl !== undefined ? (
          <div
            className={styles.innerContainer}
            style={{ width: size / 2, height: size / 2 }}
          >
            <img
              alt=""
              crossOrigin="anonymous"
              src={imgUrl}
              width={size / 2}
              height={size / 2}
              className={styles.teamLoadingIndicator}
            />
          </div>
        ) : (
          <DeafBibleIcon size={size} />
        )}
      </CSSTransition>
    </div>
  );
};

export default LoadingIndicator;
