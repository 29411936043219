import React from "react";
import useInternationalization from "../../../hooks/useInternationalization";
import { Country as CountryType } from "../../../utils/api/getCountries";
import Country from "./Country";

import styles from "./List.module.css";

interface Props {
  countries: CountryType[];
  closeSelf: () => void;
  selectCountry: (countryCode: string) => void;
  selectedCountry: string | undefined;
  darkMode: boolean;
}

const CountryList = React.memo(
  ({
    countries,
    closeSelf,
    selectCountry,
    selectedCountry,
    darkMode,
  }: Props) => {
    const { getIntlText } = useInternationalization();

    const noItemsAvailClassname = `${styles.noItemsAvailable} ${
      darkMode && styles.noItemsAvailableDarkMode
    }`;

    return (
      <div className={styles.container}>
        {countries.length > 0 ? (
          countries.map(({ name, id }) => {
            const isSelected = id === selectedCountry;

            const handler = () => {
              selectCountry(id);
              if (!isSelected) {
                closeSelf();
              }
            };

            return (
              <div key={id} className={styles.countryContainer}>
                <Country
                  darkMode={darkMode}
                  countryCode={id}
                  countryName={name}
                  isSelected={isSelected}
                  handler={handler}
                />
              </div>
            );
          })
        ) : (
          <div className={noItemsAvailClassname}>
            {getIntlText("no countries")}
          </div>
        )}
      </div>
    );
  }
);

export default CountryList;
