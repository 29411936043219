import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import VersionPicker from "../VersionPicker";
import styles from "./styles.module.css";

interface BrowseModeProps {
  toggleHeaderMode: () => void;
  toggleSettings: () => void;
  versionPickerRef: React.RefObject<HTMLDivElement>;
}

const BrowseModeHeader = ({
  toggleHeaderMode,
  toggleSettings,
  versionPickerRef,
}: BrowseModeProps): JSX.Element => {
  return (
    <>
      <VersionPicker ref={versionPickerRef} />
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className={styles.button}
        onClick={toggleHeaderMode}
      />
      <FontAwesomeIcon
        icon={faGear}
        className={styles.button}
        onClick={toggleSettings}
      />
    </>
  );
};

export default BrowseModeHeader;
