/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export enum Orientation {
  portrait,
  landscape,
}

interface Dimensions {
  width: number;
  height: number;
}

interface ScreenState {
  dimensions: Dimensions;
  separatorPosition: number;
  orientation: Orientation;
}

const initialState: ScreenState = {
  dimensions: { width: 0, height: 0 },
  separatorPosition: 600,
  orientation: Orientation.portrait,
};

const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setOrientation: (state, action): void => {
      state.orientation = action.payload;
    },
    setDimensions: (state, action): void => {
      state.dimensions.width = action.payload.width;
      state.dimensions.height = action.payload.height;
    },
    setSeparatorPositionDiff: (state, action) => {
      state.separatorPosition -= action.payload;
    },
    setSeparatorPosition: (state, action) => {
      state.separatorPosition = action.payload;
    },
  },
});

export const {
  setDimensions,
  setOrientation,
  setSeparatorPosition,
  setSeparatorPositionDiff,
} = screenSlice.actions;

export default screenSlice.reducer;
