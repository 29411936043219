import { Translation } from "@deaf-bible-society-2-0/deafbible-metadata-utils";

const fixASLCBT = (data: Translation): Translation => {
  const clonedData = JSON.parse(JSON.stringify(data)) as Translation;

  clonedData.stories.forEach((story) => {
    const { videos } = story;

    // eslint-disable-next-line no-param-reassign
    story.videos = videos.filter(
      (video) =>
        video.type === "intro-passage" ||
        video.type === "intro-passage-moreinfo"
    );
  });

  return clonedData;
};

export default fixASLCBT;
