import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { setSeparatorPosition } from "../store/screen";
import { setIsPanelSeparatorDragging } from "../store/interface";
import {
  minimumLeftPanelWidth,
  minimumRightPanelWidth,
  separatorWidth,
} from "../store/screen/panelSelectors";

const useSeparatorDragHandler = (): void => {
  const dispatch = useDispatch();
  const { isPanelSeparatorDragging: isDragging } = useSelector(
    (state: RootState) => state.interface
  );

  const { width: windowWidth } = useSelector(
    (state: RootState) => state.screen.dimensions
  );

  const sWidth = useSelector(separatorWidth);

  const min = useSelector(minimumLeftPanelWidth);
  const max = windowWidth - sWidth - useSelector(minimumRightPanelWidth);

  useEffect(() => {
    const handler = () => {
      dispatch(setIsPanelSeparatorDragging(false));
    };

    window.addEventListener("mouseup", handler);

    return () => {
      window.removeEventListener("mouseup", handler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isDragging) {
      const handler = (event: MouseEvent) => {
        const newX = event.clientX;

        dispatch(setSeparatorPosition(Math.min(Math.max(newX, min), max)));
      };

      window.addEventListener("mousemove", handler);

      return () => {
        window.removeEventListener("mousemove", handler);
      };
    }
    return undefined;
  }, [dispatch, isDragging, max, min]);
};

export default useSeparatorDragHandler;
