import { useSelector } from "react-redux";

import Button from "./Button";

import styles from "./Button.module.css";

import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import useDownload from "../../../hooks/useDownload";
import { RootState } from "../../../store";

const DownloadButton = () => {
  const { isDownloadAvailable, initiateDownload } = useDownload();
  const { videoClipMode } = useSelector(
    (state: RootState) => state.mainVideoPlayer
  );

  const label = `Download${videoClipMode ? " (Clip only)" : ""}`;

  return (
    isDownloadAvailable && (
      <Button
        label={label}
        emphasized={videoClipMode}
        icon={<Download className={styles.downloadIcon} />}
        onClick={initiateDownload}
      />
    )
  );
};

export default DownloadButton;
