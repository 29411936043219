import { useSelector } from "react-redux";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import TranslationBox from "../TranslationBox";

import styles from "./styles.module.css";
import fade from "../../styles/transitions/fade.module.css";
import Logo from "./Logo";
import { RootState } from "../../store";
import { headerHeight, isMobile, logoType } from "../../store/screen/selectors";
import Hamburger from "../Hamburger";
import ThemeSwitch from "../Switch/Theme";
import BrowseButton from "./BrowseButton";
import TextPanelSwitch from "../Switch/TextPanel";
import { translationType } from "../../store/translation/selectors";
import GearButton from "./GearButton";

const Header = (): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { code } = useSelector((state: RootState) => state.params);
  const type = useSelector(translationType);

  const isMobileDevice = useSelector(isMobile);
  const logo = useSelector(logoType);

  const height = useSelector(headerHeight);

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const containerStyle = {
    height,
  };

  return (
    <div className={container} style={containerStyle}>
      <div className={`${styles.side} ${styles.buttonsGap}`}>
        <Hamburger />
        {!isMobileDevice && (
          <>
            <GearButton />
            <ThemeSwitch />
          </>
        )}

        <Logo type={logo} darkMode={darkMode} />
      </div>
      <div className={styles.side}>
        {isMobileDevice ? (
          <BrowseButton />
        ) : (
          <>
            <SwitchTransition mode="out-in">
              <CSSTransition classNames={fade} timeout={300} key={code}>
                {type === "BBB" ? <TextPanelSwitch /> : <div />}
              </CSSTransition>
            </SwitchTransition>

            <TranslationBox />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
