import { useQuery } from "react-query";
import { captureMessage } from "@sentry/browser";

import getCountryCode from "../utils/location";

const useHomeCountryCode = (): string | undefined => {
  const { data } = useQuery(["countryCode"], getCountryCode, {
    onError: (error) => {
      captureMessage(`Error fetching country code: ${JSON.stringify(error)}`);
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 3,
  });

  return data || "unknown";
};

export default useHomeCountryCode;
