import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import styles from "./styles.module.css";
import { setShowSidebar } from "../../store/interface";

const Hamburger = (): JSX.Element => {
  const dispatch = useDispatch();
  const [triggered, setTriggered] = useState(false);

  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { showSidebar } = useSelector((state: RootState) => state.interface);

  const toggleHamburger = () => {
    setTriggered(true);
    dispatch(setShowSidebar(!showSidebar));
  };

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  }`;

  const top = `${styles.bar} ${styles.top} ${
    triggered && (!showSidebar ? styles.topOpen : styles.topClose)
  }`;
  const middle = `${styles.bar} ${styles.middle} ${
    triggered && (!showSidebar ? styles.middleOpen : styles.middleClose)
  }`;
  const bottom = `${styles.bar} ${styles.bottom} ${
    triggered && (!showSidebar ? styles.bottomOpen : styles.bottomClose)
  }`;

  return (
    <div className={container} onClick={toggleHamburger} role="none">
      <div className={top} />
      <div className={middle} />
      <div className={bottom} />
    </div>
  );
};

export default Hamburger;
