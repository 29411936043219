import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Controls,
  Layout,
  PointerState,
  VideoPlayer,
} from "@deaf-bible-society-2-0/deafbible-video-player";
import { RootState } from "../../store";
import { isMobileWidthVideoPanel } from "../../store/screen/panelSelectors";
import styles from "./styles.module.css";
import useVideoMetadata from "../../hooks/useVideoMetadata";
import SideButtons from "./SideButtons";
import useAutoAdvance from "../../hooks/mainVideoPlayer/useAutoAdvance";
import useVideoTimeFixer from "../../hooks/useVideoTimeFixer";
import { setVideoClipMode } from "../../store/mainVideoPlayer";
import { setEndTime, setStartTime } from "../../store/params";
import useVideoClipMode from "../../hooks/useVideoClipMode";
import useLoadMainVideoSource from "../../hooks/useLoadMainVideoSource";

const MainVideoPlayer = (): JSX.Element => {
  const ref = useRef<HTMLVideoElement>(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useVideoClipMode();

  const { startTime: clipStart, endTime: clipEnd } = useSelector(
    (state: RootState) => state.params
  );

  const { videoClipMode } = useSelector(
    (state: RootState) => state.mainVideoPlayer
  );

  const toggleVideoClipMode = (value: boolean) => {
    dispatch(setVideoClipMode(value));
  };

  const clipRange = useMemo(
    () =>
      clipStart !== undefined && clipEnd !== undefined
        ? { start: clipStart, end: clipEnd }
        : undefined,
    [clipEnd, clipStart]
  );

  const setClipRange = useCallback(
    (range: { start: number; end: number }) => {
      dispatch(setStartTime(range.start));
      dispatch(setEndTime(range.end));
    },

    [dispatch]
  );

  const { autoplay } = useSelector((state: RootState) => state.settings);

  const [isControlBarActive, setisControlBarActive] = useState(false);

  const [seekBarPointerState, setSeekBarPointerState] = useState<PointerState>({
    isDown: false,
    isInside: false,
  });

  useAutoAdvance(!seekBarPointerState.isDown, ref);

  const mainVideoSource = useLoadMainVideoSource();
  useVideoTimeFixer(!seekBarPointerState.isDown && show, mainVideoSource, ref);

  const { videoSrc, timestamps, startTime } = mainVideoSource;
  const label = useVideoMetadata();

  const markers = timestamps?.map(({ c, v, ts }) => ({
    time: ts,
    label: `${c}:${v}`,
  }));

  const { isEmbedded } = useSelector((state: RootState) => state.params);

  const isMobileDevice = useSelector(isMobileWidthVideoPanel);

  const container = `${styles.container} ${!show && styles.hideContainer} ${
    (isMobileDevice || isEmbedded) && styles.containerEmbedded
  }`;

  const src = videoSrc?.src.hls;
  const poster = videoSrc?.poster;

  useEffect(() => {
    setShow(false);
  }, [src]);

  useEffect(() => {
    if (ref.current !== null) {
      const handler = () => {
        setShow(true);

        setTimeout(() => {
          dispatchEvent(new Event("resize"));
        }, 300);
      };

      ref.current.addEventListener("loadeddata", handler);
      ref.current.addEventListener("loadedmetadata", handler);
    }
  }, []);

  return (
    <div id="mainVideoPlayerContainer" className={container}>
      <SideButtons show={isControlBarActive} clipModeActive={false} />
      <VideoPlayer
        src={src}
        autoplay={autoplay}
        controlBarActiveCallback={setisControlBarActive}
        seekBarPointerStateCallback={setSeekBarPointerState}
        layout={isMobileDevice ? Layout.mobile : Layout.desktop}
        controls={Controls.fullControlBar}
        poster={poster}
        label={label}
        markers={markers}
        listenKeyboard
        clipRange={clipRange}
        startTime={startTime}
        setClipRange={setClipRange}
        clippingEnabled={videoClipMode}
        setClippingEnabled={toggleVideoClipMode}
        transitionDelay={300}
        playbackRates={[0.5, 1, 2, 3]}
        ref={ref}
      />
    </div>
  );
};

export default MainVideoPlayer;
