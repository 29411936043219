import { CSSTransition } from "react-transition-group";
import LoadingIndicator from "../LoadingIndicator";
import styles from "./styles.module.css";

import fade from "../../styles/transitions/fade.module.css";

const SplashScreen = (): JSX.Element => {
  const show = true;

  return (
    <div className={styles.container}>
      <CSSTransition
        in={show}
        mountOnEnter
        unmountOnExit
        classNames={fade}
        timeout={300}
      >
        <LoadingIndicator size={200} />
      </CSSTransition>
    </div>
  );
};

export default SplashScreen;
