// const margin = 40;
const ratio = 2;

export const getInnerContainerWidth = (
  windowWidth: number,
  windowHeight: number,
  margin: number
): number => {
  const trialHeight = windowHeight / ratio;

  const trialWidth = trialHeight * (16 / 9);
  const criticalWidth = windowWidth - margin * 2;

  return Math.min(trialWidth, criticalWidth);
};
