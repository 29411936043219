import { useDispatch } from "react-redux";

import { setIsPanelSeparatorDragging } from "../../store/interface";

import TextPanel from "../TextPanel";
import VideoPanel from "../VideoPanel";
import PanelSeparator from "../PanelSeparator";

import styles from "./styles.module.css";
// import Banner from "../VideoPanel/Banner";

const TranslationPanels = (): JSX.Element => {
  const dispatch = useDispatch();

  const onMouseUpHandler = () => {
    dispatch(setIsPanelSeparatorDragging(false));
  };

  return (
    <div role="none" className={styles.container} onMouseUp={onMouseUpHandler}>
      <PanelSeparator />
      <TextPanel />
      <VideoPanel />
      {/* <Banner /> */}
    </div>
  );
};

export default TranslationPanels;
