import { LegacyRef, useRef } from "react";
import { useSelector } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, TooltipRefProps } from "react-tooltip";
import { RootState } from "../../store";
import colors from "../../styles/colors.module.css";

import styles from "./LinkBall.module.css";

interface Props {
  buttonRef?: LegacyRef<HTMLButtonElement> | undefined;
  tooltipOnClick?: string;
  label: string;
  link?: string;
  icon: IconProp;
  bgColor: string;
  onClick?: () => void;
}

const LinkBall = ({
  buttonRef,
  tooltipOnClick,
  label,
  icon,
  bgColor,
  link = "",
  onClick,
}: Props): JSX.Element => {
  const tooltipRef = useRef<TooltipRefProps>(null);
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const onClickHandler = () => {
    if (typeof onClick !== "function") {
      window.open(link);
    } else {
      onClick();
    }
  };

  return (
    <div className={styles.container}>
      <button
        className={`${styles.ball} ${
          darkMode ? styles.focusDarkStroke : styles.focusLightStroke
        }`}
        ref={buttonRef}
        data-tooltip-id={tooltipOnClick}
        type="button"
        onClick={onClickHandler}
        style={{ backgroundColor: bgColor }}
        aria-label={label}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
      <div className={`${styles.label} ${!darkMode && styles.labelLightMode}`}>
        {label}
      </div>

      {tooltipOnClick !== undefined && (
        <Tooltip
          id={tooltipOnClick}
          className={styles.tooltip}
          ref={tooltipRef}
          afterShow={() => {
            tooltipRef.current?.close({ delay: 2000 });
          }}
          openOnClick
          place="top"
          content={tooltipOnClick}
          style={{
            background: colors.gray4,
            color: darkMode ? styles.textDark : styles.textLight,
          }}
        />
      )}
    </div>
  );
};

export default LinkBall;
