import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { ReactComponent as ThickArrow } from "../../assets/icons/arrow-thick.svg";
import { RootState } from "../../store";

import styles from "./BasicButton.module.css";
import fade from "../../styles/transitions/fade.module.css";
import { useTextStyle } from "../../hooks/useTextStyle";

interface Props {
  active?: boolean;
  toggle: () => void;
  isOpen: boolean;
  icon: React.ReactNode;
  text?: string;
  transitionKey?: string;
}

const BasicButton = ({
  active = true,
  toggle,
  isOpen,
  icon,
  text,
  transitionKey,
}: Props): JSX.Element => {
  const { darkMode } = useSelector((state: RootState) => state.settings);

  const textStyle = useTextStyle(
    {
      large: "1.8em",
      normal: "1.5em",
      small: "1.2em",
    },
    !isOpen
  );

  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isOpen && styles.containerIsOpen} ${!active && styles.disabledContainer}`;

  const arrow = `${styles.arrow} ${darkMode && styles.arrowDarkMode} ${
    isOpen && styles.arrowIsOpen
  }`;

  return (
    <div
      role="button"
      tabIndex={0}
      onPointerUpCapture={active ? toggle : undefined}
      onKeyDown={undefined}
      className={container}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={transitionKey || ""}
          timeout={300}
          classNames={{ ...fade }}
        >
          <div style={textStyle} className={styles.innerContainer}>
            <div className={styles.iconContainer}>{icon}</div>
            {text !== undefined && (
              <div className={styles.textContainer}>{text}</div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div className={styles.arrowContainer}>
        <ThickArrow className={arrow} />
      </div>
    </div>
  );
};

export default BasicButton;
