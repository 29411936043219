import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../store";
import { setVideoClipMode } from "../store/mainVideoPlayer";
import { setTimeRange } from "../store/params";
import { getTimeRange, urlWithRange } from "../utils/history";
import { currentVideoSrc } from "../store/translation/selectors";

const useVideoClipMode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { start, end } = getTimeRange();

  const { startTime: shadowStart, endTime: shadowEnd } = useSelector(
    (state: RootState) => state.params
  );

  const videoSrc = useSelector(currentVideoSrc);

  const { videoClipMode } = useSelector(
    (state: RootState) => state.mainVideoPlayer
  );

  const prevVideoSrc = useRef(videoSrc);

  /**
   * If start/end params are present in URL, turn on video clip mode and set the internal state
   */
  useEffect(() => {
    if (
      shadowStart === undefined &&
      shadowEnd === undefined &&
      start !== undefined &&
      end !== undefined
    ) {
      const startTime = parseFloat(start);
      const endTime = parseFloat(end);

      if (!Number.isNaN(startTime) && !Number.isNaN(endTime)) {
        dispatch(setTimeRange({ startTime, endTime }));
        dispatch(setVideoClipMode(true));
      }
    }
  }, [dispatch, end, shadowEnd, shadowStart, start]);

  /**
   * Update the URL as needed.
   */
  useEffect(() => {
    const showParams =
      videoClipMode && shadowStart !== undefined && shadowEnd !== undefined;

    history.replace(
      urlWithRange(
        showParams ? shadowStart.toString() : undefined,
        showParams ? shadowEnd.toString() : undefined
      )
    );
  }, [history, shadowEnd, shadowStart, videoClipMode]);

  /**
   * Reset the video clip setting and turn it off when loading a new video
   */
  useEffect(() => {
    if (prevVideoSrc.current === undefined && videoSrc !== undefined) {
      prevVideoSrc.current = videoSrc;
    } else if (prevVideoSrc.current !== videoSrc) {
      prevVideoSrc.current = videoSrc;
      dispatch(setVideoClipMode(false));
      dispatch(setTimeRange({ startTime: undefined, endTime: undefined }));
      history.replace(urlWithRange(undefined, undefined));
    }
  }, [dispatch, history, videoSrc]);
};

export default useVideoClipMode;
