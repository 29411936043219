import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { captureMessage } from "@sentry/browser";
import useCountries from "./useCountries";

import { setTranslations } from "../store/translations";
import getTranslations, {
  TranslationsByCountry,
} from "../utils/api/getTranslations";

export default (): void => {
  const dispatch = useDispatch();
  const { listCode } = useCountries();

  const { data } = useQuery<TranslationsByCountry[]>(
    ["translations", listCode],
    () => getTranslations(listCode),
    {
      onError: (error) => {
        captureMessage(
          `Error fetching translations metadata: ${JSON.stringify(error)}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    dispatch(setTranslations(data));
  }, [data, dispatch]);
};
