import { RefObject, useEffect } from "react";

const useResetOnShow = (
  videoPlayerRef: RefObject<HTMLVideoElement>,
  show: boolean
): void => {
  useEffect(() => {
    if (videoPlayerRef.current !== null) {
      if (show) {
        // eslint-disable-next-line no-param-reassign
        videoPlayerRef.current.currentTime = 0;
        videoPlayerRef.current.play();
      } else {
        videoPlayerRef.current.pause();
      }
    }
  }, [show, videoPlayerRef]);
};

export default useResetOnShow;
